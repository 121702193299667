export class AudioManager {
    constructor(loader) {
        this.loader = loader;
        this.effects = {};
        this.themes = {};
        this.current = null;
        this.muted = true;
    }

    init() {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.gainNode = this.audioContext.createGain();
        this.setMuted(localStorage && localStorage.getItem('sound') !== '1');

        // load audio effects
        this.loader.getGameAsset('audio.effects')
          .then(response => response.json())
          .then(data => {
              Object.keys(data).forEach((key) => {
                this.load(data[key].file)
                  .then(decodedData => this.effects[key] = { ...data[key], audio: decodedData })
                  .catch((err) => console.log(`cannot find ${data[key].file}`, err));
              })
          })
          .catch((err) => console.log(`cannot find audio.effects.json`, err));

        // load music (don't initialize)
        this.loader.getGameAsset('audio.music')
          .then(response => response.json())
          .then(data => this.themes = data)
          .catch((err) => console.log(`cannot find audio.music.json`, err));
    }

    load(mp3) {
        return this.loader.getGameAudio(mp3)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer))
    }

    effect(name) {
        if (!name || !this.effects[name] || !this.effects[name].audio) return;

        const source = this.audioContext.createBufferSource();
        const gainNode = this.audioContext.createGain();
        gainNode.gain.value = this.muted ? 0 : 1;

        source.buffer = this.effects[name].audio;
        source.connect(gainNode);
        gainNode.connect(this.audioContext.destination);
        source.start();
    }

    music(name) {
      this.stop();

      if (!name || !this.themes[name]) return;
      
      if (!this.themes[name].audio) {
        this.load(this.themes[name].file)
          .then(decodedData => { this.themes[name].audio = decodedData; this.music(name); })
          .catch((err) => console.log(`cannot find ${this.themes[name].file}`, err))

        return;
      }

      this.current = this.audioContext.createBufferSource();
      this.current.buffer = this.themes[name].audio;
      this.current.loop = true;
      this.current.connect(this.gainNode);
      this.gainNode.connect(this.audioContext.destination);
      this.current.start();
    }

    stop() {
      if (!this.current) return;
    
      this.current.stop();
      this.current.disconnect();
    }

    play() {
      if (!this.current) return;

      this.current.play();
    }

    pause() {
      if (!this.current) return;

      this.current.pause();
    }

    setMuted(muted) {
      if (!this.gainNode) return;

      this.gainNode.gain.value = muted ? 0 : 0.5;
      this.muted = muted;

      localStorage && localStorage.setItem('sound', this.muted ? 0 : 1);

      this.loader.onAudioMuted && this.loader.onAudioMuted();
    }

    mute = () => this.setMuted(true)

    unmute = () => this.setMuted(false)
}
