import "./World.css";
import React, { useEffect, useState } from "react";
import { Loader } from "../services/Loader";
import { Badge } from "react-bootstrap";
import { DialogComponent, DialogManager, DialogReactionComponent } from "../services/DialogManager";
import { GameTrivia } from "../ui/GameTrivia";
import { EventManager } from "../services/EventManager";
import { GameMarket } from "../ui/GameMarket";

class World extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showTrivia: false,
      trivia: {},
      showMarket: false,
      market: {},
      status: null
    };

    this.dialogManager = new DialogManager({
      onChange: () => this.setState({dialogHash : (this.state.dialogHash || 0) + 1})
    });

    this.loader = new Loader(props.game);
  }

  async componentDidMount() {
    this.loader.onDefLoaded = (def) => this.props.onDefLoaded && this.props.onDefLoaded(def);

    this.loader.setWorldMessage = (data) => {
      switch (data.type) {
        case 'title':     this.setTitle(data); break;
        case 'message':   this.setMessage(data); break;
        case 'trivia':    this.setTrivia(data); break;
        case 'dialog':    this.dialogManager.add(DialogComponent, data); break;
        case 'reaction':  this.dialogManager.add(DialogReactionComponent, data); break;
        case 'status':    this.setStatusMessage(data); break;
        default: break;
      }
    }

    this.loader.setMarket = (market) => {
      this.setMarket(market);
    }

    await this.loader.load();

    this.loader.createWorld(document.querySelector('.world-container'), this.props.map, this.props.user)
      .then((data) => {
        this.world = data.world;

        /*
        window.onresize = function() {
          this.world.canvas.width = Utils.canvasSize().x;
          this.world.canvas.height = Utils.canvasSize().y;
        }.bind(this);
        */

        // initialize actions
        // console.log(this.world.loader.actions);

        this.world.init();
        
        if (this.props.onWorldInitialized) {
          this.props.onWorldInitialized(this.world, data.status);

          // splash screen
          setTimeout(() => this.setState({loading: false}), 2000);
        }

        // create events (escape and space)
        EventManager.bind('keys.escape', (event) => {
          this.cleanMessage();
          this.dialogManager.close();

          return Promise.resolve();
        });    

        EventManager.bind('keys.space', (event) => {
          this.cleanMessage();
          this.dialogManager.close();

          return Promise.resolve();
        });    
      });    
  }

  componentWillUnmount() {
    this.loader.exit();

    if (this.world) {
      this.world = null;
    }
  }

  setTitle(data) {
    this.setState({
      showTitle: true, 
      title: data.title,
      subtitle: data.subtitle
    });
  }

  setMessage(data) {
    this.setState({
      showMessage: true, 
      message: {
        title: data.title,
        subtitle: data.subtitle,
        message: data.message,
        badge: data.badge,
        className: data.className,
        onComplete: data.onComplete
      }
    });

    if (data.timeout) {
      setTimeout(() => { this.cleanMessage() }, data.timeout);
    }
  }

  cleanMessage() {
    if (!this.state.showMessage) {
      return;
    }

    if (this.state.message.onComplete) {
      this.state.message.onComplete();
    }

    this.setState({showMessage: false, message: {}});
  }

  setTrivia(data) {
    data.options = data.options || [];
    const options = [];

    for (const option of data.options) {
      const splitted = option.split(':');
      options.push({
        code: splitted[0], 
        value: splitted.length > 1 ? splitted[1] : null,
        onComplete: () => data.onComplete && data.onComplete({option: splitted[0]})
      });
    }

    data.options = options;

    this.setState({showTrivia: true, trivia: data});
  }

  onTriviaOptionSelected(option) {
    option.onComplete();
    this.setState({showTrivia: false, trivia: {}});
  }

  setMarket(market) {
    this.setState({showMarket: true, market: market});
  }

  onMarketClose() {
    this.state.market.onClose && this.state.market.onClose();
    this.setState({showMarket: false, market: {}});
  }

  setStatusMessage(data) {
    this.setState({status: data.message, statusbgcolor: data.bgcolor || 'primary'});

    if (data.timeout) {
      setTimeout(() => { this.setState({status: null}) }, data.timeout);
    }
  }

  render() {
    return (
        <>
          {this.state.loading && (this.props.splash || <div>Loading</div>)}
          <div className="world-container">
            <canvas className="world-canvas" 
              width={this.props.width || 1920} 
              height={this.props.height || 1080}
              tabIndex={0}>
            </canvas>
          </div>
          {this.dialogManager.render(this.state.dialogHash)}
          {
            this.state.showMessage &&
            this.state.message &&
            <div className={`world-message-container ${this.state.message.className}`} onClick={() => this.cleanMessage()}>
              {
                this.state.message.badge && <Badge>{this.state.message.badge}</Badge>
              }
              <div className="world-title">{this.state.message.title}</div>
              <div className="world-subtitle">{this.state.message.subtitle}</div>
              {
                this.state.message.message.map((message, i) => <div className="world-message" key={i} dangerouslySetInnerHTML={{ __html: message }}/>)
              }
            </div>
          }
          {
            this.state.status &&
            <div className={`world-message-status world-message-status-${this.state.statusbgcolor}`} onClick={() => this.setState({status: null})} dangerouslySetInnerHTML={{ __html: this.state.status }}></div>
          }
          {
            this.state.showTitle &&
            <div className="world-status-container">
              <div className="world-title">{this.state.title}</div>
              <div className="world-subtitle">{this.state.subtitle}</div>
            </div>
          }
          {
            <GameTrivia 
              visible={this.state.showTrivia} 
              trivia={this.state.trivia} 
              onOptionSelected={(option) => this.onTriviaOptionSelected(option)}/>
          }        
          {
            <GameMarket 
              visible={this.state.showMarket} 
              market={this.state.market}
              world={this.world} 
              onClose={(option) => this.onMarketClose()}/>
          }        
          <WorldCharacters loader={this.loader} map={this.world?.map}></WorldCharacters>
        </>
    );
  }
}

const WorldCharacters = (props = {}) => {
  const [characters, setCharacters] = useState({});

  useEffect(() => {
    if (!props.loader) return;

    props.loader.onUpdateDOMCharacters = (c) => setCharacters(c);
  }, [props.loader]);

  return (
    <div className="world-characters">
      { Object.keys(characters).map((key) => <WorldCharacter key={key} map={props.map} id={key}/>) }
    </div>
  )
}

const WorldCharacter = (props = {}) => {
  const [position, setPosition] = useState({});
  const [name, setName] = useState();
  const [chat, setChat] = useState();
  const [isPlayerControlled, setIsPlayerControlled] = useState(false);

  useEffect(() => {
    if (!props.map) return;

    const c = props.map.charactersDOM[props.id];
    if (!c) return;

    setIsPlayerControlled(c.isPlayerControlled);
    setPosition(c.position);
    setName(c.name);
    setChat(c.chat);
    
    c.onUpdateElement = () => {
      setPosition(c.position);
      setName(c.name);
      setChat(c.chat);
    }
  }, [props.map, props.id])

  return <div style={{ 
        left: position.left || 0, 
        top: position.top || 0, 
        height: position.height || 0, 
        width: position.width || 0 }} 
    id={`world-character-${props.id}`} 
    className="world-character" 
    title={name}>
    <>
      <DialogComponent
        show={chat} message={chat}
        className={isPlayerControlled ? 'player' : ''}
        x={60} y={-60}/>
      {/*<div className="world-character-name">{character.name}</div>*/}
    </>
  </div>
}

export default World;