import React from "react";

class Img extends React.Component {
    state = { loaded: false, src: this.props.src };

    onLoad = () => {
        this.setState({ loaded: true });
    };

    render() {
        //const { loaded } = this.state;
        return (
            <img
                className={(this.props.className || "")}
                src={this.state.src}
                onLoad={this.onLoad}
                onError={() => this.setState({src:this.props.errorImage})}
                title={this.props.title || ""}
                alt={this.props.alt || ""}
                style={this.props.style || {}}>
            </img>
        );
    }
}

export default Img;