import { AppContext, eventUpdateEnum } from "AppContext";
import "./RoachCounter.css";
import React from "react";
import { iethContract } from "Landing";
import Countdown from "react-countdown";

class RoachCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            roachesData: {
                totalSupply: 0,
                totalRoaches: 0
            }
        };
    }

    componentDidMount() {
        this.setState({roachesData: AppContext.roachesData || {}});

        AppContext.events.update.add((event) => {
            if (event !== eventUpdateEnum.roachesCounters)
                return;

            this.setState({roachesData: AppContext.roachesData || {}});
        });

        this.setState({roachesData: AppContext.roachesData || {}});
    }
    
    formatValue(value) {
        return value != null ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
    }    

    render() {
        const rendererCounterDays = ({days, hours, minutes, seconds, completed}) => {
            return <span>{days}d {hours}h {minutes}m</span>;
        };

        const now = iethContract.apiRootTime();
        let countDownCloseSeason = 0;
        if (AppContext.roachesData && AppContext.roachesData.seasonEndDate) {
            let closeTimeToSeason = new Date();
            closeTimeToSeason.setTime(new Date(AppContext.roachesData.seasonEndDate));
      
            countDownCloseSeason = closeTimeToSeason.getTime() - now.getTime();
        }
      
        return (
            <div className={"roach-counter"}>
                {this.formatValue(this.state.roachesData.totalRoaches || 0)} / {this.formatValue(this.state.roachesData.totalSupply || 0)}
                {
                    countDownCloseSeason > 0 &&
                    <div className="counter counter-season">
                        <Countdown date={Date.now() + countDownCloseSeason} daysInHours renderer={rendererCounterDays}/>
                    </div>
                }
            </div>
        );
    }
}

export default RoachCounter;