import "./Terminal.css";

import React from "react";
import MetaMaskAuth, {isWalletConnected} from "../connect/metamask-auth.module";
import {AppContext} from "AppContext";
import Navbar from "./Navbar";
import StatusComponent from "./TerminalComponents/StatusComponent";
import SideMenu from "Components/SideMenu";
import DashboardComponent from "./TerminalComponents/DashboardComponent"
import MissionsComponent from "./TerminalComponents/MissionsComponent"
import LeaderboardComponent from "./TerminalComponents/LeaderboardComponent"
import InvadersComponent from "./TerminalComponents/InvadersComponent"
import ResistanceComponent from "./TerminalComponents/ResistanceComponent"
import RebelFactoryComponent from "./TerminalComponents/RebelFactoryComponent";

const terminalMenuItems = [
  {
    index: 1,
    name: "Dashboard",
    image: "dashboard.png",
    component: <DashboardComponent></DashboardComponent>
  },
  {
    index: 5,
    name: "Missions",
    image: "missions.png",
    pendingFcn: () => {
      const globalMissions = AppContext.missions ? AppContext.missions['global'] || [] : [];
      return globalMissions.filter((m) => m.state === 'pending').length;
    },
    component: <MissionsComponent></MissionsComponent>,
    hidden: false
  },
  {
    index: 10,
    name: "Resistance Camp",
    image: "bonfire.png",
    component: <></>,//<ResistanceCampComponent></ResistanceCampComponent>,
    hidden: true
  },
  {
    index: 15,
    name: "Rebel Factory",
    image: "factory.png",
    component: <RebelFactoryComponent></RebelFactoryComponent>
  },
  {
    index: 30,
    name: "Leaderboard",
    image: "leaderboard.png",
    component: <LeaderboardComponent></LeaderboardComponent>
  },
  {
    index: 40,
    name: "Invaders",
    image: "invaders.png",
    component: <InvadersComponent></InvadersComponent>
  },
  {
    index: 50,
    name: "Resistance",
    image: "team.png",
    component: <ResistanceComponent></ResistanceComponent>,
    hidden: true
  }
]

class Terminal extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      authorized: true,
      userAddress: null,
      contractLoaded: false,
      selectedMenu: null
    };

    this.menuRef = React.createRef();
    AppContext.statusRef = React.createRef();
  }

  componentDidMount() {
    isWalletConnected()
      .then((account) => {
        AppContext.userAddress = account;
        this.setState({loading: false});
        
        if (account)        
          this.initialize();
      })
      .catch((err) => console.log(err))
  }

  async initialize() {
    this.menuRef.current.showMenu();

    // open dashboard
    this.onMenuClick(this.menuRef.current.getMenu(this.props.defaultMenu || 1));
  }

  render() {
    const discordhref = 'https://discord.gg/4guwfvhgbr';
    const openseacollection = 'https://opensea.io/collection/invasion-eth-resistance';
    const twitter = 'https://twitter.com/InvasionEth';
    // const website = 'https://invasioneth.art';
  
    return (
        <div className="terminal">
          <Navbar>
            <StatusComponent ref={AppContext.statusRef}></StatusComponent>
          </Navbar>
          <h1 className="caption" title={process.env.REACT_APP_VERSION}>
            <span className="close-terminal" title="Close Terminal" onClick={() => this.props.onAppClose()}>X</span>
            Resistance Terminal 
          </h1>
            {
                !this.state.loading &&
                <SideMenu ref={this.menuRef} items={terminalMenuItems} onMenuClick={(menu) => this.onMenuClick(menu)}></SideMenu>
            }
            <div className="terminal-main">
              {
                !this.state.loading &&
                !AppContext.userAddress &&
                <>
                  <div>
                    <MetaMaskAuth onAddressChanged={address => {}}/>
                  </div>
                  <div className="socialbar">
                    {/*<a className="button" title="Web Site" target="_blank" rel="noreferrer" href={website}><img src={require("../css/images/logo-website.png")} alt=""/></a>*/}
                    <a className="button" title="Connect to Discord" target="_blank" rel="noreferrer"  href={discordhref}>
                      <img src={require("../css/images/logo-discord.png")} alt=""/>
                    </a>
                    <a className="button" title="Open Sea Market" target="_blank" rel="noreferrer" href={openseacollection}>
                      <img src={require("../css/images/logo-opensea.png")} alt=""/>
                    </a>
                    <a className="button" title="Twitter" target="_blank" rel="noreferrer" href={twitter}>
                      <img src={require("../css/images/logo-twitter.png")} alt=""/>
                    </a>
                  </div>
                </>
              }
              {
                this.state.selectedMenu != null &&
                this.state.selectedMenu.component
              }
            </div>
        </div>
    );
  }

  onMenuClick = (menu) => {
    this.setState({selectedMenu: menu});
  }
}

export default Terminal;