import { GameObject } from "./GameObject";
import { Sprite } from "./Sprite";

export class GameObjectSprite extends GameObject {
    constructor(id, props) {
        super(id, props);

        this.movingProgressRemaining = 0;
        this.animationProgressRemaining = 0;
        this.direction = props.direction || 'down';
        this.orientation = props.orientation;
        this.action = null;

        props.data = props.data || {};
        props.data.animationFrameLimit = props.data.animationFrameLimit || 6;

        this.sprite = new Sprite({
            gameObject: this,
            src: props.src,
            loader: props.loadImage,
            spriteSize: props.spriteSize,
            spriteIndex: props.spriteIndex,
            animations: props.animations,
            data: props.data
        });
    }

    destroy() {
        if (this.sprite.image) {
            this.sprite.image = null;
        }
    }

    draw(ctx, cameraObject) {
        if (!this.sprite || this.isHidden) {
            return;
        }

        // super.draw(ctx, cameraObject);

        this.sprite.draw(ctx, cameraObject);
    }

    strokeRect(ctx, cameraObject) {
        const [x, y] = this.sprite.getPosition(ctx, cameraObject);
        ctx.strokeRect(x, y, this.sprite.spriteSize.x * (this.scale || 1), this.sprite.spriteSize.y * (this.scale || 1));
    }

    mount(map) {
        if (!this.isOverlapped) {
            map.addWall(this.x, this.y);
        }
        super.mount(map);
    }

    unMount(map) {
        if (!this.isOverlapped) {
            map.removeWall(this.x, this.y);
        }
        super.unMount(map);
    }
    
    set action(action) {
        if (!action) {
            this._action = action;
            this.animationProgressRemaining = 0;
        } else {
            this._action = action;
            const animation = this.sprite.animations[action];

            if (animation) {
                this.animationProgressRemaining = animation.length * this.sprite.data.animationFrameLimit;
            }
        }

        this.updateSprite();
    }

    get action() {
        return this._action;
    }

    get imageSrc() {
        return this.sprite && this.sprite.image ? this.sprite.image.src : '';
    }

    get imageClip() {
        return this.sprite ? this.sprite.clip : {};
    }

    update(state) {
        super.update(state);
        
        if (this.animationProgressRemaining > 0) {
            this.updatePosition(state);
        }
    }

    updateSprite() {
        if (this.action && this.animationProgressRemaining > 0) {
            this.state = this.action + (this.orientation ? '-' +  this.orientation : '');
            return;
        }
    }

    updatePosition(state) {
        if (this.animationProgressRemaining > 0) {
            this.animationProgressRemaining--;

            if (this.animationProgressRemaining === 0) {
                // action finished callback
                if (this.onActionFinished) {
                    this.onActionFinished(this.action);
                    this.onActionFinished = null;
                }

                this.action = null;
            }
        } 
    }

    onChangeState(state) {
        if (!this.sprite) {
            return;
        }

        this.sprite.onChangeState(state);
    }

    save(data) {
        super.save(data);
        
        data.direction = this.direction;
        data.orientation = this.orientation;
    }

    load(data) {
        super.load(data);

        this.direction = data.direction;
        this.orientation = data.orientation;
    }
}