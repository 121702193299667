import "./BaseWorldApp.css";
import React from "react";
import { AppContext, eventUpdateEnum } from "AppContext";
import { iethContract } from "Landing";
import World from "../core/World";
import Gauge from "Components/Gauge";
import { EventManager } from "../services/EventManager";
import { Joystick } from "./Joystick";

class BaseWorldApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPointAwarded: false,
      pointsAwarded: null,
      showItem: false,
      item: null,
      itemAdded: false,
      contractLoaded: false,
      muted: true,
      gauges: {
        score: 0,
        bucks: 0,
        users: 1
      }
    };
  }

  componentDidMount() {
    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.status || event === eventUpdateEnum.bucks) {
        const gauges = this.state.gauges;
        gauges.score = AppContext.score;
        gauges.bucks = AppContext.bucks;
  
        this.setState({gauges: gauges, contractLoaded: AppContext.contractsLoaded});
      }
    });
    
    const gauges = this.state.gauges;
    gauges.score = AppContext.score;
    gauges.bucks = AppContext.bucks;
    
    this.setState({gauges: gauges, contractLoaded: AppContext.contractsLoaded});
  }

  onWorldInitialized(world, status) {
    this.world = world;

    this.props.onWorldInitialized && this.props.onWorldInitialized(world, status);

    // callbacks
    this.world.loader.onOption = (option) => {
      const options = document.getElementsByClassName(`game-option-${option}-click`);
      
      if (options.length > 0) {
        options[0].click();
      }
    }

    this.world.loader.onPointsAwarded = (points, reference) => {
      //console.log(points, reference);
      
      const gauges = this.state.gauges;
      gauges.score += parseInt(points);
      this.setState({pointsAwarded: points, showPointAwarded: true, gauges: gauges});
      setTimeout(() => { this.setState({pointsAwarded: null, showPointAwarded: false}) }, 1500);
    }

    this.world.loader.onItemAdded = (item) => this.setItem(item, true)

    this.world.loader.onItemRemoved = (item) => this.setItem(item, false)

    /*
    this.world.loader.onConnectedPlayersChanged = (online) => {
      const gauges = this.state.gauges;
      gauges.users = online || 1;

      this.setState({gauges: gauges});
    }
    */

    this.world.loader.onPlayerStatusChanged = (status) => {
      iethContract.updateStatus();
      this.props.onPlayerStatusChanged && this.props.onPlayerStatusChanged(status);
    }

    // update initial status
    this.world.loader.onPlayerStatusChanged(status);

    // game loop update
    this.world.loader.onUpdate = (state) => this.props.onUpdate && this.props.onUpdate(state)

    this.world.loader.onAudioMuted = () => {
      this.setState({muted: this.world.loader.audio.muted});
    }

    this.setState({muted: this.world.loader.audio.muted});
  }

  setItem = (item, added) => {
    if (!item) return;

    item.image = item.image || this.world.loader.getObject(item.id).imageSrc;
    item.imageClip = item.imageClip || this.world.loader.getObject(item.id).imageClip;
    item.style = {
      height: `${item.imageClip.height}px`,
      width: `${item.imageClip.width}px`,
      background: `url(${item.image}) -${item.imageClip.left}px -${item.imageClip.top}px`
    }

    this.setState({showItem: true, item: item, itemAdded: added});

    setTimeout(() => this.setState({showItem: false, item: null}), 1000);
  }

  handleTalkInputKeyDown = (e) => {
    if(e.keyCode === 13) { 
      EventManager.dispatch(EventManager.translate(`character.talk:player|${this.state.talkValue}^8000`));
      this.setState({talkValue : ''});
    }

    e.stopPropagation();
  }

  /*
  handleJoystickStop = (e) => {
    if (!this.world?.directionInput) return;
    
    this.activatedJoystick = false;

    this.world.directionInput.stopAll();
  }

  handleJoystickMove = (e) => {
    if (!this.joystickRef || !this.joystickRef.current || !this.world?.directionInput) return;

    const rect = this.joystickRef.current.getBoundingClientRect();
    
    // Get the touch position (assuming single touch)
    const touch = e.touches ? e.touches[0] : e;
    
    // Calculate the touch position relative to the div
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;

    // TODO: calculate velocity

    if (Math.abs(x - 50) < Math.abs(y - 50)) {
      this.world.directionInput.applyMovement(y > 50 ? 'ArrowDown' : 'ArrowUp', 1);
    } else {
      this.world.directionInput.applyMovement(x > 50 ? 'ArrowRight' : 'ArrowLeft', 1);
    }
  }
  */

  render() {
    return (
        <div className="base-game-app">
            <World 
              {...this.props}
              onWorldInitialized={(world, status) => this.onWorldInitialized(world, status)}
              />
            {
              <>
                {this.props.profile}
                <div className="game-options">
                  {this.props.options}
                </div>
              </>
            }
            {
              this.state.contractLoaded &&
              <div className="gaugebar gaugebar-top-right">
                <Gauge icon={require("../../../css/images/icons/coins.png")} value={this.state.gauges.score} title="Coins"></Gauge>
                <Gauge icon={require("../../../css/images/icons/rblb.png")} 
                    value={(this.state.gauges.bucks !== -1 ? this.state.gauges.bucks : 'Err!')} 
                      title={this.state.gauges.bucks !== -1 ? 'Rebel Bucks' : 
                        `Network Error (change to ${iethContract.network().name} and click to refresh)`}>
                </Gauge>
              </div>
            }
            {
              <Joystick world={this.world}></Joystick>
            }
            {
              <div className="game-zoom-controls">
                {
                  <div className={`game-option game-option-small`}>
                    <div onClick={() => {this.state.muted ? this.world.loader.audio.unmute() : this.world.loader.audio.mute()}}>
                      <img src={require(`../../../css/images/icons/${this.state.muted ? 'sound-mute' : 'sound-volume'}.png`)} alt="" title={this.state.muted ? 'Unmute' : 'Mute'}></img>
                    </div>
                  </div>
                }
                <div className={`game-option game-option-small`}>
                  <div onClick={() => {this.world.zoomIn()}}>
                    <img src={require('../../../css/images/icons/zoom-in.png')} alt="" title={'Zoom In'}></img>
                  </div>
                </div>
                <div className={`game-option game-option-small`}>
                  <div onClick={() => {this.world.zoomOut()}}>
                    <img src={require('../../../css/images/icons/zoom-out.png')} alt="" title={'Zoom Out'}></img>
                  </div>
                </div>
              </div>
            }
            {
              this.props.chat &&
              <div className="game-text-input">
                <input type="text" 
                  maxLength={55}
                  placeholder="Chat Here..."
                  onKeyDown={this.handleTalkInputKeyDown} 
                  value={this.state.talkValue} 
                  onChange={e => this.setState({ talkValue : e.target.value })}>
                </input>
              </div>
            }
            {
              this.state.showPointAwarded &&
              <div className={"world-points-awarded" + (this.state.pointsAwarded < 0 ? " world-points-awarded-negative" : "")}>
                <div>{this.state.pointsAwarded}<small>coins</small></div>
              </div>
            }
            {
              this.state.showItem &&
              this.state.item &&
              <div className={`world-message-container ${this.state.itemAdded ? 'item-added-message' : 'item-removed-message'}`} onClick={() => this.setState({showItem: false, item: null})}>
                <div style={this.state.item.style} title={this.state.item.data?.label || this.state.item.id}></div>                
              </div>
            }
        </div>
    );
  }
}

export default BaseWorldApp;