import "./InvadersComponent.css";
import {iethContract} from "Landing";
import {AppContext} from "AppContext";
import React from "react";
import InvaderWaveComponent from "./InvaderWaveComponent";
import InvaderGameRouletteComponent from "./InvaderGameRouletteComponent";
//import InvaderGameSlotMachineComponent from "./InvaderGameSlotMachineComponent";
import InvaderGameModalComponent from "./InvaderGameModal";
import InvaderGameResultsModalComponent from "./InvaderGameResultsModal";
import Countdown from "react-countdown";
import { InfinitySpin } from  'react-loader-spinner'

class InvadersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waves: [],
      selectedInvader: null,
      selectedInvaderModalShow: false,
      chances: [],
      selectedVoucher: null,
      resultsInvaderModalShow: false,
      result: null,
      context: AppContext
    };
  }

  componentDidMount() {
    if (AppContext.initialized) {
      this.reload();
    } else {
      AppContext.events.initilize.add(() => this.reload());
    }
  }

  reload() {
    this.setState({context: AppContext });

    iethContract.api.call('get', `invaders`)
      .then((res) => res.json())
      .then((data) => { 
        this.setState({waves: data.waves || []});
      });
  }

  async onCompleteWait() {
    await new Promise(res => setTimeout(res, 1000));
    await iethContract.updateChances();
    iethContract.updateStatus();
    
    this.reload();
  }

  onFinishSpin = (selectedItem) => {
    this.reload();

    // if the user won or was captured by the UFO, no more chances
    const usedChances = (this.state.chances[selectedItem].prize === -1 || this.state.chances[selectedItem].ufo) ? AppContext.chances : this.state.chances.filter((item) => item.winner).length;
    iethContract.updateStatus();

    // waitTime
    // capture invader = 24 hours
    // ufo = 12 hours
    // no more chances = 6 hours
    const waitTime = this.state.chances[selectedItem].prize === -1 ? 24 :
                      this.state.chances[selectedItem].ufo ? 12 :
                      AppContext.chances <= 0 ? 6 : 0;

    // call api
    // signature + used chances + prize
    iethContract.signIn()
        .then((results) => {
            iethContract.api.call('post', `wallets/${iethContract.userAddress()}/prize/complete`, {
                signature: results.signature,
                nonce: results.nonce,
                prize: this.state.chances[selectedItem].prize || 0,
                score: this.state.chances[selectedItem].score || 0,
                prizeId: this.state.selectedInvader.id,
                usedChances: waitTime > 0 ? 0 : usedChances,
                waitTime: waitTime
            })
            .then((res) => res.json())
            .then(async (data) => {
              await iethContract.updateStatus();

              // show results
              this.setState({
                result: this.state.chances[selectedItem],
                resultsInvaderModalShow: true
              });

              if (data.voucher) {
                iethContract.loadRebels();
              }
            });
        });
  }  

  onStartSpin = () => {}

  render() {
    const now = iethContract.apiRootTime();
    let waitTimeTo = new Date();
    let countDown = 0;
    if (AppContext.walletData.raffles && 
        AppContext.walletData.raffles.lastDraw && 
        AppContext.walletData.raffles.lastDraw.waitTimeFrom) {
        waitTimeTo.setTime(new Date(AppContext.walletData.raffles.lastDraw.waitTimeFrom));
        waitTimeTo.setTime(waitTimeTo.getTime() + (AppContext.walletData.raffles.lastDraw.waitTime * 60 * 60 * 1000));

        countDown = waitTimeTo.getTime() - now.getTime();
    }

    return (
        <div className="component-invaders">
          <h1>Invaders</h1>
          <p>Intelligence Report:</p>
          <p>You have {this.state.context.chances} {this.state.context.chances === 1 ? 'chance' : 'chances'} available.</p>
          <p>6 chances max per roulette spin.</p>
          {
            countDown > 0 &&
            <p>Your chances resets in <Countdown date={Date.now() + countDown} daysInHours onComplete={() => this.onCompleteWait()}/></p>
          }
          <a href={require("../../css/images/help-capture.png")}  target="_blank" rel="noreferrer">How does this work?</a>
          {
            !this.state.context.initialized &&
            <div className="text-center">
              <InfinitySpin width='190'color="#ffffff"/>                  
              <div>Loading</div>
            </div>
          }
          { 
            this.state.context.initialized &&
            this.state.waves.map(wave => 
              <InvaderWaveComponent 
                key={wave.wave} 
                wave={wave}
                onInvaderClick={(invader) => this.onInvaderClick(invader)}>
              </InvaderWaveComponent>)
          }
        <InvaderGameModalComponent 
          show={this.state.selectedInvaderModalShow} 
          onClose={(reload) => {
            this.setState({selectedInvaderModalShow: false});
          }}
          chances={this.state.chances}
          onStartSpin={() => this.onStartSpin()}
          onFinishSpin={(selectedItem) => this.onFinishSpin(selectedItem)}
          selectedInvader={this.state.selectedInvader}/>
        <InvaderGameResultsModalComponent
          show={this.state.resultsInvaderModalShow} 
          onClose={(reload) => {
            this.setState({resultsInvaderModalShow: false, selectedInvaderModalShow: false});
          }}
          result={this.state.result}/>
        </div>
    );
  }

  randomizeChances() {
    const chances = Array(36).fill({text:'-'});

    const rouletteChances = [
      { prize: 2, text: '+2 -', score: 2 },
      { prize: 2, text: '+2 -', score: 2 },
      { prize: 3, text: '+3 -', score: 3 },
      { prize: 5, text: '+5 -', score: 5 },
      { prize: 0, text: '-', score: 0, ufo: true, image: require('./../../css/images/icons/ufo.png') },
      { prize: 0, text: '-', score: 0, ufo: true, image: require('./../../css/images/icons/ufo.png') }
    ];

    // winner chances
    // daily chances - max 6
    const maxChances = this.state.selectedInvader.maxChances ? this.state.selectedInvader.maxChances : parseInt(process.env.REACT_APP_INVADERS_MAX_CHANCES) || 6;
    const availableChances = AppContext.chances >= maxChances ? maxChances : AppContext.chances;
    for (let x = 0; x < availableChances; x++) {
      if (this.state.selectedInvader.id !== 1) {
        rouletteChances.push({text: '-', winner: true, prize: -1, score: this.state.selectedInvader.score, image: require('./../../css/images/icons/ticket.png')});
      } else {
        // score wheel
        rouletteChances.push({text: '+' + this.state.selectedInvader.score + ' -', winner: true, prize: this.state.selectedInvader.score, score: this.state.selectedInvader.score, image: null});
      }
    }

    rouletteChances.forEach(chance => {
      let index = Math.floor(Math.random() * chances.length);
      while (chances[index].prize || chances[index].ufo) {
        index = Math.floor(Math.random() * chances.length);
      }

      chances[index] = chance;
    });
    
    this.setState({chances: chances});
  }

  onInvaderClick = (invader) => {
    if (invader.locked)
      return;

    iethContract.signIn()
      .then((results) => {
        invader.game = InvaderGameRouletteComponent;
        //invader.game = InvaderGameSlotMachineComponent;
        this.setState({selectedInvader: invader, selectedInvaderModalShow: true});
        this.randomizeChances();
      });
  }
}

export default InvadersComponent;