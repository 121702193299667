import React from 'react';

import './index.css';

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      clicked: false
    };
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem() {
    if (this.state.clicked)
      return;

    this.setState({clicked: true});
    if (this.state.selectedItem === null) {
        if (this.props.onStartSpin) {
          this.props.onStartSpin();
        }

        const selectedItem = Math.floor(Math.random() * this.props.items.length);
        if (this.props.onSelectItem) {
            this.props.onSelectItem(selectedItem);
        }
        this.setState({ selectedItem });
        if (this.props.onFinishSpin) {
            setTimeout(() => this.props.onFinishSpin(selectedItem), 4000);
        }
    } else {
        this.setState({ selectedItem: null });
        setTimeout(this.selectItem, 500);
    }
  }

  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;

    const wheelVars = {
      '--nb-item': items.length,
      '--selected-item': selectedItem,
    };
    const spinning = selectedItem !== null ? 'spinning' : '';

    return (
      <div className="wheel-container">
        <div className={`wheel ${spinning}`} style={wheelVars} onClick={this.selectItem}>
            {this.props.image && <img src={this.props.image} alt=""></img>}
            {items.map((item, index) => (
                <div className="wheel-item" key={index} style={{ '--item-nb': index }}>
                {item.image &&
                    <img 
                    src={item.image} 
                    alt=""></img>}
                {item.text || ''}
                </div>
            ))}
        </div>
      </div>
    );
  }
}
