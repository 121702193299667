import "./Landing.css";

import abiAEKAssetContractShared from "./data/AEKAssetContractShared.json"
import abiAEKInvadersAssetContractShared from "./data/AEKInvadersAssetContractShared.json"
import abiAEKInvasionEthRules from "./data/AEKInvasionEthRules.json"
import abiAEKBattleEthAssets from "./data/AEKBattleEthAssets.json"
import abiAEKInvasionEthRoaches from "./data/AEKInvasionEthRoaches.json"
import abiAEKRebelBucks from "./data/AEKRebelBucks.json"
import abiAEKPixelRebels from "./data/AEKPixelRebels.json"
import React from "react";
import MetaMaskAuth, {isWalletConnected} from './connect/metamask-auth.module'
import { BigNumber, ethers } from "ethers";
import Apps from "./Apps";
import {AppContext, rankEnum, eventUpdateEnum, rankEnumString} from "AppContext";
import {makeNonce, produceMessage} from "./connect/eip4361-message";
import { InfinitySpin } from  'react-loader-spinner'
import Gauge from "Components/Gauge";
import ModalSetNickName from "Terminal/TerminalComponents/Missions/ModalSetNickName";
import { MissionsModal } from "Terminal/TerminalComponents/Missions/MissionsModal";
import { VouchersModal } from "Terminal/TerminalComponents/VouchersModal";
import { Carousel } from "react-bootstrap";
import { RBLBModal } from "Terminal/TerminalComponents/RBLBModal";
import { DailyRewards } from "DailyRewards";
import { Paycheck } from "PayCheck";

export const iethContract = {
  api: {
    call: async (method, uri, body) => {
      return fetch(`${process.env.REACT_APP_API_URL}${uri}`,
        {
            method: method,
            dataType: 'json',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: method === 'get' ? null : (JSON.stringify(body) || {})
        });
    }
  },
  userAddress: () => {
    return AppContext.userAddress;
  },
  nickname: () => {
    const nickname = AppContext.walletData && AppContext.walletData.nickname ? AppContext.walletData.nickname : AppContext.userAddress;
    return !AppContext.userAddress ? "Unregistered" : nickname.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase() ? 'Unnamed' : nickname;
  },
  network: () => {
    return {
      name: process.env.REACT_APP_NETWORK_NAME,
      chainId: parseInt(process.env.REACT_APP_NETWORK_CHAINID) || 0,
      _defaultProvider: (providers) => new providers.JsonRpcProvider(process.env.REACT_APP_NETWORK_DEFAULT_PROVIDER)
    }   
  },
  getProvider: () => {
    return ethers.getDefaultProvider(iethContract.network())
  },
  getWalletProvider: () => {
    // TODO: other wallets (ronin, phantom, etc)
    return window.ethereum || window.ronin?.provider;
  },
  getWeb3Provider: () => {
    return new ethers.providers.Web3Provider(iethContract.getWalletProvider());
  },
  isCorrectNetwork: async () => {
    const provider = iethContract.getWeb3Provider();
    const network = await provider.getNetwork();

    return (network.chainId === iethContract.network().chainId);
  },
  apiRoot: async() => {
    return iethContract.api.call('get', '')
      .then((res) => res.json())
      .then((data) => { 
        AppContext.api = data;
      });
  },
  apiRootTime: () => {
    const now = new Date();

    if (AppContext.api.now) {
      now.setTime(new Date(AppContext.api.now));
    }

    return (now);
  },
  loadWalletData: async() => {
    return iethContract.api.call('get', `wallets/${iethContract.userAddress()}`)
      .then((res) => res.json())
      .then((data) => { 
        AppContext.walletData = data; 
        AppContext.score = data ? data.score || 0 : 0;
        AppContext.levelup = Math.ceil((AppContext.score / 40) - (data.usedLevelPoints || 0));
        
        if (AppContext.statusRef && AppContext.statusRef.current)
          AppContext.statusRef.current.update();
      });
  },
  loadMissions: async() => {
    return iethContract.api.call('get', `missions`)      
      .then((res) => res.json())
      .then(async (data) => { 
        if (data) {
          // get user missions and set status
          await iethContract.loadWalletData();            

          // check mission status and disable/enable
          if (data.missions) {
            const missionsByClass = [];
            data.missions.forEach((mission) => {
              mission.completed = AppContext.walletData.missions && AppContext.walletData.missions.find((m) => m === mission.id);
              mission.state = mission.completed ? 'completed' : 'pending';

              // find required missions
              if (mission.required) {
                mission.required.forEach((id) => {
                  const reqmission = data.missions.find((m) => m.id === id);
                  
                  if (!reqmission || !reqmission.completed) {
                    mission.state = 'disabled';
      
                    return false;
                  }
                });
              }

              // points required
              if (mission.points && mission.points > AppContext.points) {
                mission.state = 'disabled';
              }

              // rank required
              if (mission.rank && mission.rank > AppContext.rank) {
                mission.state = 'disabled';
              }

              missionsByClass[mission.class || 'global'] = missionsByClass[mission.class || 'global'] ?? [];
              missionsByClass[mission.class || 'global'].push(mission);
            });

            //AppContext.pendingMissions = data.missions.filter((m) => m.state === 'pending' && !m.class).length;
            AppContext.missions = missionsByClass;
            return data.missions || {};
          }

          AppContext.events.update.dispatch(eventUpdateEnum.missions);
        }
      })
      .catch((err) => console.error(err));    
  },
  loadRebels: async (onSetStatus) => {
    return iethContract.load('resistance', process.env.REACT_APP_CONTRACT_RESISTANCE, onSetStatus)
      .then((tokens) => {
        const foundercount = tokens.filter(item => item.tokenType === 'founder').length;
        const rebelcount = tokens.filter(item => item.tokenType === 'rebel' || item.tokenType === 'founder').length;
        AppContext.rank =  rebelcount >= 30 ? rankEnum.army : 
                                rebelcount >= 20 ? rankEnum.corps : 
                                rebelcount >= 13 ? rankEnum.brigade :
                                rebelcount >= 9 ? rankEnum.platoon :
                                (rebelcount >= 4 || foundercount >= 1) ? rankEnum.squad :
                                rebelcount >= 1 ? rankEnum.rebel :
                                rankEnum.wannabe;
        AppContext.rebels = tokens;
        iethContract.getPoints();
        iethContract.loadVouchers();

        AppContext.events.update.dispatch(eventUpdateEnum.rebels);
        return tokens;
      });
  },
  loadAssets: async (onSetStatus) => {
    return iethContract.load('assets', process.env.REACT_APP_CONTRACT_ASSETS, onSetStatus)
      .then((tokens) => {
        AppContext.assets = tokens;
        iethContract.getPoints();

        AppContext.events.update.dispatch(eventUpdateEnum.assets);
        return tokens;
      });
  },
  loadEarthlings: async (onSetStatus) => {
    return iethContract.load('earthlings', process.env.REACT_APP_CONTRACT_EARTHLINGS, onSetStatus)
      .then((tokens) => {
        AppContext.earthlings = tokens;
        iethContract.getPoints();

        AppContext.events.update.dispatch(eventUpdateEnum.earthlings);
        return tokens;
      });
  },  
  loadInvaders: async (onSetStatus) => {
    return iethContract.load('invaders', process.env.REACT_APP_CONTRACT_INVADERS, onSetStatus)
        .then((tokens) =>  {
          AppContext.invaders = tokens;
          iethContract.getPoints();

          AppContext.events.update.dispatch(eventUpdateEnum.invaders);
          return tokens;
        });
  },
  loadRoaches: async (onSetStatus) => {
    return iethContract.load('roaches', process.env.REACT_APP_CONTRACT_ROACHES, onSetStatus)
        .then((tokens) =>  {
          AppContext.roaches = tokens;

          AppContext.events.update.dispatch(eventUpdateEnum.roaches);
          return tokens;
        });
  },
  loadRoachesCounters: async (onSetStatus) => {
    iethContract.api.call('get', `invaders`)
      .then((res) => res.json())
      .then((data) => { 
          const roachesData = {
            totalSupply: 0,
            totalRoaches: 0,
            seasonStartDate: null,
            seasonEndDate: null,
            userRoaches: (AppContext.walletData.roaches || 0) + (AppContext.walletData.roaches2 || 0)
          };

          roachesData.totalSupply = data.roaches ? data.roaches.totalSupply || 1888 : 0;
          roachesData.totalRoaches = data.roaches.captured || 0;
          roachesData.seasonStartDate = data.roaches.seasonStartDate;
          roachesData.seasonEndDate = data.roaches.seasonEndDate;
      
          AppContext.roachesData = roachesData;
          AppContext.events.update.dispatch(eventUpdateEnum.roachesCounters);
      });
  },
  loadVouchers: async() => {
    await iethContract.loadWalletData();
    AppContext.vouchers = [];

    // asset vouchers
    AppContext.rebels.filter(rebel => rebel.voucherBalance > 0).forEach(rebel => {
      for (let t = 0; t < rebel.voucherBalance; t++) {
        AppContext.vouchers.push({
          type: 'asset', 
          voucher: rebel.id,
          voucherBalance: 1, 
          legend: 'Valid for a random Pet or Gadget',
          metadata: {
            name: 'Voucher: Pet or Gadget',
            image: require('./css/images/icons/voucher-asset.png')
          }
        });
      }

      AppContext.events.update.dispatch(eventUpdateEnum.vouchers);
      //rebel.voucherBalance = 0;
    });

    // wallet vouchers
    if (AppContext.walletData.vouchers) {
      await AppContext.walletData.vouchers.forEach(async voucher => {
        // check voucher balance
        await iethContract.getVoucherBalance(voucher).then(
            (data) => {
              if (data.toNumber() === 0) {
                AppContext.vouchers.push({
                  type: voucher.type,
                  voucher: voucher.voucher, 
                  prizeId: voucher.prizeId || 0,
                  voucherBalance: 1,
                  legend: `Valid for a captured ${voucher.type.charAt(0).toUpperCase()}${voucher.type.slice(1)}`,
                  metadata: {
                    name: `Voucher: ${voucher.type.charAt(0).toUpperCase()}${voucher.type.slice(1)}`,
                    image: require(`./css/images/icons/voucher-${voucher.type}.png`)
                  }
                });

                AppContext.events.update.dispatch(eventUpdateEnum.vouchers);
              };
          })
          .catch(err => {
            console.log(voucher, err);
            AppContext.events.update.dispatch(eventUpdateEnum.vouchers);
          });
      });
    }    
  },
  loadPixelRebels: async (onSetStatus) => {
    return iethContract.load('resistance.pixelart', process.env.REACT_APP_CONTRACT_PIXEL_REBELS, onSetStatus)
      .then((tokens) => {
        AppContext.pixels = tokens;

        AppContext.events.update.dispatch(eventUpdateEnum.pixels);

        if (!process.env.REACT_APP_CONTRACT_PIXEL_REBELS) {
          return tokens;
        }

        // check owned rebels and not minted pixel rebel
        const contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_PIXEL_REBELS, abiAEKPixelRebels.data, iethContract.getProvider());

        AppContext.rebels.forEach((rebel) => {
          const id = rebel?.metadata?.index;

          if (id && !tokens.find((token) => token.id === id)) {
            // check availability in pixel contract
            contract.exists(id)
              .then(async (result) => {
                if (result) return;

                // add pixel token to mint
                const indexhex = id.toString(16).padStart(8, '0');
                const metadata = `https://invasioneth.art/resistance.pixelart/metadata/${indexhex}.json`;
        
                await fetch(metadata, { method: 'get', dataType: 'json' })
                  .then((res) => res.json())
                  .then(async (data) => { 
                    AppContext.pixels.push({ 
                      id: id,
                      className: 'token-not-minted',
                      hidden: true,
                      metadata: data,
                      onClick: () => {
                        // mint token
                        const signer = contract.connect((iethContract.getWeb3Provider()).getSigner());

                        signer.mint(id, 1)
                          .then((result) => {
                            
                          })
                          .catch((err) => console.log(err))
                      }
                    });
                    AppContext.events.update.dispatch(eventUpdateEnum.pixels);
                  })        
              })
              .catch((err) => console.log(err))
          }
        });

        return tokens;
      });
  },
  redeem: async(token, onSetStatus) => {
    if (token.type === '$RBLB') {
      return iethContract.RBLB.exchange(token, onSetStatus);
    }

    const contractAddress = token.type === 'asset' ? process.env.REACT_APP_CONTRACT_ASSETS : 
                              token.type === 'invader' ? process.env.REACT_APP_CONTRACT_INVADERS : 
                                token.type === 'roach' ? process.env.REACT_APP_CONTRACT_ROACHES : "";

    if (!contractAddress) {
      if (onSetStatus) onSetStatus(<p>Voucher Collection Not Found</p>, null);
      return;
    }

    if (!token.voucher) {
      if (onSetStatus) onSetStatus(<p>Voucher Not Found</p>, null);
      return;
    }

    // load contract
    let abi = {};
    let redeemArgs = [token.voucher];
    switch (token.type) {
      case 'invader':   abi = abiAEKInvadersAssetContractShared.data;   break;
      case 'champion':  abi = abiAEKBattleEthAssets.data;               break;
      case 'roach':     abi = abiAEKInvasionEthRoaches.data; 
                        redeemArgs.push(BigNumber.from(token.prizeId)); break;
      case '$RBLB':     abi = abiAEKInvasionEthRoaches.data; 
                        redeemArgs.push(token.hash);
                        redeemArgs.push(BigNumber.from(token.points));  break;
      default:          abi = abiAEKAssetContractShared.data;           break;
    }

    // load contract
    if (onSetStatus) onSetStatus(<p>Connecting to the Network...</p>, null);

    if (!await iethContract.isCorrectNetwork()) {
      if (onSetStatus) onSetStatus(<><p>Please, switch wallet network</p><div>Name: {process.env.REACT_APP_NETWORK_NAME}</div><div>Chain Id: {process.env.REACT_APP_NETWORK_CHAINID}</div><div>RPC: {process.env.REACT_APP_NETWORK_DEFAULT_PROVIDER}</div></>, null);
      return;
    }

    const contract = new ethers.Contract(contractAddress, abi, iethContract.getProvider());
    const signer = contract.connect((iethContract.getWeb3Provider()).getSigner());

    await signer.redeem(...redeemArgs, { gasLimit: 1000000 })
      .then((result) => { 
          if (onSetStatus) onSetStatus(
            <><p>Redeeming Voucher</p><p>Please wait a couple of minutes</p></>, 
            result.hash);
      })
      .catch((err) => { 
        if (onSetStatus) onSetStatus(
          <><p>Error Redeeming Voucher</p><p><small>{err.data ? err.data.message : err.message}</small></p></>,
          null); 
    });
  },
  load: async (type, contractAddress, onSetStatus) => {
    let transfers = [];
    
    //console.log(`Loading ${type} protocols... ${contractAddress}`);
    if (onSetStatus) onSetStatus(`Loading ${type} protocols`);
  
    if (!contractAddress)
      return [];
  
    await iethContract.api.call('get', `contracts/${contractAddress}/transfers`)      
      .then((res) => res.json())
      .then((data) => { 
        transfers = data;
      })
      .catch((err) => console.error(err));

    if (!transfers || Object.keys(transfers).length === 0)
      return [];

    //if (onSetStatus) onSetStatus(`${type.charAt(0).toUpperCase()}${type.slice(1)}: ${transfers.owners ? transfers.owners.length : transfers.ids.length}`);
      
    // load contract
    let abi = {};
    switch (type) {
      case 'invaders':            abi = abiAEKInvadersAssetContractShared.data;   break;
      case 'champions':           abi = abiAEKBattleEthAssets.data;               break;
      case 'roaches':             abi = abiAEKInvasionEthRoaches.data;            break;
      case 'resistance.pixelart': abi = abiAEKPixelRebels.data;                   break;
      default:                    abi = abiAEKAssetContractShared.data;           break;
    }

    const contract = new ethers.Contract(contractAddress, abi, iethContract.getProvider());
    
    // load rules
    const rules = iethContract.rules.load();

    // get all tokens from user
    const ids = transfers.owners ? [...new Set(transfers.owners.filter(owner => owner?.owner?.toUpperCase() === AppContext.userAddress?.toUpperCase()).map(item => item.id))] : [...new Set(transfers.ids)];
    const accounts = Array.from({length: ids.length}, (_, i) => AppContext.userAddress);
  
    const tokenBalances = [];
  
    if (contract.balanceOfBatch) {
      await contract.balanceOfBatch(accounts, ids)
          .then((result) => result.forEach((value, index) => tokenBalances.push({id: BigNumber.from(ids[index]), balance: value ? value.toNumber() : 0})))
          .catch((err) => console.log(err));

      await Promise.all(tokenBalances.map(async item => {
          if (transfers.tokenType || !contract.getTokenType) {
            item.tokenType = transfers.tokenType || type;
          } else {
            await contract.getTokenType(item.id)
            .then((result) => {
                item.tokenType = result;
            })
            .catch((err) => console.log(err));
          } 
      }));
    } else if (contract.ownerOf) {
      await Promise.all(ids.map(id => {
        return contract.ownerOf(id)
          .then((result) => {
            if (result.toLowerCase() === AppContext.userAddress.toLowerCase()) {
              tokenBalances.push({id: parseInt(id), balance: 1});
            }
          })
          .catch((err) => console.log(type, id, err));
      })).then(() => {
        return tokenBalances;
      });
    } else {
      const _tokens = [];
      ids.forEach((id) => _tokens.push({id: parseInt(id), balance: 1}));
      return _tokens;
    }

    const tokens = [];
  
    const addToken = async (data, item) => {
      item.metadata = data; 

      if (!item.tokenType && item.metadata && item.metadata.attributes) {
        const attrType = item.metadata.attributes.find((attr) => attr.trait_type === 'Type');
        const attrRarity = item.metadata.attributes.find((attr) => attr.trait_type === 'Rarity');

        item.tokenType = (attrType || {}).value || '';
        item.tokenRarity = (attrRarity || {}).value || '';
      }

      if (item.metadata.nft) {
        item.metadata.name = item.metadata.nft.name;
        item.metadata.image = item.metadata.nft.image_url;
      }

      iethContract.setTokenPoints(item);
      await iethContract.setTokenVoucherBalance(rules, item);
      //console.log(`${item.metadata.name} (${item.id}) - Checking DNA...`);
      if (onSetStatus) onSetStatus(`${item.metadata.name} - Checking DNA...`);
      tokens.push(item);
    }

    await Promise.all(tokenBalances.filter(item => item.balance > 0).map(async item => {
        const index = typeof item.id === 'number' ? item.id : iethContract.tokenIdParse(item.id).index;
        const indexhex = index.toString(16).padStart(8, '0');
        const metadata = `https://invasioneth.art/${type}/metadata/${indexhex}.json`;

        await fetch(metadata, { method: 'get', dataType: 'json' })
          .then((res) => res.json())
          .then(async (data) => { 
            await addToken(data, item);
          })
        .catch(async (err) => {
          // local metadata not found
          // get from ipfs
          const uriMethod = contract.uri ? contract.uri : contract.tokenURI ? contract.tokenURI : null;
          if (uriMethod) {
            await uriMethod(item.id)
              .then((result) => {
                item.ipfs = result;
                
                return fetch(item.ipfs.replace('ipfs://', 'https://ipfs.io/ipfs/').replace('0x{id}', item.id),
                    {
                        method: 'get',
                        dataType: 'json'
                    })
                    .then((res) => res.json())
                    .then(async (data) => { 
                      if (data && !data.success) {
                        // open sea API
                        const options = {
                          method: 'GET',
                          headers: {accept: 'application/json', 'X-API-KEY': process.env.REACT_APP_X_API_KEY}
                        };
                        
                        fetch(`https://api.opensea.io/v2/chain/MATIC/contract/${contractAddress}/nfts/${item.id}`, options)
                          .then(response => response.json())
                          .then(async (data) => {
                            await addToken(data, item);
                          })
                          .catch(err => console.error(err));

                        return;
                      }

                      await addToken(data, item);
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
          }
        });
    }));
  
    tokens.sort((a, b) => {
      return (b.metadata.level || 0) - (a.metadata.level || 0);
    });
  
    return tokens;
  },
  getPoints: () => {
    AppContext.points = 0;
  
    if (AppContext.rebels) {
      AppContext.rebels.forEach(element => {
        AppContext.points += element.points;
      });
    }

    if (AppContext.earthlings) {
      AppContext.earthlings.forEach(element => {
        AppContext.points += element.points;
      });
    }

    if (AppContext.assets) {
      AppContext.assets.forEach(element => {
        AppContext.points += element.points;
      });
    }
  },
  setTokenPoints: (token) => { 
    let points = 0;

    switch (token.tokenType) {
      case 'follower':  points = 10; break;
      case 'wannabe':   points = 15; break;
      case 'angel':     points = 20; break;
      case 'rebel':     points = 30; break;
      case 'earthling': 
      case 'founder':   points = 40; break;
      default:          points = 0; break;
    }

    if (token.tokenType === 'Pet' || token.tokenType === 'Gadget') {
      switch (token.tokenRarity) {
        case 'Common':    points = 10; break;
        case 'Rare':      points = 15; break;
        case 'Epic':      points = 20; break;
        case 'Legendary': points = 30; break;
        default:          points = 0; break;
      }
    }

    token.points = points * token.balance;

    if (token.metadata) {
      if (!token.metadata.attributes) {
        token.metadata.attributes = [];
      }

      token.metadata.attributes.push({trait_type: 'Points', value: token.points});
      
      if (token.balance > 1) {
        token.metadata.attributes.push({trait_type: 'Balance', value: token.balance + 'x'});
      }

      // token level (rebel & founder)
      if (token.tokenType === 'rebel' || token.tokenType === 'founder') {
        // get level & class data from DB
        iethContract.api.call('get', `rebels/${token.id}`)
          .then((res) => res.json())
          .then(async (data) => {
            if (data.class) {
              token.metadata.attributes.unshift({trait_type: 'Class', value: data.class});
            }

            const level = data.level > 0 ? data.level : token.tokenType === 'rebel' ? 1 : 5;
            token.metadata.attributes.unshift({trait_type: 'Level', value: level});
            token.metadata.level = level;

            token.metadata.attributes.unshift({trait_type: 'Rarity', value: data.ranking});
            token.metadata.rarity = data.ranking;
        });
      }
    }
  },
  getVoucherBalance: async(token) => {
    const contractAddress = token.type === 'asset' ? process.env.REACT_APP_CONTRACT_ASSETS : 
                              token.type === 'invader' ? process.env.REACT_APP_CONTRACT_INVADERS : 
                                token.type === 'roach' ? process.env.REACT_APP_CONTRACT_ROACHES : "";

    if (!contractAddress) {
      return BigNumber.from(1);
    }

    if (!token.voucher) {
      return BigNumber.from(1);
    }

    // load contract
    let abi = {};
    switch (token.type) {
      case 'invader':   abi = abiAEKInvadersAssetContractShared.data;   break;
      case 'champion':  abi = abiAEKBattleEthAssets.data;               break;
      case 'roach':     abi = abiAEKInvasionEthRoaches.data;            break;
      default:          abi = abiAEKAssetContractShared.data;           break;
    }

    const contract = new ethers.Contract(contractAddress, abi, iethContract.getProvider());

    if (!contract || !contract.getVoucherBalance) {
      return BigNumber.from(1);
    }

    return contract.getVoucherBalance(token.voucher);
  },
  setTokenVoucherBalance: async (rules, token) => {
    if (!rules) {
      rules = iethContract.rules.load();
    }
    
    if (!rules || token.tokenType === 'wannabe' || token.tokenType === 'earthling')
      return;

    return rules.getVoucherBalance(token.id)
        .then((result) => token.voucherBalance = result.toNumber())
        .catch((err) => console.log(err));
  },
  signMessage: async (message) => {
    const signer = iethContract.getWeb3Provider().getSigner();

    // create eip4361 message
    AppContext.nonce = await makeNonce();
    const msg = produceMessage(
      process.env.REACT_APP_DOMAIN, 
      AppContext.userAddress,
      message, 
      process.env.REACT_APP_URI, 
      1, 
      AppContext.nonce);

    return signer.signMessage(msg);
  },
  rules: {
    load: () => {
      //console.log(`Loading rules... ${process.env.REACT_APP_CONTRACT_RULES}`);

      if (!process.env.REACT_APP_CONTRACT_RULES)
        return null;

      return new ethers.Contract(process.env.REACT_APP_CONTRACT_RULES, abiAEKInvasionEthRules.data, iethContract.getProvider());
    }
  },
  roaches: {
    load: () => {
      //console.log(`Loading roach contract... ${process.env.REACT_APP_CONTRACT_ROACHES}`);

      if (!process.env.REACT_APP_CONTRACT_ROACHES)
        return null;

      return new ethers.Contract(process.env.REACT_APP_CONTRACT_ROACHES, abiAEKInvasionEthRoaches.data, iethContract.getProvider());
    }
  },
  signIn: async() => {
    return new Promise((resolve, reject) => {
      if (AppContext.signature) {
        resolve({signature: AppContext.signature, nonce: AppContext.nonce});
        return;
      }
      
      iethContract.signMessage('Sign In')
        .then((signature) => {
          AppContext.signature = signature;
          resolve({signature: AppContext.signature, nonce: AppContext.nonce});
        })
        .catch(err => reject(err));
    })

  },
  updateChances: () => {
    AppContext.points = AppContext.points || 0;
    AppContext.score = AppContext.score || 0;

    AppContext.chances = 1;

    // +1 chance always
    // +1 chance for each 15 points
    // +1 chance for each 40 score
    AppContext.chances += Math.round(AppContext.points / 15);
    AppContext.chances += Math.floor(AppContext.score / 40);
    
    // used chances
    const now = iethContract.apiRootTime();
    const today = '' + now.getUTCFullYear() + '-' + (now.getUTCMonth() + 1) + '-' + now.getUTCDate();

    if (AppContext.walletData && 
        AppContext.walletData.raffles && 
        AppContext.walletData.raffles.lastDraw) {

        if (AppContext.walletData.raffles.lastDraw.waitTimeFrom) {
          let waitTimeTo = new Date();
          waitTimeTo.setTime(new Date(AppContext.walletData.raffles.lastDraw.waitTimeFrom));
          waitTimeTo.setTime(waitTimeTo.getTime() + (AppContext.walletData.raffles.lastDraw.waitTime * 60 * 60 * 1000));
  
          // wait period not ended
          if (waitTimeTo.getTime() - now.getTime() > 0 ) {
            AppContext.chances = 0;
          } else if (AppContext.walletData.raffles.lastDraw.date === today) {
            AppContext.chances -= AppContext.walletData.raffles.lastDraw.usedChances || 0;
            AppContext.chances = AppContext.chances < 0 ? 0 : AppContext.chances;
          }
        }
    }
  },
  updateStatus: async () => {
    return iethContract.loadWalletData()
      .then(() => {
        iethContract.updateChances();

        AppContext.events.update.dispatch(eventUpdateEnum.status);

        if (AppContext.statusRef && AppContext.statusRef.current)
          AppContext.statusRef.current.update();
      });
  },
  tokenIdParse: (id) => {
    const INDEX_BITS = 56;
    const SUPPLY_BITS = 40;
    const SUPPLY_MASK = BigNumber.from(1).shl(SUPPLY_BITS).sub(1);
    const INDEX_MASK =  BigNumber.from(1).shl(INDEX_BITS + SUPPLY_BITS).sub(1).xor(SUPPLY_MASK);
    
    return {
        maxSupply: id.and(SUPPLY_MASK).toNumber(),
        index: id.and(INDEX_MASK).shr(SUPPLY_BITS).toNumber(),
        creatorAddress: id.shr((SUPPLY_BITS + INDEX_BITS)).toHexString()
    }
  },
  RBLB: {
    getContract: () => {
      return new ethers.Contract(
        process.env.REACT_APP_CONTRACT_RBLB, 
        abiAEKRebelBucks.data, 
        iethContract.getProvider()
      );
    },
    getSigner: () => {
      // TODO:
      // check errors and valid contract
      return iethContract.RBLB.getContract().connect((iethContract.getWeb3Provider()).getSigner());
    },    
    exchange: async(token, onSetStatus) => {
      const contractAddress = token.type === '$RBLB' ? process.env.REACT_APP_CONTRACT_RBLB : "";
  
      if (!contractAddress) {
        if (onSetStatus) onSetStatus(<p>RBLB Contract Not Found</p>, null);
        return;
      }
  
      if (!token.hash) {
        if (onSetStatus) onSetStatus(<p>Hash Not Found</p>, null);
        return;
      }
  
      // load contract
      let abi = abiAEKRebelBucks.data;
      let redeemArgs = [token.hash, token.points, token.signed];

      // load contract
      if (onSetStatus) onSetStatus(<p>Connecting to the Network...</p>, null);
  
      if (!await iethContract.isCorrectNetwork()) {
        if (onSetStatus) onSetStatus(<><p>Please, switch wallet network</p><div>Name: {process.env.REACT_APP_NETWORK_NAME}</div><div>Chain Id: {process.env.REACT_APP_NETWORK_CHAINID}</div><div>RPC: {process.env.REACT_APP_NETWORK_DEFAULT_PROVIDER}</div></>, null);
        return;
      }
  
      const contract = new ethers.Contract(contractAddress, abi, iethContract.getProvider());
      const signer = contract.connect((iethContract.getWeb3Provider()).getSigner());
  
      return iethContract.signIn()
        .then((results) => {
          // clean points in wallet
          iethContract.api.call('post', `wallets/${iethContract.userAddress()}/RBLB/${token.hash}/complete`, {
              address: iethContract.userAddress(),
              signature: results.signature,
              nonce: results.nonce,
              signed: token.signed,
              points: token.points
          })
          .then((res) => res.json())
          .then((data) => {
            signer.exchangePoints(...redeemArgs)
              .then((result) => { 
                  if (onSetStatus) onSetStatus(
                    <><p>Exchanging Points</p><p>Please wait a couple of minutes</p></>, 
                    result.hash);
              })
              .catch((err) => { 
                /*
                // recover points in wallet
                iethContract.api.call('post', `wallets/${iethContract.userAddress()}/RBLB/${token.hash}/recover`, {
                    address: iethContract.userAddress(),
                    signature: results.signature,
                    nonce: results.nonce
                })
                .then((res) => res.json())
                */                
                if (onSetStatus) onSetStatus(
                  <><p>Error Exchanging Points</p><p><small>{err.data ? err.data.message : err.message}</small></p></>,
                  null); 
              })
          })
      });
    },
    balance: async() => {
      let redeemArgs = [iethContract.userAddress()];

      iethContract.RBLB.getSigner().balanceOf(...redeemArgs)
        .then((result) => { 
          AppContext.bucks = ethers.utils.formatEther(result);
          AppContext.rblb = result;
  
          AppContext.events.update.dispatch(eventUpdateEnum.bucks);
        })
        .catch((err) => {
          console.error(err);
          AppContext.bucks = -1;
          AppContext.rblb = BigNumber.from(0);
          AppContext.events.update.dispatch(eventUpdateEnum.bucks);
        });
  
      iethContract.RBLB.getSigner().getPointRate()
        .then((result) => {
          AppContext.bucksRate = ethers.utils.formatEther(result);
        })
        .catch((err) => {
          console.error(err);
          AppContext.bucksRate = 0;
          AppContext.events.update.dispatch(eventUpdateEnum.bucks);
        });
  
      AppContext.lastExchangeUsed = true;
      if (AppContext.walletData.lastExchange) {
        AppContext.walletData.lastExchange.type = '$RBLB';
  
        // check if last exchange was used
        iethContract.RBLB.getSigner().isExchangeHashUsed(...[AppContext.walletData.lastExchange.hash])
          .then((result) => {
            AppContext.lastExchangeUsed = result;
            AppContext.events.update.dispatch(eventUpdateEnum.bucks);
          })
          .catch((err) => {
          });
      }
    },
    getExchangeVoucher: async (percentage) => {
      await iethContract.loadWalletData();
      iethContract.RBLB.balance();
  
      return new Promise((resolve, reject) => {
        iethContract.signIn()
          .then((results) => {
            iethContract.api.call('post', `wallets/${iethContract.userAddress()}/exchangeScore`, {
              address: iethContract.userAddress(),
              signature: results.signature,
              nonce: results.nonce,
              percentage: percentage || 100
            })
            .then((res) => res.json())
            .then((data) => resolve (data))
            .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    }
  }
}

class Landing extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      authorized: true,
      userAddress: null,
      contractLoaded: false,
      showmodalNickname: false,
      showmodalVouchers: false,
      showmodalExchangePoints: false,
      exchangePointsVoucher: null,
      showDailyRewards: false,      
      nextRewardNumber: 0,
      reward: {},
      showPaycheck: false,
      paycheckDays: 0,
      showHelp: false,
      selectedSection: null,
      status: "",
      gauges: {
        score: 0,
        bucks: 0,
        points: 0,
        chances: 0,
        roaches: 0
      },
      profileRebel: null,
      rank: null,
      nickname: null,
      hasVouchers: false
    };

    this.appRef = React.createRef();
    this.modalNicknameRef = React.createRef();
    AppContext.statusRef = React.createRef();
  }

  componentDidMount() {
    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.status || event === eventUpdateEnum.bucks) {
        this.setState({
          gauges: { 
            score: AppContext.score, 
            bucks: AppContext.bucks,
            points: AppContext.points, 
            chances: AppContext.chances, 
            roaches: (AppContext.walletData.roaches || 0) + (AppContext.walletData.roaches2 || 0) 
          },
          reward: {
            lastReward: AppContext.walletData?.rewards?.lastReward || 0,
            lastRewardDate: AppContext.walletData?.rewards?.lastRewardDate
          }
        });
      }

      if (event === eventUpdateEnum.rebels) {
        const rebelcount = AppContext.rebels ? AppContext.rebels.filter(item => item.tokenType === 'rebel' || item.tokenType === 'founder').length : 0;

        // get random rebel
        this.setState({
          profileRebel: rebelcount > 0 ? AppContext.rebels[Math.floor(Math.random() * rebelcount)] : null,
          rank: rankEnumString[AppContext.rank],
          rebelCount: rebelcount > 0 ? rebelcount + " Rebel" + (rebelcount > 1 ? "s" : "") : ""
        });
      }
    });

    this.setState({loading: false});

    isWalletConnected()
      .then((account) => {
        AppContext.userAddress = account;
        
        if (account)        
          this.initialize();
      })
      .catch((err) => console.log(err))
  }

  setStatus(status) {
    this.setState({status: status});
  }

  async initialize() {
    await iethContract.apiRoot();

    this.setStatus('Loading Resistance Protocols...');
    await iethContract.loadWalletData();

    // show daily rewards
    AppContext.walletData.rewards = AppContext.walletData.rewards || {};
    const lastRewardDate = new Date(AppContext.walletData.rewards.lastRewardDate);
    const now = iethContract.apiRootTime();

    // must pass at least 24 hours
    const nextRewardNumber = ((AppContext.walletData.rewards.lastReward || 0) + 1) % 7;
    if (!AppContext.walletData.rewards.lastRewardDate || ((now - lastRewardDate) / (1000*60*60) >= 24)) {
      this.setState({showDailyRewards: true, nextRewardNumber: nextRewardNumber === 0 ? 7 : nextRewardNumber});
    }

    const loaders = [
      iethContract.loadRebels(),
      iethContract.loadAssets(),
      iethContract.loadEarthlings(),
      iethContract.loadRoachesCounters(),
      iethContract.loadMissions(),
      iethContract.RBLB.balance()
    ];

    Promise.all(loaders)
      .then(() => {
        // show paycheck
        // if no paycheck ever collected, use first day of the month to start
        AppContext.walletData.paychecks = AppContext.walletData.paychecks || {};
        const lastPaycheckDate = AppContext.walletData.paychecks.lastPaycheckDate ? 
                                    new Date(AppContext.walletData.paychecks.lastPaycheckDate) : 
                                    new Date(now.getFullYear(), now.getMonth(), 1);

        // must pass at least a week from last paycheck
        const days = Math.round((now - lastPaycheckDate) / (1000*60*60*24));
        if (days >= 7 && AppContext.rebels?.length > 0) {
          this.setState({showPaycheck: true, paycheckDays: days});
        }

        // Roaches and invaders loader async
        iethContract.loadPixelRebels();
        iethContract.loadInvaders();        
        iethContract.loadRoaches();                
      })
      .finally(() => {
        AppContext.contractsLoaded = true;
        iethContract.updateStatus();
        AppContext.events.initilize.dispatch();

        this.getLeaderBoard();

        this.setStatus('');
        this.setState({contractLoaded: AppContext.contractsLoaded, nickname: iethContract.nickname(), hasVouchers: AppContext.vouchers && AppContext.vouchers.length > 0});
      });

    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.vouchers) {
        this.setState({hasVouchers: AppContext.vouchers && AppContext.vouchers.length > 0});
      }
    });
  }

  async getLeaderBoard() {
    return iethContract.api.call('get', 'wallets?contents=true')
      .then((res) => res.json())
      .then((data) => {
        // global score leaders
        const leaders = data.sort((a, b) => a.score < b.score ? 1 : -1);
        const loggeduserindex = leaders.findIndex(wallet => wallet.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase());
        this.setState({showTrophy: loggeduserindex < 15, globalLeaderboard: loggeduserindex + 1});
      })
      .catch((err) => console.error(err));
  }

  onSetNickname() {
    this.modalNicknameRef.current.setState({
      title: "Set Nickname",
      form: <ModalSetNickName></ModalSetNickName>,
      onClick: async (e) => {
        e.preventDefault();
        this.setState({showmodalNickname:false});
        const data = e.target.form;

        await iethContract.signIn()
        .then(async (results) => {
            await iethContract.api.call('put', `wallets`, {
                address: iethContract.userAddress(),
                signature: results.signature,
                nonce: results.nonce,
                nickname: data.nickname.value,
                showOSLink: data.showOSLink.checked
            })
            .then((result) => {
              if (result.status === 200) {
                this.setState({nickname: data.nickname.value});
                iethContract.loadWalletData();
              }
            });
        });
      }
    });    
    this.setState({showmodalNickname: true});
  }

  async onShowVouchers() {
    await iethContract.loadVouchers();
    this.setState({showmodalVouchers: true});
  }

  async onShowExchangePoints() {
    iethContract.RBLB.getExchangeVoucher()
      .then((data) => this.setState({showmodalExchangePoints: true, exchangePointsVoucher: data}))
      .catch(() => {})
  }

  onPickWallet() {
  }

  render() {
    const discordhref = 'https://discord.gg/4guwfvhgbr';
    const openseacollection = 'https://opensea.io/collection/invasion-eth-resistance/overview';
    const twitter = 'https://twitter.com/InvasionEth';
    // const website = 'https://invasioneth.art';
  
    const registered = AppContext && AppContext.walletData && AppContext.walletData.nickname;

    return (
      <>
        {
          this.state.selectedApp == null &&
          <div className="landing-parallax">
            <div className="section-main"></div>
              {
                !this.state.loading &&
                <Apps ref={this.appRef} 
                    initialized={this.state.contractLoaded}
                    onAppClick={(app) => this.onAppClick(app)} 
                    onAppClose={() => this.onAppClick(null)}>
                </Apps>
              }
              <div className="landing-main">
                {
                  this.state.showHelp &&
                  <div className="help">
                    <div className="close-terminal" title="Close" onClick={() => {this.setState({showHelp: false})}}>X</div>
                    <Carousel interval={5000}>
                      <Carousel.Item>
                        <img src={require("./css/images/help/chances-instructions.png")} alt=""/>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={require("./css/images/help/ranks-instructions.png")} alt=""/>
                      </Carousel.Item>
                    </Carousel>
                  </div>                    
                }
                {
                  !this.state.contractLoaded &&
                  AppContext.userAddress &&
                  <div className="waitingbar">
                    <InfinitySpin width='200' color="#ffffff"/>                  
                  </div>  
                }
                {
                  this.state.status !== "" &&
                  <div className="statusbar">
                    {this.state.status}
                  </div>
                }
                {
                  !this.state.loading &&
                  !AppContext.userAddress &&
                  <div className="connectbar">
                    <div>
                      <MetaMaskAuth onAddressChanged={address => {}}/>
                    </div>
                  </div>
                }
                {
                  this.state.contractLoaded &&
                  //this.state.profileRebel != null &&
                  <>
                    <div className="profile" onClick={() => this.onSetNickname()}>
                      <div className="profile-picture" title={this.state.profileRebel?.metadata.name}>
                        <img src={(this.state.profileRebel ? this.state.profileRebel.metadata.external_url + ".png" : require("./css/images/resistance-fan.png"))} alt=""></img>
                      </div>
                      <div className="profile-data">
                        {
                          registered &&
                          <div className="nickname"><div className="leaderboard-rank">#{this.state.globalLeaderboard}</div>{this.state.nickname}</div>
                        }
                        {
                          !registered &&
                          <div className="nickname">Unregistered</div>
                        }
                        <div className="rank">{this.state.rank}</div>
                        <div className="rebel-count">
                          {
                            registered &&
                            this.state.showTrophy &&
                            <img src={require("./css/images/icons/trophy.png")} alt=""></img>
                          }
                          {this.state.rebelCount}
                        </div>
                      </div>
                    </div>
                    {
                      this.state.hasVouchers &&
                      <div className="profile-tickets">
                        <img src={require("./css/images/icons/ticket.png")} alt="" onClick={() => this.onShowVouchers()}></img>
                      </div>
                    }
                  </>
                }
                {
                  this.state.contractLoaded &&
                  <div className="gaugebar gaugebar-top-right">
                    <Gauge icon={require("./css/images/icons/coins.png")} 
                      value={this.state.gauges.score} title="Coins" 
                      className="gauge-clickable" 
                      onClick={() => this.onShowExchangePoints()}/>
                    <Gauge icon={require("./css/images/icons/rblb.png")} 
                      value={(this.state.gauges.bucks !== -1 ? this.state.gauges.bucks : 'Err!')} 
                      className="gauge-clickable" 
                      title={this.state.gauges.bucks !== -1 ? 'Rebel Bucks' : 
                        `Network Error (change to ${iethContract.network().name} and click to refresh)`}  
                      onClick={() => this.onShowExchangePoints()}/>
                  </div>
                }
                <div className="socialbar">
                  {/*<a className="button" title="Web Site" target="_blank" rel="noreferrer" href={website}><img src={require("./css/images/logo-website.png")} alt=""/></a>*/}
                  <a className="button" title="Connect to Discord" target="_blank" rel="noreferrer"  href={discordhref}>
                    <img src={require("./css/images/logo-discord.png")} alt=""/>
                  </a>
                  <a className="button" title="Open Sea Market" target="_blank" rel="noreferrer" href={openseacollection}>
                    <img src={require("./css/images/logo-opensea.png")} alt=""/>
                  </a>
                  <a className="button" title="Twitter" target="_blank" rel="noreferrer" href={twitter}>
                    <img src={require("./css/images/logo-twitter.png")} alt=""/>
                  </a>
                  {/*<a className="button button-help" title="Help" href="/#" onClick={() => {this.setState({showHelp: true})}}><QuestionCircleFill></QuestionCircleFill></a>*/}
                </div>
                <div className="versionbar">
                  {
                    process.env.REACT_APP_TEST_MODE &&
                    <div className="testmode-enabled" onClick={() => {this.onPickWallet()}}>TEST MODE</div>
                  }
                  <div>APP v{process.env.REACT_APP_VERSION}</div>
                  {
                    AppContext.api && AppContext.api.version &&
                    <div>API v{AppContext.api.version}</div>
                  }
                </div>
              </div>
          </div>
        }
        {
          this.state.selectedApp != null &&
          this.state.selectedApp.component
        }
        <Paycheck
          show={this.state.showPaycheck}
          days={this.state.paycheckDays}
          onClose={() => {this.setState({showPaycheck: false})}}>
        </Paycheck>
        <DailyRewards
          show={this.state.showDailyRewards}
          reward={this.state.reward}
          nextRewardNumber={this.state.nextRewardNumber}
          onClose={() => {this.setState({showDailyRewards: false})}}> 
        </DailyRewards>
        <MissionsModal 
          ref={this.modalNicknameRef}
          onClick={() => {}}    
          show={this.state.showmodalNickname}
          onClose={() => {this.setState({showmodalNickname: false});}}>
        </MissionsModal>
        <VouchersModal
          onClick={() => {}}    
          show={this.state.showmodalVouchers}
          onClose={() => {this.setState({showmodalVouchers: false});}}>
        </VouchersModal>
        <RBLBModal 
          show={this.state.showmodalExchangePoints} 
          token={this.state.exchangePointsVoucher}
          onClose={async (close) => {
            this.setState({showmodalExchangePoints: false});
           
            if (close) {
              await iethContract.loadWalletData();
              await iethContract.RBLB.balance();
            }
          }}>
        </RBLBModal>
      </>
    );
  }

  onAppClick = (app) => {
    iethContract.apiRoot()
      .then(() => this.setState({selectedApp: app}));
  }
}

export default Landing;