import { Container } from "react-bootstrap";
import { GameOption } from "./GameOption"
import { useState } from "react";
import { AppContext, eventUpdateEnum } from "AppContext";

export const getUIItems = (world, items) => {
  items = items || [];
  const result = items.slice();

  result.forEach(item => { 
    const o = world.loader.getObject(item.id);

    if (!o) return true;

    item.o = o;
    item.label = o.data?.label || o.id;
    item.data = o.data;
    item.image = o.imageSrc;
    item.imageClip = o.imageClip;
    item.style = {
      height: `${item.imageClip.height}px`,
      width: `${item.imageClip.width}px`,
      background: `url(${item.image}) -${item.imageClip.left}px -${item.imageClip.top}px`
    }
  })

  return result;
}

export const GameOptionInventory = (props = {}) => {
  const [items, setItems] = useState([])
  
  const onShowOption = () => {
    if (!props.world?.player) return

    // get nfts
    props.world.player.loadAssets && props.world.player.loadAssets();

    setItems(getUIItems(props.world, Object.keys(props.world.player.items).map(key => props.world.player.items[key])))

    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.gameItems) {
        setItems(getUIItems(props.world, Object.keys(props.world.player.items).map(key => props.world.player.items[key])))
      }
    });
  }

  const onItemUpdated = (item) => {
    setItems(getUIItems(props.world, Object.keys(props.world.player.items).map(key => props.world.player.items[key])));    
  }

  return (
    <GameOption 
      {...props}
      title={props.title || 'Inventory'} 
      className={'inventory'}
      icon={require("../../../css/images/icons/inventory.png")}
      onShowOption={() => onShowOption()}
      component={Inventory}
      items={items}
      onItemUpdated={(item) => onItemUpdated(item)}/>
  )
}

const Inventory = (props = {}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const market = process.env.REACT_APP_REBELCITY_MARKET_URL;

  const onItemClick = (e, item) => {
    e.stopPropagation();

    setSelectedItem(item);
  }

  return (
      <Container fluid>
        <>
          {
            <InventoryItem item={selectedItem} showActions={true} {...props}></InventoryItem>
          }
          <div className={"token-list" + (props.className ? " " + props.className : "")}>
            {
              market &&
              <div className={`token-entry token-entry-inventory token-entry-inventory-market`} key={0} index={0}>
                <div title="Open Sea Market">
                  <a className="button" target="_blank" rel="noreferrer" href={market}>
                    <img src={require("css/images/logo-opensea.png")} alt=""/>
                  </a>
                </div>
              </div>
            }
            {
              props.items.length > 0 &&
              props.items.filter(item => !item.data.hidden).map((item, index) => 
                <div className={`token-entry token-entry-inventory ${item === selectedItem ? 'token-entry-selected' : ''}`}
                  key={index} index={index} item={item} 
                  onClick={(e) => onItemClick(e, item)}>
                  <div style={item.style} title={item.label}></div>
                  {(item.count || 1) > 1 && <div className="token-entry-inventory-item-count">{item.count}</div>}
                  {item.isNFT && <div className="token-entry-inventory-indicator-nft">NFT</div>}
                </div>
              )
            }
          </div>
        </>
      </Container>
  );
}

export const InventoryItem = (props = {}) => {
  const doItemAction = (e, action) => {
    e.stopPropagation();

    if (!props.item.o || !action) return;
    
    props.item.o.onEvent(action.event).then(() => props.onItemUpdated && props.onItemUpdated(props.item));
  }

  return (
    <div className="token-entry token-entry-inventory-details">
      {
        !props.item &&
        <>
          <div className="title">Your Treasures</div>
          <div className="description">Choose your top pick and let the fun begin!</div>
        </>
      }
      {
        props.item &&
        <>
          {
            props.showActions &&
            <div className="actions">
              {
                props.item.o?.actions &&
                Object.keys(props.item.o.actions).map((key, index) => {
                  const action = props.item.o.actions[key];
                   return <div key={index} className="button" onClick={(e) => doItemAction(e, action)}>{action.label}</div>;
                })
              }
            </div>
          }
          <div className="title">{props.item.label}</div>
          <div className="description">
            {props.item.data?.description ? props.item.data?.description : `Just ${props.item.label}`}
          </div>
        </>
      }
    </div>
  )
}
