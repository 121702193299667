import { useState } from "react"
import { GameOption } from "./GameOption"
import { Badge, Container, Table } from "react-bootstrap"
import { iethContract } from "Landing"

export const GameOptionLeaderboard = (props = {}) => {
  const [players, setPlayers] = useState([])

  const onShowOption = (orderby) => {
    iethContract.api.call('get', `games/${props.world.loader.module}/players`)
      .then((res) => res.json())
      .then((data) => {
        Object.keys(data).forEach(key => {
          const item = data[key];
          item.inventory = item.inventory || {};
          item.poop = parseInt(item.data?.poop) || 0;

          item.online = props.world.players?.find(w => w?.toUpperCase() === item.wallet?.toUpperCase()) ? true : false;

          if (!item.inventory['id-citizen']) return true;

          // id-citizen
          item.image = props.world.loader.getObject('id-citizen')?.imageSrc;
          item.imageClip = props.world.loader.getObject('id-citizen')?.imageClip;
          item.imageStyle = {
            height: `${item.imageClip.height}px`,
            width: `${item.imageClip.width}px`,
            float: 'left',
            background: `url(${item.image}) -${item.imageClip.left}px -${item.imageClip.top}px`
          }         
        });

        orderby = orderby || 'xp';
        setPlayers(data.sort((a, b) => a.status?.name < b.status?.name ? 1 : -1).sort((a, b) => (a[orderby] || 0) < (b[orderby] || 0) ? 1 : -1));
      });
  }

  return (
    <GameOption 
      {...props}
      title={'Leaderboard'} 
      className={'leaderboard'}
      icon={require("../../../css/images/icons/users.leaderboard.png")}
      onShowOption={() => onShowOption()}
      component={Leaderboard}
      items={players}
      onOrderBy={(column) => onShowOption(column)}/>
  )
}

const Leaderboard = (props = {}) => {
  return (
      <Container fluid className="game-leaderboard">
        <>
          {
            props.items.length === 0 &&
            <>
              <div>No players in the leaderboard</div>
            </>
          }
          {
            props.items.length > 0 &&
            <Table striped bordered hover variant="dark" responsive size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th className='orderby' onClick={(e) => { props.onOrderBy && props.onOrderBy('name'); e.stopPropagation(); }}>Player</th>
                  <th className='orderby' onClick={(e) => { props.onOrderBy && props.onOrderBy('xp'); e.stopPropagation(); }}>XP</th>
                  <th>LVL</th>
                  <th className='orderby' onClick={(e) => { props.onOrderBy && props.onOrderBy('poop'); e.stopPropagation(); }}>Poop</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.items.map((item, index) => 
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td className="player-name">
                        {item.imageStyle && <div style={item.imageStyle} title={'Registered Citizen'}></div>}
                        {item.status?.name?.toUpperCase() === item.wallet?.toUpperCase() ? `${item.status?.name.substring(0, 12)}...` : item.status?.name || item.wallet?.substring(0, 10)} {item.online ? <Badge bg="success">online</Badge> : ''}
                      </td>
                      <td>{!item.xp ? '-' : item.xp}</td>
                      <td>{item.level || 1}</td>
                      <td>{item.poop || ''}</td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          }
        </>
      </Container>
  );
}