import "./LeaderboardComponent.css";
import React from "react";
import { iethContract } from "Landing";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "AppContext";
import { BigNumber } from "ethers";

class LeaderboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaders: [],
      levelleaders: [],
      stageleaders: [],
      roachesleaders: [],
      loggeduserglobal: {
        index: 0,
        data: {}
      },
      loggeduserlevel: {
        index: 0,
        data: {}
      },
      loggeduserstage: {
        index: 0,
        data: {}
      },
      loggeduserroaches: {
        index: 0,
        data: {}
      },
      count: 0,
      stage: null
    };
  }

  async componentDidMount() {
    // rebel level
    let rebelData = {};
    let levelleaders = {};

    await iethContract.api.call('get', `rebels`)      
      .then((res) => res.json())
      .then((data) => rebelData = data);

    await iethContract.api.call('get', `contracts/${process.env.REACT_APP_CONTRACT_RESISTANCE}/transfers`)      
      .then((res) => res.json())
      .then((data) => { 
        if ((data?.owners?.length || 0) === 0)
          return;

        let rebels = data.owners.filter((item) => {
          return item.id !== '16632171241560882840900462580621435406543332698332825844039081534165828502504' &&
              item.id !== '16632171241560882840900462580621435406543332698332825844039081533066316874028' &&
              item.id !== '16632171241560882840900462580621435406543332698332825844039081535265340129480'
        });

        const leaders = {};
        const filtered = [];
        rebels.slice().reverse().forEach(item => {
          if (!filtered.find(i => i.id === item.id)) {
            item.index = iethContract.tokenIdParse(BigNumber.from(item.id)).index;
            item.indexhex = item.index.toString(16).padStart(8, '0');
            item.image = 'https://invasioneth.art/resistance/' + item.indexhex + '.png';
            item.type = item.index <= 52 ? 'founder' : 'rebel';
            item.level = rebelData[item.id] && rebelData[item.id].level ? rebelData[item.id].level : item.type === 'rebel' ? 1 : 5;

            filtered.push(item);

            leaders[item.owner] = leaders[item.owner] || {
              wallet: item.owner,
              level: 0
            };
            leaders[item.owner].level += item.level;
          } 
        });

        levelleaders = Object.values(leaders);
        levelleaders = levelleaders.sort((a, b) => a.level < b.level ? 1 : -1);

        const loggeduserindex = levelleaders.findIndex(leader => leader.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase());
        const loggeduserdata = levelleaders[loggeduserindex];
        this.setState({loggeduserlevel:{index:loggeduserindex, data: loggeduserdata || {}}});

        levelleaders = levelleaders.slice(0, 15);
        
        if (loggeduserindex > 14)
          levelleaders.push(loggeduserdata);
      });
      
    // wallets
    iethContract.api.call('get', 'wallets?contents=true')
      .then((res) => res.json())
      .then((data) => {
        // level leaders nickname
        levelleaders.map(leader => {
          const w = data.find(wallet => wallet.wallet.trimEnd().toUpperCase() === leader.wallet.trimEnd().toUpperCase());
          leader.nickname = w ? w.nickname : null;
          return leader;
        });

        // global score leaders
        let leaders = data.sort((a, b) => (a.vouchers ? a.vouchers.length : 0) < (b.vouchers ? b.vouchers.length : 0) ? 1 : -1);
        const loggeduserindex = leaders.findIndex(wallet => wallet.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase());
        this.setState({loggeduserglobal:{index:loggeduserindex, data: data[loggeduserindex] || {}}});
        leaders = leaders.slice(0, 15);
        
        if (loggeduserindex > 14)
          leaders.push(data[loggeduserindex]);

        // TODO: clean this up
        // AppContext.api.stage = 'Wave 4';

        // stage score leaders
        let stageleaders = [];
        let stage = null;

        if (AppContext.api && AppContext.api.stage) {
          stage = AppContext.api.stage;

          stageleaders = data.sort((a, b) => (a.scores ? a.scores[stage] || 0 : 0) < (b.scores ? b.scores[stage] || 0 : 0) ? 1 : -1);
          const loggeduserindexstage = stageleaders.findIndex(wallet => wallet.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase());
          this.setState({loggeduserstage:{index:loggeduserindexstage, data: data[loggeduserindexstage] || {}}});
          stageleaders = stageleaders.slice(0, 15);
          
          if (loggeduserindexstage > 14)
            stageleaders.push(data[loggeduserindexstage]);
        }

        // roaches leaders
        let roachesleaders = data.sort((a, b) => a.score < b.score ? 1 : -1);
        roachesleaders = roachesleaders.sort((a, b) => ((b.roaches || 0) + (b.roaches2 || 0)) - ((a.roaches || 0) + (a.roaches2 || 0)));
        const loggeduserindexroaches = roachesleaders.findIndex(wallet => wallet.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase());
        this.setState({loggeduserroaches:{index:loggeduserindexroaches, data: data[loggeduserindexroaches] || {}}});
        roachesleaders = roachesleaders.slice(0, 15);
        
        if (loggeduserindexroaches > 14)
          roachesleaders.push(data[loggeduserindexroaches]);

        this.setState({
          leaders: leaders, 
          levelleaders: levelleaders, 
          stageleaders: stageleaders,
          roachesleaders: roachesleaders,
          count: data.length, 
          stage: stage});
      })
      .catch((err) => console.error(err));
  }

  render() {
    const openseaLink = 'https://opensea.io/';

    return (
      <div className="component-leaderboard">
        <h1>Leaderboard<small className="user-count">{this.state.count} Users</small></h1>
        <Tabs className="mb-3">
          {
            //this.state.stage != null &&
            // do not show extra stage leaderboard tab
            false &&
            <Tab eventKey="Stage" title={this.state.stage}>
            <Container fluid="xs">
                <Row key={-1} className="row-header">
                  <Col xs={1}>#</Col>
                  <Col xs={7}>Rebel</Col>
                  <Col xs={4}>Score</Col>
                </Row>
                <hr></hr>
              {
                this.state.stageleaders.map((item, index) => {
                  if (item.wallet === this.state.loggeduserstage.data.wallet) {
                    index = this.state.loggeduserstage.index;
                  }
                  const nickname = !item.nickname || item.nickname.trimEnd().toUpperCase() === item.wallet.trimEnd().toUpperCase() ? 'Unnamed (' + item.wallet.substring(0, 6) + '...' + item.wallet.slice(item.wallet.length - 4) + ')' : item.nickname;
                  const loggeduser = item.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase();
                  return  <Row key={index} className={"row-detail" + (loggeduser ? " component-leaderboard-logged-user" : "")}>
                            <Col xs={1}>{index + 1}</Col>
                            <Col xs={7}>
                              {item.showOSLink && <a title="Open Sea Profile" href={openseaLink + item.wallet} target="_blank" rel="noopener noreferrer">{nickname}</a>}
                              {!item.showOSLink && <>{nickname}</>}
                            </Col>
                            <Col xs={4}>{item.scores ? item.scores[this.state.stage] || 0 : 0}</Col>
                          </Row>
                  }
                )
              }
              </Container>
            </Tab>
          }
          {
            <Tab eventKey="Roaches" title="Roaches">
            <Container fluid="xs">
                <Row key={-1} className="row-header">
                  <Col xs={1}>#</Col>
                  <Col xs={7}>Rebel</Col>
                  <Col xs={4}>Roaches</Col>
                </Row>
                <hr></hr>
              {
                this.state.roachesleaders.map((item, index) => {
                  if (item.wallet === this.state.loggeduserroaches.data.wallet) {
                    index = this.state.loggeduserroaches.index;
                  }
                  const nickname = !item.nickname || item.nickname.trimEnd().toUpperCase() === item.wallet.trimEnd().toUpperCase() ? 'Unnamed (' + item.wallet.substring(0, 6) + '...' + item.wallet.slice(item.wallet.length - 4) + ')' : item.nickname;
                  const loggeduser = item.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase();
                  return  <Row key={index} className={"row-detail" + (loggeduser ? " component-leaderboard-logged-user" : "")}>
                            <Col xs={1}>{index + 1}</Col>
                            <Col xs={7}>
                              {item.showOSLink && <a title="Open Sea Profile" href={openseaLink + item.wallet} target="_blank" rel="noopener noreferrer">{nickname}</a>}
                              {!item.showOSLink && <>{nickname}</>}
                            </Col>
                            <Col xs={4}>{(item.roaches || 0) + (item.roaches2 || 0)}</Col>
                          </Row>
                  }
                )
              }
              </Container>
            </Tab>
          }
          <Tab eventKey="Global" title="Global">
          <Container fluid="xs">
              <Row key={-1} className="row-header">
                <Col xs={1}>#</Col>
                <Col xs={7}>Rebel</Col>
                <Col xs={4}>Captured</Col>
              </Row>
              <hr></hr>
            {
              this.state.leaders.map((item, index) => {
                if (item.wallet === this.state.loggeduserglobal.data.wallet) {
                  index = this.state.loggeduserglobal.index;
                }
                const nickname = !item.nickname || item.nickname.trimEnd().toUpperCase() === item.wallet.trimEnd().toUpperCase() ? 'Unnamed (' + item.wallet.substring(0, 6) + '...' + item.wallet.slice(item.wallet.length - 4) + ')' : item.nickname;
                const loggeduser = item.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase();
                return  <Row key={index} className={"row-detail" + (loggeduser ? " component-leaderboard-logged-user" : "")}>
                          <Col xs={1}>{index + 1}</Col>
                          <Col xs={7}>
                            {item.showOSLink && <a title="Open Sea Profile" href={openseaLink + item.wallet} target="_blank" rel="noopener noreferrer">{nickname}</a>}
                            {!item.showOSLink && <>{nickname}</>}
                          </Col>
                          <Col xs={4}>{item.vouchers?.length || 0}</Col>
                        </Row>
                }
              )
            }
            </Container>
          </Tab>
          <Tab eventKey="Level" title="Level">
          <Container fluid="xs">
              <Row key={-1} className="row-header">
                <Col xs={1}>#</Col>
                <Col xs={7}>Rebel</Col>
                <Col xs={4}>Level</Col>
              </Row>
              <hr></hr>
            {
              this.state.levelleaders.map((item, index) => {
                if (item.wallet === this.state.loggeduserlevel.data.wallet) {
                  index = this.state.loggeduserlevel.index;
                }
                const nickname = !item.nickname || item.nickname.trimEnd().toUpperCase() === item.wallet.trimEnd().toUpperCase() ? 'Unnamed (' + item.wallet.substring(0, 6) + '...' + item.wallet.slice(item.wallet.length - 4) + ')' : item.nickname;
                const loggeduser = item.wallet.trimEnd().toUpperCase() === AppContext.userAddress.trimEnd().toUpperCase();
                return  <Row key={index} className={"row-detail" + (loggeduser ? " component-leaderboard-logged-user" : "")}>
                          <Col xs={1}>{index + 1}</Col>
                          <Col xs={7}>
                            {item.showOSLink && <a title="Open Sea Profile" href={openseaLink + item.wallet} target="_blank" rel="noopener noreferrer">{nickname}</a>}
                            {!item.showOSLink && <>{nickname}</>}
                          </Col>
                          <Col xs={4}>{item.level}</Col>
                        </Row>
                }
              )
            }
            </Container>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default LeaderboardComponent;