import "./BattleEthTokenList.css";

import React from "react";
import Img from "../Components/Img";
import {Container, Col, Row, Spinner} from 'react-bootstrap';
import { InfinitySpin } from "react-loader-spinner";
import { PiShieldCheckeredFill, PiSwordFill } from "react-icons/pi";
import { battleEthService } from "./BattleEthService";

class BattleEthTokenList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const tokens = this.props.tokens;

    return (
        <Container fluid>
          <Col>
              <Row>
                { this.props.title &&
                  <h4 className="token-list-title">
                    {this.props.title}
                  </h4>
                }
                {
                  this.props.loading &&
                  <div><InfinitySpin width='200' color="#ffffff"/></div>
                }
                {
                  !this.props.loading &&
                  <>
                  {
                    tokens &&
                    tokens.length > 0 &&
                    <ul className={"token-list" + (this.props.className ? " " + this.props.className : "")}>
                    {
                      tokens.map((token, index) => token && 
                      <TokenEntry key={index} className={(this.props.tokenClassName || "")} index={index} token={token} onTokenClick={(token) => this.props.onTokenClick(token)}></TokenEntry>)
                    }
                    </ul>
                  }
                  {
                    ( !tokens ||
                    tokens.length === 0 ) &&
                    <div className="token-list-empty">
                      {
                        this.props.noDataLegend && 
                        this.props.noDataLegend
                      }
                      {
                        !this.props.noDataLegend &&
                        <span>No {this.props.title} available</span>
                      }
                    </div>
                  }
                  </>
                }
              </Row>
          </Col>
        </Container>
    );
  }
}

export class TokenEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const token = this.props.token;

    if (!token || token.isLoading) {
      return(
        <li className={"token-entry"}>
          <Spinner className="spinner" animation="border"/>
        </li>
      )
    }

    const image = token.metadata?.external_url ? (token.metadata?.external_url.indexOf('.gif') < 0 ? token.metadata?.external_url + ".png" : token.metadata?.external_url) : token.metadata?.image;
    const defense = token.metadata?.attributes?.filter((attr) => attr.trait_type === 'Defense')[0]?.value || 0;
    const attack = token.metadata?.attributes?.filter((attr) => attr.trait_type === 'Attack')[0]?.value || 0;
  
    const priceNative = token.class === 'marketItem' ? battleEthService.toPrice(token.market.priceNative) : null;
    const priceRBLB = token.class === 'marketItem' ? battleEthService.toPrice(token.market.priceRBLB) : null;

    return (
      <li className={"token-entry " + (this.props.className || "") + ` token-entry-${token.class}`} 
          key={this.props.index} onClick={() => this.props.onTokenClick(token)}>
        <div className="image">
          <Img 
            src={image} 
            errorImage={require("../css/images/resistance-fan.png")}
            alt="" 
            title={token.metadata?.name}></Img>
        </div>
        <div className="card-title">
          {token.metadata?.name}<br></br>
          {priceNative && <>{priceNative} POL<br></br></>}
          {priceRBLB && <>{priceRBLB} RBLB</>}
        </div>
        {
          /*
          token.class == 'champion' &&
          <div className="card-stats">
            <div className="attack">{<PiSwordFill/>} <small>{attack}</small></div>
            <div className="defense">{<PiShieldCheckeredFill/>} <small>{defense}</small></div>
          </div>
          */
        }
        {
          token.class === 'champion' &&
          <>
            <div className="attack">{<PiSwordFill/>} <small>{attack}</small></div>
            <div className="defense">{<PiShieldCheckeredFill/>} <small>{defense}</small></div>
          </>
        }
      </li>
    );
  }
}

export default BattleEthTokenList;