import "./DashboardComponent.css";
import React from "react";
import Img from "../../Components/Img";
import {AppContext} from "AppContext";
import {Container, Col, Row, Badge} from 'react-bootstrap';
import {ArrowRepeat, CaretDownFill, CaretUpFill, Cart} from 'react-bootstrap-icons'

class TokenList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: props.disableCollapseMode ? false : props.isCollapsed,
      disableCollapseMode: props.disableCollapseMode
    };
  }

  componentDidMount() {
  }

  getScore = () => { 
    let score = 0;

    return score;
  }

  getPoints = () => { 
    let points = 0;

    AppContext.tokens.forEach(element => {
      points += element.points;
    });

    return points;
  }

  onClickCart = () => {
    if (this.props.cartUrl) {
      window.open(this.props.cartUrl, '_blank').focus();
    }
  }

  onClickReload = () => {
    if (this.props.onReload) {
      this.props.onReload();
    }
  }

  render() {
    return (
        <Container fluid>
          <Col>
              <Row>
                { this.props.title &&
                  <h4 className="token-list-title">
                    {
                      !this.state.disableCollapseMode &&
                      <div className="token-list-collapse" onClick={() => this.setState({isCollapsed: !this.state.isCollapsed})}>
                        { this.state.isCollapsed ? <CaretDownFill></CaretDownFill> : <CaretUpFill></CaretUpFill> }
                      </div>
                    }
                    {this.props.title} {this.props.tokens && <small>[{this.props.tokens.length}]</small>}
                    <div className="token-list-buttons">
                      <div className="token-list-button" title="Reload" onClick={() => this.onClickReload()}><ArrowRepeat/></div>
                      <div className="token-list-button" title={"Get " + this.props.title} onClick={() => this.onClickCart()}><Cart/></div>
                    </div>
                  </h4>
                }
                {
                  !this.state.isCollapsed &&
                  <>
                    {
                      this.props.tokens &&
                      this.props.tokens.length > 0 &&
                      <ul className={"token-list" + (this.props.className ? " " + this.props.className : "")}>
                      {
                        this.props.tokens.map((token, index) => <TokenEntry key={index} index={index} token={token} onTokenClick={(token) => this.props.onTokenClick(token)}></TokenEntry>)
                      }
                      </ul>
                    }
                    {
                      !this.props.tokens &&
                      <p>Loading...</p>
                    }
                    {
                      this.props.tokens && 
                      this.props.tokens.length === 0 &&
                      <p>No {this.props.title} available</p>
                    }
                  </>
                }
              </Row>
          </Col>
        </Container>
    );
  }
}

class TokenEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const image = this.props.token.metadata.external_url ? (this.props.token.metadata.external_url.indexOf('.gif') < 0 ? this.props.token.metadata.external_url + ".png" : this.props.token.metadata.external_url) : this.props.token.metadata.image;
    return (
      <li className={`token-entry ${this.props.token.className}` /*+ (props.token === this.state.selectedToken ? "token-selected" : "")*/} 
          key={this.props.index} onClick={() => this.props.onTokenClick(this.props.token)}>
        <Img 
          src={image} 
          errorImage={require("../../css/images/resistance-fan.png")}
          alt="" 
          title={this.props.token.metadata.name}></Img>
        {
          this.props.token.metadata.rarity > 0 &&
          <div className="rarity" title={"Rarity #" + this.props.token.metadata.rarity}>#{this.props.token.metadata.rarity}</div>
        }
        {
          this.props.token.metadata.level > 0 &&
          <div className="level" title={"Level " + this.props.token.metadata.level}>{this.props.token.metadata.level}</div>
        }
        {
          this.props.token.metadata.level > 0 &&
          AppContext.levelup > 0 &&
          <div className="levelup" title={"Level Up Rebel!"}></div>
        }
        {
          this.props.token.balance > 1 &&
          <Badge className="balance" pill bg="primary">{this.props.token.balance}</Badge>
        }
      </li>
    );
  }
}

export default TokenList;