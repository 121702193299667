import { iethContract } from "Landing";
import "./Paycheck.css";
import { AppContext } from "AppContext";
import { useState } from "react";

export const Paycheck = (props = {}) => {
  const [isCollecting, setIsCollecting] = useState(false)

  if (!props.show) {
    return null
  }

  const rebelCount = AppContext.rebels?.length || 0;
  const assetCount = AppContext.assets?.length || 0;
  const earthlingCount = AppContext.earthlings?.length || 0;

  const rebelPoints = Math.round(rebelCount * (props.days / 7));
  const assetPoints = Math.round(assetCount * (props.days / 14));
  const earthlingsPoints = Math.round(earthlingCount * (props.days / 3.5));

  const points = rebelPoints + assetPoints + earthlingsPoints;               

  if (rebelCount + assetCount + earthlingCount === 0) {
    return null;
  }

  const onCollect = (points) => {
    setIsCollecting(true);

    // call api
    // signature + score + rewardNumber
    iethContract.signIn()
        .then((results) => {
            iethContract.api.call('post', `wallets/${iethContract.userAddress()}/paycheck/collect`, {
                signature: results.signature,
                nonce: results.nonce,
                score: points
            })
            .then((res) => res.json())
            .then(async (data) => {
              props.onClose && props.onClose();
              iethContract.updateStatus();
            });
        })
        .catch((err) => {
          setIsCollecting(false);
        });
  }

  return (
    <div className="paycheck">
      <div className="paycheck-container">
        <div className="close-window" title="Close" onClick={() => props.onClose && props.onClose()}>X</div>
        <div className="paycheck-watermark">PAYCHECK</div>
        <div className="paycheck-content">
          <div>From: The Resistance</div>
          <div>To: {AppContext.walletData?.nickname}</div>
          <div>Days: {props.days}</div>
          <small className="text-end">
            <div>Rebels: {rebelCount} = {rebelPoints} coins</div>
            <div>Assets: {assetCount} = {assetPoints} coins</div>
            <div>Earthlings: {earthlingCount} = {earthlingsPoints} coins</div>
          </small>
          <hr></hr>
          <div className="points">{points} <small>coins</small></div>
          {
            !isCollecting &&
            <div className="collect" onClick={() => onCollect(points)}>Claim!</div>
          }
        </div>
      </div>
    </div>
  );
}