export class DialogManager {
    constructor(props) {
        this.dialogs = {};
        this.onChange = props.onChange || (() => {});
    }

    add = (type, dialog) => {
        this.dialogs[dialog.className] = this.dialogs[dialog.className] || [];
        dialog.type = type || DialogComponent;
        dialog.index = this.dialogs[dialog.className].length;
        this.dialogs[dialog.className].push(dialog);

        dialog.show = true;
        dialog.onClose = () => {
            dialog.show = false;
            dialog.onComplete && dialog.onComplete();
            
            // remove last showed dialog
            this.dialogs[dialog.className].shift();
            
            this.onChange();
        }

        if (dialog.timeout) {
            setTimeout(() => { dialog.onClose() }, dialog.timeout);
        }

        this.onChange();
    }

    close = () => {
        this.visible().forEach(dialog => dialog.onClose());
        this.onChange();
    }

    visible = () => {
        const filtered = [];
        for (const key of Object.keys(this.dialogs)) {
            const dialog = this.dialogs[key]?.find(dialog => dialog.show);

            if (dialog) filtered.push(dialog);
        }

        return filtered;
    }

    render = () => {
        return <>{this.visible().map(dialog => <dialog.type key={dialog.className + dialog.index} {...dialog}/>)}</>;
    }
}

export const DialogComponent = (props = {}) => {
    if (!props.show) return

    return (
        <div className={"world-dialog-container " + props.className || ""} 
            style={{ left: props.x + 'px', top: props.y + 'px' }} 
            onClick={() => {props.onClose && props.onClose()}}>
            {/*<div className="character-img"><img src={this.state.dialog.image} alt=""></img></div>*/}
            <div className="character-message" dangerouslySetInnerHTML={{ __html: props.message }}></div>              
        </div>
    )  
}

export const DialogReactionComponent = (props = {}) => {
    if (!props.show) return

    return (
        <div className={"world-reaction-container " + props.className || ""} 
            style={{ left: props.rect.left + 'px', top: (props.rect.top - (props.rect.height * 0.8)) + 'px', width: props.rect.width + 'px', height: props.rect.height + 'px'  }} 
            onClick={() => {props.onClose()}}>
            <div className="reaction-img" style={{width: props.imageSize + 'px', height: props.imageSize + 'px'}}>
                <img src={props.image} style={{ width: props.imageWidth + 'px', height: props.imageHeight + 'px', objectPosition: props.imagePosition }} alt=""></img>
            </div>
        </div>
    )
}

  