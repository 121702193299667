import React from "react";
import {AppContext, rankEnum} from "AppContext";
import {Container} from 'react-bootstrap';

class RebelRank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
        <Container fluid>
          <div className="rebel-rank">
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.wannabe ? " current-rank" : "")} title="Wannabe">
              <div className="title">Wannabe</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.wannabe ? " has-rank" : "")}></div>
            </div>
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.rebel ? " current-rank" : "")}  title="1-3 Rebels">
              <div className="title">Rebel</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.rebel ? " has-rank" : "")}></div>
            </div>
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.squad ? " current-rank" : "")}  title={"4-8 Rebels\r\nFounding Squad"}>
              <div className="title">Squad</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.squad ? " has-rank" : "")}></div>
            </div>
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.platoon ? " current-rank" : "")}  title="9-13 Rebels">
              <div className="title">Platoon</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.platoon ? " has-rank" : "")}></div>
            </div>
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.brigade ? " current-rank" : "")}  title="14-19 Rebels">
              <div className="title">Brigade</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.brigade ? " has-rank" : "")}></div>
            </div>
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.corps ? " current-rank" : "")}  title="20+ Rebels">
              <div className="title">Corps</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.corps ? " has-rank" : "")}></div>
            </div>
            <div className={"rebel-rank-status" + (AppContext.rank === rankEnum.army ? " current-rank" : "")}  title="30+ Rebels">
              <div className="title">Army</div>
              <div className={"fill" + (AppContext.rank >= rankEnum.army ? " has-rank" : "")}></div>
            </div>
          </div>
        </Container>
    );
  }
}

export default RebelRank;