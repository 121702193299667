import { ActionOption } from "./ActionOption";

export class Action {
    constructor(id, props) {
        this.id = id;
        this.props = props || {};
        
        this.key = this.props.key;
        this.disabled = this.props.disabled;
        this.loader = this.props.loader;

        switch (this.props.type) {
            case 'ActionOption':
                this.executor = (data) => !this.disabled && ActionOption(this, data); 
                break;
            default:
                this.executor = (data) => { console.log(this.id, 'no executor') };
                break;
        }
    }

    execute = (data) => this.executor(data)
}