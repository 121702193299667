import "./TokenDataModal.css";
import React from "react";
import Img from "../../Components/Img";
import {Container, Col, Row, Modal} from 'react-bootstrap';
import RedeemModalComponent from "./RedeemModal";
import { AppContext } from "AppContext";
import { iethContract } from "Landing";

class TokenDataModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redeemModalShow: false,
      selectedToken: props.selectedToken
    };
  }

  onClickLevelUp(e) {
    e.stopPropagation();

    const tokenType =   this.props.selectedToken.tokenType === 'rebel' || this.props.selectedToken.tokenType === 'founder' ? 'rebels' : '';

    iethContract.signIn()
        .then((results) => {
            iethContract.api.call('post', `${tokenType}/${this.props.selectedToken.id}/levelup`, {
                address: iethContract.userAddress(),
                signature: results.signature,
                nonce: results.nonce,
                levelBase: this.props.selectedToken.metadata.level
            })
            .then((res) => res.json())
            .then(async (data) => {
              await iethContract.updateStatus();

              // update token level
              if (this.props.selectedToken.metadata) {
                this.props.selectedToken.metadata.level = data.level;

                const attrLevel = this.props.selectedToken.metadata.attributes.find(attr => attr.trait_type === 'Level');
                if (attrLevel) {
                  attrLevel.value = data.level;
                }
              }

              this.forceUpdate();
            });
        });
  }

  render() {
    if (!this.props.show || !this.props.selectedToken)
      return null;

    // console.log(this.props.selectedToken);

    return (
        <Modal
          show={this.props.show}
          onHide={this.props.onClose}
          dialogClassName="token-data-modal"
          keyboard={false}
        >
          {/*
          <Modal.Header closeButton>
            <Modal.Title>{this.props.selectedToken.metadata.name}</Modal.Title>
          </Modal.Header>
          */
          }
          <Modal.Body onClick={() => this.props.onClose()}>
              <Container fluid className="token-data">
                  <Row>
                    <Col md={6}>
                      <Img 
                        //src={this.props.selectedToken.metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/")} 
                        src={this.props.selectedToken.metadata.external_url ? (this.props.selectedToken.metadata.external_url.indexOf('.gif') < 0 ? this.props.selectedToken.metadata.external_url + ".png" : this.props.selectedToken.metadata.external_url) : this.props.selectedToken.metadata.image}
                        classNameUnloaded="loading-rebel"></Img>
                    </Col>
                    <Col md={6} className="token-details">
                      <h2>{this.props.selectedToken.metadata.name}</h2>
                      {   this.props.selectedToken.metadata.attributes && 
                          <div className="token-attrs">
                              {this.props.selectedToken.metadata.attributes.map(attr => 
                                <div key={attr.trait_type} className={`token-attr token-attr-${attr.trait_type}`}>
                                  <div className="attr-title">{attr.trait_type}</div>
                                  <div className="attr-value">
                                    {attr.value}
                                    {
                                      // level up
                                      attr.trait_type === "Level" && AppContext.levelup > 0 && 
                                      <div className="levelup" title="Level Up Rebel!" 
                                        onClick={(e) => this.onClickLevelUp(e)}></div>
                                    }
                                  </div>
                                </div>
                              )}
                          </div>
                      }
                      {
                        this.props.selectedToken.voucherBalance > 0 &&
                        this.props.selectedToken.tokenType !== 'rebel' &&
                        <>
                          <div className="button button-redeem" onClick={() => this.setState({redeemModalShow: true})}>Redeem x{this.props.selectedToken.voucherBalance}</div>
                          <RedeemModalComponent 
                            show={this.state.redeemModalShow} 
                            token={this.props.selectedToken}
                            onClose={(close) => {
                              this.setState({redeemModalShow: false});
                              
                              if (close) {
                                this.props.onClose(close);
                              }
                            }}
                          />
                        </>
                      }
                    </Col>
                  </Row>
            </Container>
          </Modal.Body>
        </Modal>
    );
  }
}

export default TokenDataModalComponent;