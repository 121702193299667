import "./DashboardComponent.css";
import React from "react";
import TokenList from "./TokenList";
import TokenDataModalComponent from "./TokenDataModal";
import { iethContract } from "Landing";
import { AppContext, eventUpdateEnum } from "AppContext";
import RebelRank from "./RebelRank";

class DashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedToken: null,
      selectedTokenModalShow: false,
      redeemModalShow: false,
      rebels: [],
      pixels: [],
      earthlings: [],
      assets: [],
      invaders: [],
      vouchers: []
    };
  }

  componentDidMount() {
    this.setState({rebels: AppContext.rebels});
    this.setState({earthlings: AppContext.earthlings});
    this.setState({assets: AppContext.assets});
    this.setState({invaders: AppContext.invaders});
    this.setState({vouchers: AppContext.vouchers});
    this.setState({roaches: AppContext.roaches});
    this.setState({pixels: AppContext.pixels?.sort((a,b) => a.id - b.id)});

    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.champions) {
        return;
      }

      if (event === eventUpdateEnum.vouchers) {
        this.setState({vouchers: AppContext.vouchers});
        return;
      }
  
      if (event === eventUpdateEnum.pixels) {
        this.setState({pixels: []});
        this.setState({pixels: AppContext.pixels?.sort((a,b) => a.id - b.id)});
        return;
      }

      this.setState({rebels: AppContext.rebels});
      this.setState({earthlings: AppContext.earthlings});
      this.setState({assets: AppContext.assets});
      this.setState({invaders: AppContext.invaders});
      this.setState({roaches: AppContext.roaches});
    })
  }

  render() {
    return (
      <>
        <div>
            <h1>Dashboard <small className="dashboard-nickname">{iethContract.nickname()}</small></h1>
            { this.state.vouchers != null &&
              this.state.vouchers.length > 0 &&
              <TokenList title="" 
                className="token-list-sm"
                disableCollapseMode={true}
                tokens={this.state.vouchers} 
                onTokenClick={(token) => this.onTokenClick(token)}
                cartUrl=""
                onReload={() => iethContract.loadRebels().then((tokens) => this.setState({rebels:tokens, vouchers:AppContext.vouchers}))}
              />
            }
            <RebelRank></RebelRank>
            <TokenList title="Rebels" 
              tokens={this.state.rebels} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/invasion-eth-resistance"
              onReload={() => iethContract.loadRebels().then((tokens) => this.setState({rebels:tokens, vouchers:AppContext.vouchers}))}
            />
            <TokenList title="Pixel Rebels" 
              className="token-list-md"
              isCollapsed={true}
              tokens={this.state.pixels} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/pixel-rebels"
              onReload={() => iethContract.loadPixelRebels()}
            />
            <TokenList title="Captured Invaders" 
              className="token-list-md"
              isCollapsed={true}
              tokens={this.state.invaders} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/invasion-eth"
              onReload={() => iethContract.loadInvaders().then((tokens) => this.setState({invaders:tokens}))}
              />
            <TokenList title="Captured Roaches" 
              className="token-list-md"
              isCollapsed={true}
              tokens={this.state.roaches} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/invasion-eth-roaches"
              onReload={() => iethContract.loadRoaches().then((tokens) => this.setState({roaches:tokens}))}
              />
            <TokenList title="Earthlings" 
              className="token-list-md"
              isCollapsed={true}
              tokens={this.state.earthlings} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/scared-earthlings"
              onReload={() => iethContract.loadEarthlings().then((tokens) => this.setState({earthlings:tokens}))}
            />
            <TokenList title="Pets" 
              className="token-list-md"
              isCollapsed={true}
              tokens={this.state.assets != null ? this.state.assets.filter((token) => token.tokenType === 'Pet') : []} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/invasion-eth-assets"
              onReload={() => iethContract.loadAssets().then((tokens) => this.setState({assets:tokens}))}
            />
            <TokenList title="Gadgets" 
              className="token-list-md"
              isCollapsed={true}
              tokens={this.state.assets != null ? this.state.assets.filter((token) => token.tokenType === 'Gadget') : []} 
              onTokenClick={(token) => this.onTokenClick(token)}
              cartUrl="https://opensea.io/collection/invasion-eth-assets"
              onReload={() => iethContract.loadAssets().then((tokens) => this.setState({assets:tokens}))}
            />
        </div>
        <TokenDataModalComponent 
          show={this.state.selectedTokenModalShow} 
          onClose={(reload) => {
            this.setState({selectedTokenModalShow: false});
            
            if (reload) {
              iethContract.loadRebels().then((tokens) => this.setState({rebels:tokens}));
              iethContract.loadAssets().then((tokens) => this.setState({assets:tokens}));
              iethContract.loadInvaders().then((tokens) => this.setState({invaders:tokens}));
            }
          }}
          selectedToken={this.state.selectedToken}/>
      </>
    );
  }

  onTokenClick = (token) => {
    if (token.onClick) {
      token.onClick();
      return;
    }

    this.setState({selectedToken: token, selectedTokenModalShow: true});
  }
}

export default DashboardComponent;