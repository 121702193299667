import "./BaseApp.css";
import React from "react";
import { InfinitySpin } from  'react-loader-spinner'
import { AppContext } from "AppContext";
import MetaMaskAuth from "connect/metamask-auth.module";

class BaseApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: <></>,
      render: <></>,
      className: ''
    };
  }

  async componentDidMount() {
  }

  render() {
    return (
      <div className={"base-app " + this.state.className}>
        {this.state.title}
        <div className="close-terminal" title="Close" onClick={() => this.props.onAppClose()}>X</div>
          {
            this.state.loading &&
            <div className="text-center">
              <InfinitySpin width='190'color="#ffffff"/>                  
              <div>Loading</div>
            </div>
          }
          { 
            !this.state.loading &&
            this.state.render 
          }
          {
            !AppContext.userAddress &&
            <div className="text-center">
              <MetaMaskAuth onAddressChanged={address => {}}/>
            </div>
          }
      </div>
    );
  }
}

export default BaseApp;