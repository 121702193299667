import "./ResistanceCampApp.css";
import BaseWorldApp from "./rebellion/ui/BaseWorldApp";
import React, { useState } from "react";
import { AppContext } from "AppContext";
import { iethContract } from "Landing";
import { GameOptionInventory } from "./rebellion/ui/GameOptionInventory";
import { GameOptionMissions } from "./rebellion/ui/GameOptionMissions";
import { GameOptionMyCamp } from "./rebellion/ui/GameOptionMyCamp";
import { GameSelectorPlayerRebel } from "./rebellion/ui/GameSelectorPlayerRebel";

const ResistanceCampApp = (props = {}) =>  {
  const [world, setWorld] = useState(null)

  return(
    <div className="base-app">
      <div className="close-terminal" title="Close" onClick={() => props.onAppClose()}>X</div>
      <BaseWorldApp 
        game="resistance-camp" 
        user={{
          wallet: iethContract.userAddress(),  
          nickname: AppContext.walletData ? AppContext.walletData.nickname : 'Guest'
        }}
        {...props}
        profile={<GameSelectorPlayerRebel world={world} type={'rebels'}/>}
        options={
          <>
            <GameOptionInventory world={world}/>
            <GameOptionMissions world={world}/>
            <GameOptionMyCamp world={world} isHidden={true}/>
          </>
        }
        chat={true}
        onWorldInitialized={(world) => setWorld(world)}>            
      </BaseWorldApp>
    </div>
  )
}

export default ResistanceCampApp