import { EventManager } from "../services/EventManager";

export class Cutscence {
    constructor(id, props) {
        this.id = id;
        this.events = props.events || {};
    }

    run(data) {
        return EventManager.dispatch(this.events);
    }
}