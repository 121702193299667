import { iethContract } from "Landing";
import Gauge from "./Gauge";

const { AppContext, eventUpdateEnum } = require("AppContext");
const { useState, useEffect } = require("react");

export const RBLBGauges = (props = {}) => {
  const [score, setScore] = useState(0);
  const [bucks, setBucks] = useState(0);
  const [contractsLoaded, setContractsLoaded] = useState(false);

  useEffect(() => {
    setScore(AppContext.score);
    setBucks(AppContext.bucks);
    setContractsLoaded(AppContext.contractsLoaded);
    
    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.status) {
        setScore(AppContext.score);
        setBucks(AppContext.bucks);
        setContractsLoaded(AppContext.contractsLoaded);
      }
    });
  }, []);


  if (!contractsLoaded) {
    return null;
  }

  return (
    <div className={`gaugebar ${props.position || 'gaugebar-top-right'}`}>
      <Gauge icon={require("./../css/images/icons/coins.png")} value={score} title="Coins"></Gauge>
      <Gauge icon={require("./../css/images/icons/rblb.png")} 
          value={(bucks !== -1 ? bucks : 'Err!')} 
            title={bucks !== -1 ? 'Rebel Bucks' : 
              `Network Error (change to ${iethContract.network().name} and click to refresh)`}>
      </Gauge>
    </div>
  );
}