import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap"
import { getUIItems, InventoryItem } from "./GameOptionInventory";
import { EventManager } from "../services/EventManager";
import { AppContext, eventUpdateEnum } from "AppContext";

export const GameMarket = (props = {}) => {
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    EventManager.bind('keys.escape', (event) => {
        close();
        return Promise.resolve();
    });    
  }, []);  

  useEffect(() => {
    if (!props.world || !props.market?.objects) return;

    setItems(getUIItems(props.world, props.market?.objects));

    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.gameItems) {
        setItems(getUIItems(props.world, props.market?.objects));
      }
    });

    props.market.updateNFTPrices();
  }, [props.world, props.market])

  const close = () => {
    setSelectedItem(null);
    props.onClose && props.onClose();
  }

  if (!props.visible)
    return

  const onMarketBuy = (e, item) => {
    e.stopPropagation();
    
    props.market.buy(item, item.buyall ? item.player : 1);
  }

  const onMarketSell = (e, item) => {
    e.stopPropagation();
    
    props.market.sell(item, 1);
  }

  const onMarketSellNative = (e, item) => {
    e.stopPropagation();

    if (!props.world?.nftService?.mintAssetNative) return;

    setWorldStatus('<div>Minting</div>Follow the instructions in your wallet');

    props.world.nftService.mintAssetNative(item, (status) => setWorldStatus(`<div>Minting</div><div>Please wait a few minutes - <a href="${process.env.REACT_APP_NETWORK_SCAN + 'tx/' + status.hash}" target="_blank" rel="noopener noreferrer">Transaction Hash</a></div>`))
      .then((status) => setWorldStatus(`<div>Item Minted!</div>Refresh your inventory`, 10000, 'success'))
      .catch((err) => setWorldStatus('<div>Minting</div>Transaction rejected', 5000, 'danger'));
  }

  const onMarketSellRBLB = (e, item) => {
    e.stopPropagation();

    if (!props.world?.nftService.mintAssetRBLB) return;

    setWorldStatus('<div>Minting</div>Follow the instructions in your wallet');

    props.world.nftService.mintAssetRBLB(item, (status) => setWorldStatus(`<div>Minting</div><div>Please wait a few minutes - <a href="${process.env.REACT_APP_NETWORK_SCAN + 'tx/' + status.hash}" target="_blank" rel="noopener noreferrer">Transaction Hash</a></div>`))
      .then((status) => setWorldStatus(`<div>Item Minted!</div>Refresh your inventory`, 10000, 'success'))
      .catch((err) => setWorldStatus('<div>Minting</div>Transaction rejected', 5000, 'danger'));
  }

  const setWorldStatus = (status, timeout, bgcolor) => {
    props.world.loader.setWorldMessage({
      type: 'status',
      message: status,
      timeout: timeout,
      bgcolor: bgcolor
    })
  }

  const onItemClick = (e, item) => {
    e.stopPropagation();

    setSelectedItem(item);
  }

  return (
    <div className="world-selector" onClick={() => close()}>
      <Container fluid className="game-market">
        <div className="game-market-header">
          <div className="game-market-coins">$ {AppContext.score || 0}</div>
          <h3>{props.market.name}</h3>
          <div>{props.market.description}</div>
          {
            <InventoryItem item={selectedItem} {...props}></InventoryItem>
          }
        </div>
        <div className="game-market-details">
          {
            <div className={"token-list" + (props.className ? " " + props.className : "")}>
              {
                items.map((item, index) => {
                  // add player stock
                  item.player = props.world.player.items[item.id] ? props.world.player.items[item.id].count || 0 : 0;
                  const buyprice = item.buy * (item.buyall ? item.player : 1);
                  const sellprice = item.sell;

                  return(
                    <div key={index} className="token-entry-market" onClick={(e) => e.stopPropagation()}>
                      <div className={`token-entry token-entry-inventory ${item === selectedItem ? 'token-entry-selected' : ''} ${(item.isNFT ? 'token-entry-inventory-nft' : '')}`}
                        key={index} index={index} item={item} 
                        onClick={(e) => onItemClick(e, item)}>
                        <div style={item.style} title={item.label}></div>
                        {item.supply >= 0 && <div className="token-entry-inventory-item-count" title="Market Supply">{item.supply}</div>}
                        {item.player > 0 && <div className="token-entry-inventory-player-count" title="Your Supply">{item.player}</div>}
                        {item.isNFT && <div className="token-entry-inventory-indicator-nft">NFT</div>}
                      </div>
                      <div className="token-entry-inventory-item-label">{item.label}</div>
                      <div className="token-entry-market-buttons">
                        {
                          item.isNFT &&
                          <>
                            <Button onClick={(e) => onMarketSellNative(e, item)} disabled={!item.priceNativeString || !item.supply}>
                              {item.supply > 0 && item.priceNativeString && <>{item.selllegend || 'Buy'} (<small>POL</small>)<div>{item.priceNativeString}</div></>}                              
                            </Button>
                            <Button onClick={(e) => onMarketSellRBLB(e, item)} disabled={!item.priceRBLBString || !item.supply}>
                              {item.supply > 0 && item.priceRBLBString && <>{item.selllegend || 'Buy'} (<small>RBLB</small>)<div>{item.priceRBLBString}</div></>}
                            </Button>
                          </>
                        }
                        {
                          !item.isNFT &&
                          <>
                            <Button onClick={(e) => onMarketBuy(e, item)} disabled={!item.player || !item.buy}>
                              {item.buy > 0 && <>{item.buylegend || 'Sell'}<div>$ {buyprice}</div></>}
                            </Button>
                            <Button onClick={(e) => onMarketSell(e, item)} disabled={!item.supply || !item.sell}>
                              {item.sell > 0 && item.sell < (AppContext.score || 0) && <>{item.selllegend || 'Buy'}<div>$ {sellprice}</div></>}
                            </Button>
                          </>
                        }
                      </div>
                    </div>
                  )
                }
                )
              }
            </div>
          }
        </div>
      </Container>
    </div>
  )
}