import { MissionStates } from "../core/Mission";
import { Utils } from "../Utils";

export const EventManager = {
    dispatch: (events, context) => {
        return new Promise((resolve, reject) => {
            const dispatchEvents = [];

            if (!events) {
                // no events to dispatch
                resolve();
            }

            if (!Array.isArray(events)) {
                dispatchEvents.push(events);
            } else {
                dispatchEvents.push(...events);
            }
    
            const runEvent = (eventList, context) => {
                const e = EventManager.translate(eventList.shift(), context);
    
                if (!e || !EventManager.callbacks[e.code]) {
                    resolve();
                    return;
                }

                // set context
                e.context = context;
                    
                // repeat event
                e.repeat = e.repeat || 1;

                if (e.repeat > 1) {
                    e.repeat--;
                    eventList.unshift(e);
                }
    
                // execute callbacks
                // all callbacks must return a promise
                const callbackResults = [];
                EventManager.callbacks[e.code].forEach(fcn => EventManager.checkCondition(e) ? callbackResults.push(fcn(e)) : '');
    
                // wait for all callbacks to end and keep processing events
                // or stop processing in case of error
                Promise.all(callbackResults)
                    .then((data) => {
                        const result = data && data[0] ? data[0] : null;
                        data && data[0] && data[0].stop ? resolve() : runEvent(eventList, {...context, ...result});
                    })
                    .catch((err) => { console.log(err); resolve(); });
            }

            // add map id to context
            context = context || {};
            context.map = EventManager.world?.map.id;
            context.origin = EventManager.world?.map.origin;

            // add player information to context
            if (EventManager.world?.player) {
                context['player-name'] = EventManager.world.player.name || 'Rebel';
                context['player-wallet'] = EventManager.world.player.wallet;
            }

            runEvent(dispatchEvents, context);
        });
    },
    bind: (eventCode, callbackFcn) => {
        EventManager.callbacks[eventCode] = EventManager.callbacks[eventCode] || [];
        EventManager.callbacks[eventCode].push(callbackFcn);
    },
    clear: () => EventManager.callbacks = [],
    translate: (event, context) => {
        context = context || {};

        if (!event || typeof event !== 'string') {
            // not a string
            return event;
        }

        // replace context variables in event
        for (const v of Object.keys(context)) {
            event = event.replaceAll(`$\{${v}\}`, context[v]);
        }

        // generate object from format:
        // <code>:<id>:<repeat>|data;data;data
        const mainParts = event.split('|');
        const codePart = mainParts.length > 0 ? mainParts[0].split(':') : [];
        const dataPart = mainParts.length > 1 ? mainParts[1].split('^') : [];

        const result = {
            code: codePart.length > 0 ? codePart[0] : '',
            id: codePart.length > 1 ? codePart[1] : '',
            condition: codePart.length > 2 ? codePart[2] : '',
            repeat: codePart.length > 3 ? codePart[3] : 1
        }

        // special fields according to event.code
        switch (result.code) {
            case 'character.behaviour':
            case 'character.automated':
                result.behaviour = {
                    type: dataPart.length > 0 ? dataPart[0] : '',
                    direction: dataPart.length > 1 ? dataPart[1] : ''
                }
                break;
            case 'character.talk':
            case 'character.dialog':
                result.data = {
                    message: dataPart.length > 0 ? dataPart[0] : '',
                    timeout: dataPart.length > 1 ? dataPart[1] : null
                }
                break;
            case 'character.reaction':
                result.data = {
                    reaction: dataPart.length > 0 ? dataPart[0] : '',
                    timeout: dataPart.length > 1 ? dataPart[1] : null
                }
                break;
            case 'character.inventory.add':
            case 'character.inventory.remove':
            case 'character.inventory.consume':
            case 'character.inventory.equip':
                result.data = {
                    item: dataPart.length > 0 ? dataPart[0] : ''
                }
                break;
            case 'character.points':
            case 'character.energy':
            case 'character.xp':
                result.data = {
                    value: dataPart.length > 0 ? dataPart[0] : '',
                    reference: dataPart.length > 1 ? dataPart[1] : ''
                }
                break;
            case 'object.state':
                result.data = {
                    state: dataPart.length > 0 ? dataPart[0] : ''
                }
                break;
            case 'object.action':
                result.data = {
                    action: dataPart.length > 0 ? dataPart[0] : '',
                    state: dataPart.length > 1 ? dataPart[1] : ''
                }
                break;
            case 'map.camera':
                result.data = {
                    speed: dataPart.length > 0 ? dataPart[0] : ''
                }
                break;
            case 'world.message':
                result.data = {
                    type: 'message',
                    title: dataPart.length > 0 ? dataPart[0] : '',
                    subtitle: dataPart.length > 1 ? dataPart[1] : '',
                    message: dataPart.length > 2 ? [dataPart[2]] : [''],
                    timeout: dataPart.length > 3 ? dataPart[3] : null
                }
                break;
            case 'world.trivia':
                result.data = {
                    type: 'trivia',
                    title: dataPart.length > 0 ? dataPart[0] : '',
                    subtitle: dataPart.length > 1 ? dataPart[1] : '',
                    options: dataPart.length > 2 ? dataPart[2].split('-') : []
                }
                break;
            case 'map.wall.add':
            case 'map.wall.remove':
                result.marker = dataPart.length > 0 ? dataPart[0] : '';
                break;
            default:
                result.data = dataPart;
        }

        return result;
    },
    checkCondition: (event) => true,
    callbacks: {},
    world: null
}

export const registerEvents = (loader) => {
    // register event callbacks
    //EventManager.clear();
    EventManager.world = loader.world;

    // condition verifier
    const conditionFcns = {
        item: (e, params) => {
            // find player item
            return loader.world.player.items[params];
        },
        state: (e, params) => {
            const splitted = params.split('=');

            // object state
            const id = splitted.length >= 1 ? splitted[0] : '';
            const state = splitted.length >= 2 ? splitted[1] : '';

            const object = loader.world.map && loader.world.map.gameObjects ? loader.world.map.gameObjects[id] : null;

            return state === object?.state || '';
        },
        context: (e, params) => {
            if (!e.context || e.context.length === 0 || !params) return false;

            const splitted = params.split('=');

            // context variable
            const variable = splitted.length >= 1 ? splitted[0] : '';
            const value = splitted.length >= 2 ? splitted[1] : '';
            
            if (!variable) return false;

            // check for variable existance
            if (!value && params.indexOf('=') === -1) {
                return e.context[variable];
            }

            return e.context[variable] === value;
        },
        milestone: (e, params) => {
            const milestone = loader.world.loader.milestones[params];
            return milestone && milestone.completed;
        },
        mission: (e, params) => {
            const splitted = params.split('=');

            // mission state
            const id = splitted.length >= 1 ? splitted[0] : '';
            const state = splitted.length >= 2 ? splitted[1] : '';

            const mission = loader.world.loader.missions[id];

            return +state === mission?.state || '';
        }
    };

    EventManager.checkCondition = (event) => {
        if (!event.condition) {
            return true;
        }

        const evaluate = (condition) => {
            // console.log(`evaluate ${condition}`)
            
            let fcn = condition.length > 0 ? condition[0] : null;
            const params = condition.length > 1 ? condition[1] : null;
            const not = fcn ? fcn.charAt(0) === '!' : false;
            fcn = fcn && fcn.charAt(0) !== '!' ? fcn : fcn.substring(1);
    
            if (fcn && conditionFcns[fcn]) {                    
                return not ? !conditionFcns[fcn](event, params) : conditionFcns[fcn](event, params);
            }
        }

        const conditions = event.condition.split(' && ');

        for (const c in conditions) {
            if (!evaluate(conditions[c].split('=>'))) return false;
        }

        return true;
    }

    // generic handlers
    EventManager.bind('console.log', (event) => {
        return new Promise((resolve, reject) => {
            console.log(event.data || event, event.context);
            resolve();
        });
    });

    EventManager.bind('context.set', (event) => {
        const data = Utils.eval(`(${event.data ? event.data[0] : ''})`) || {};
        return Promise.resolve(data);
    });

    EventManager.bind('wait', (event) => {
        return new Promise((resolve, reject) => {
            const timeout = event.id || 1000;
            setTimeout(() => resolve(), timeout);
        });
    });

    EventManager.bind('break', (event) => {
        return new Promise((resolve, reject) => {
            resolve({stop: true});
        });
    });

    // key handlers
    // EventManager.bind('keys.escape', (event) => Promise.resolve());
    // EventManager.bind('keys.space', (event) => Promise.resolve());

    // server handlers
    EventManager.bind('server.emit', (event) => {
        return new Promise((resolve, reject) => {
            const data = Utils.eval(`(${event.data ? event.data[0] : ''})`) || {};
            loader.server.emit(event.id, data);
            resolve();
        });
    });

    // hotspot and gateway handlers
    EventManager.bind('hotspot.enter', (event) => {
        return new Promise((resolve, reject) => {
            // only activate hotspot trigger for main player
            if (!event.hotspot || !event.character || event.character.id !== loader.world.player.id) {
                reject('not a hotspot');
                return;
            }

            // pause world and stop all events
            loader.world.isPaused = true;
            loader.world.directionInput.stopAll();

            // dispatch hotspot events      
            const context = event.hotspot.data?.context || {};
            context.hotspot = event.hotspot.id;

            EventManager.dispatch(event.hotspot.data && event.hotspot.data.events ? event.hotspot.data.events.enter || [] : [], context)
                .then(() => resolve())
                .catch((err) => reject(err))
                .finally(() => { loader.world.map.save(); loader.world.isPaused = false; });
        })
    });

    EventManager.bind('hotspot.leave', (event) => {
        return new Promise((resolve, reject) => {
            // only activate hotspot trigger for main player
            if (!event.hotspot || !event.character || event.character.id !== loader.world.player.id) {
                reject('not a hotspot');
                return;
            }

            // dispatch hotspot events
            const context = event.hotspot.data?.context || {};
            context.hotspot = event.hotspot.id;
            EventManager.dispatch(event.hotspot.data && event.hotspot.data.events ? event.hotspot.data.events.leave || [] : [], context)
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    });

    const hotspotShow = (id, show) => {
        const hotspot = loader.world.map.gameObjects[id];
        if (!hotspot) {
            return;
        }
        hotspot.state = show ? 'idle' : 'hidden';
    }

    EventManager.bind('hotspot.show', (event) => { 
        return new Promise((resolve, reject) => {
            hotspotShow(event.id, true); 
            resolve();
        });
    });

    EventManager.bind('hotspot.hide', (event) => { 
        return new Promise((resolve, reject) => {
            hotspotShow(event.id, false); 
            resolve(); 
        });
    });

    EventManager.bind('gateway.enter', (event) => {
        return new Promise((resolve, reject) => {
            if (!loader.maps[event.id]) {
                reject('Map does not exist! (' + event.id + ')');
                return;
            }

            event.data = event.data || [null];
            loader.setMap(event.id, event.data[0]);

            resolve();
        });
    });

    // map handlers
    EventManager.bind('map.zoom.in', (event) => {
        return new Promise((resolve, reject) => {
            loader.world.zoomIn();
            resolve();            
        });
    });

    EventManager.bind('map.zoom.out', (event) => {
        return new Promise((resolve, reject) => {
            loader.world.zoomOut();
            resolve();            
        });
    });

    EventManager.bind('map.wall.add', (event) => {
        return new Promise((resolve, reject) => {
            if (!loader.maps[event.id] || (!event.wall && !event.marker)) {
                reject('Map, wall or marker does not exist! (' + event.id + ')');
                return;
            }

            // find map
            const map = loader.maps[event.id];
            const marker = map.markers[event.marker];
            map.addWall((marker?.x * Utils.cellSize) || event.wall?.x, (marker?.y * Utils.cellSize) || event.wall?.y);

            resolve();
        });
    });

    EventManager.bind('map.wall.remove', (event) => {
        return new Promise((resolve, reject) => {
            if (!loader.maps[event.id] || (!event.wall && !event.marker)) {
                reject('Map, wall or marker does not exist! (' + event.id + ')');
                return;
            }

            // find map & marker
            const map = loader.maps[event.id];
            const marker = map.markers[event.marker];
            map.removeWall((marker?.x * Utils.cellSize) || event.wall?.x, (marker?.y * Utils.cellSize) || event.wall.y);

            resolve();
        });
    });

    EventManager.bind('map.camera', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;
            }

            event.data = event.data || {};
            event.data.speed = event.data.speed || 2;

            // change camera object
            loader.world.map.camera.moveTo(character, event.data.speed)
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    });
    
    // map cutscene handlers
    EventManager.bind('cutscene.run', (event) => {
        return new Promise((resolve, reject) => {
            // find cutscene in the current map
            const cutscene = loader[`cutscenes.${loader.world.map.id}`] ? loader[`cutscenes.${loader.world.map.id}`][event.id] : null;

            if (!cutscene) {
                reject('Cutscence does not exist in this map! (' + event.id + ')');
                return;
            }

            // find map
            cutscene.run()
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    });

    // mission handlers
    EventManager.bind('mission.start', (event) => {
        return new Promise((resolve, reject) => {
            const mission = loader.missions ? loader.missions[event.id] : null;

            if (mission !== null && mission.state === MissionStates.pending) {
                loader.missions[event.id].start();
    
                // emit player.mission.state
                loader.server.emit('player.mission.state', { id: event.id, state: MissionStates.progress });
            }

            resolve();
        });
    });

    EventManager.bind('mission.complete', (event) => {
        return new Promise((resolve, reject) => {
            const mission = loader.missions ? loader.missions[event.id] : null;

            if (mission !== null && mission.state === MissionStates.progress) {
                loader.missions[event.id].complete();

                // emit player.mission.state
                loader.server.emit('player.mission.state', { id: event.id, state: MissionStates.completed, xp: mission.xp, coins: mission.reward });
            }

            resolve();
        });
    });

    // milestones handlers
    EventManager.bind('milestone.complete', (event) => {
        return new Promise((resolve, reject) => {
            const milestone = loader.milestones ? loader.milestones[event.id] : null;

            if (milestone !== null && !milestone.completed) {
                loader.milestones[event.id].complete();

                // emit player.milestone
                loader.server.emit('player.milestone', { id: event.id, xp: milestone.xp, coins: milestone.reward });
            }

            resolve();
        });
    });

    // object handlers
    EventManager.bind('object.state', (event) => {
        return new Promise((resolve, reject) => {
            const object = loader.world.map && loader.world.map.gameObjects ? loader.world.map.gameObjects[event.id] : null;

            if (!object) {
                reject(`no object found (${event.id})`);
                return;
            }

            object.state = event.data.state || object.state;

            resolve();
        });
    });

    EventManager.bind('object.action', (event) => {
        return new Promise((resolve, reject) => {
            const object = loader.world.map && loader.world.map.gameObjects ? loader.world.map.gameObjects[event.id] : null;
            event.data = event.data || {};

            if (!object || !event.data.action) {
                reject(`no object found (${event.id})`);
                return;
            }

            object.action = event.data.action;
            object.onActionFinished = () => {
                // change state if defined
                object.state = event.data.state || object.state;
                resolve();
            }
        });
    });

    EventManager.bind('object.create', (event) => {
        return new Promise((resolve, reject) => {
            if (!loader.world.map || !loader.world.map.gameObjects) {
                reject(`object can't be created (${event.id}) - map doesn't exists`);
                return;
            }

            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject(`creator character not found (${event.id})`);
                return;
            }

            const data = Utils.eval(`(${event.data ? event.data[0] : ''})`) || {};
            data.position = {x: character.x / Utils.cellSize, y: character.y / Utils.cellSize};

            // enumerate object
            const oId = data.object;
            let n = 1;
            while (loader.world.map.gameObjects[`${oId}.${n}`]) { n++; }

            const o = loader.world.map.createObject(`${oId}.${n}`, data);
            
            if (!o) {
                reject(`object can't be created (${oId}.${n})`);
                return;
            }

            if (data.destroy) {
                setTimeout(() => { EventManager.dispatch(`object.destroy:${o.id}`) }, data.destroy);
            }

            resolve();
        });
    });

    EventManager.bind('object.destroy', (event) => {
        return new Promise((resolve, reject) => {
            const o = loader.world.map && loader.world.map.gameObjects ? loader.world.map.gameObjects[event.id] : null;

            if (!o) {
                reject(`no object found (${event.id})`);
                return;
            }

            o.state = 'hidden';

            resolve();
        });
    });

    // message & dialogs handlers
    EventManager.bind('world.message', (event) => {
        return new Promise((resolve, reject) => {
            event.data.onComplete = () => resolve();
            loader.setWorldMessage(event.data);
        });
    });

    EventManager.bind('world.trivia', (event) => {
        return new Promise((resolve, reject) => {
            event.data.onComplete = (option) => resolve(option);
            loader.setWorldMessage(event.data);
        });
    });

    EventManager.bind('world.market', (event) => {
        return new Promise((resolve, reject) => {
            const market = loader.world.map && loader.world.map?.markets ? loader.world.map.markets[event.id] : null;

            if (!market) {
                reject(`no market found (${event.id})`);
                return;
            }

            market.onClose = () => resolve();
            loader.setMarket(market);
        });
    });

    EventManager.bind('world.redirect', (event) => {
        if (event.data && event.data[0]) {
            window.open(event.data[0], "_blank").focus();
        }

        return Promise.resolve();
    });

    EventManager.bind('character.behaviour', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character || !event.behaviour) {
                reject(`no character found (${event.id})`);
                return;
            }

            // callback when behaviour is over
            event.behaviour.callbackFcn = (behaviour) => {
                resolve();
            }

            character.startBehaviour({ map: loader.world.map }, event.behaviour);
        });
    });

    EventManager.bind('character.automated', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character || !event.behaviour) {
                reject(`no character found (${event.id})`);
                return;
            }

            character.startAutomatedBehaviour(true, event.behaviour.type);

            resolve();
        });
    });

    EventManager.bind('character.dialog', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject(`no character found (${event.id})`);
                return;                
            }

            const rect = character.map.getObjectPosition(character) || {};

            event.data.type = 'dialog';
            event.data.image = character.sprite.image;
            event.data.imageSize = character.sprite.spriteSize;
            event.data.x = rect.right;
            event.data.y = rect.top - (rect.height * 1.2);
            event.data.onComplete = () => resolve();
            event.data.className = character.id;
            loader.setWorldMessage(event.data);
        });
    });

    EventManager.bind('character.talk', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject(`no character found (${event.id})`);
                return;                
            }

            event.data.onComplete = () => resolve();
            character.talk(event.data);
        });
    });

    EventManager.bind('character.reaction', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            const rect = character.map.getObjectPosition(character) || {};
            
            const reactionSprite = loader.reactions.map[event.data.reaction];

            if (!reactionSprite) {
                reject(`no reaction found (${event.data.reaction})`);
                return;                
            }

            event.data.type = 'reaction';
            event.data.image = loader.reactions.image;
            event.data.imageWidth = loader.reactions.width;
            event.data.imageHeight = loader.reactions.height;
            event.data.imageSize = loader.reactions.size;
            event.data.imagePosition = reactionSprite;
            event.data.rect = rect;
            event.data.onComplete = () => resolve();
            event.data.className = character.id;
            loader.setWorldMessage(event.data);
        });
    });

    // item handlers
    EventManager.bind('character.inventory.add', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;
            const mapObject = loader.world.map && loader.world.map.gameObjects ? loader.world.map.gameObjects[event.data.item] : null;
            const object = loader.objects[event.data.item] || mapObject;

            if (!character || !object) {
                reject('no character or object found');
                return;                
            }

            // hide object
            const id = object.object || object.id;

            const item = {
                id: id,  
                global: false,
                label: object.data?.label,
                multiple: object.data?.multiple,
                pickable: object.data?.pickable,
                count: character.items[id] && object.data?.multiple ? (character.items[id].count || 0) + 1 : 1
            };

            if (mapObject) {
                mapObject.state = 'hidden';
            }

            character.addItem(item);
            object.onEvent('pickup', {character: character.id, count: item.count});

            // emit player.inventory.add
            loader.server.emit('player.inventory.add', item);

            loader.onItemAdded(item);

            resolve();
        });
    });

    EventManager.bind('character.inventory.remove', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;
            //const object = loader.world.map && loader.world.map.gameObjects ? loader.world.map.gameObjects[event.data.item] : null;
            const object = loader.objects ? loader.objects[event.data.item] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            // change object position & show object
            const item = {
                id: event.data.item, 
                global: false,
                x: character.x,
                y: character.y,
                state: 'idle',
                data: object.data
            };

            object.x = item.x;
            object.y = item.y;
            object.state = item.state;

            character.removeItem(item);

            // emit player.inventory.remove
            loader.server.emit('player.inventory.remove', item);
            loader.onItemRemoved(item);

            resolve();
        });
    });

    EventManager.bind('character.inventory.consume', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;
            const item = character?.items[event.data.item];

            if (!character || !item) {
                reject('no character found or no item found');
                return;                
            }

            // decrease count
            item.count = (item.count || 1) - 1;

            const _item = {
                id: item.id,  
                global: false,
                label: item.data?.label,
                multiple: item.data?.multiple,
                pickable: item.data?.pickable,
                count: item.count
            };

            if (item.count === 0) {
                character.removeItem(item);

                // emit player.inventory.remove
                loader.server.emit('player.inventory.remove', _item);
            } else {
                // update remote status
                loader.server.emit('player.inventory.add', _item);
            }

            // animation
            loader.onItemRemoved(item);

            resolve();
        });
    });

    EventManager.bind('character.inventory.equip', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;
            const item = character?.items[event.data.item];

            if (!character || !item) {
                reject('no character found or no item found');
                return;                
            }

            character.use(item);

            resolve();
        });
    });

    // character data
    EventManager.bind('character.step', (event) => {
        return new Promise((resolve, reject) => {
            //console.log(event.character.steps);
            resolve();
        });
    });

    EventManager.bind('character.steps.reset', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            character.steps = 0;

            resolve();
        });
    });

    EventManager.bind('character.steps', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            //console.log(character.steps);
            resolve(character.steps);
        });
    });

    EventManager.bind('character.points', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            const points = parseInt(event.data.value || 0);

            loader.onPointsAwarded(points, event.data.reference);
            resolve();
        });
    });

    EventManager.bind('character.xp', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            const xp = parseInt(event.data.value || 0);

            loader.server.emit('player.xp', { xp });
            resolve();
        });
    });

    EventManager.bind('character.energy', (event) => {
        return new Promise((resolve, reject) => {
            const character = loader.characters ? loader.characters[event.id] : null;

            if (!character) {
                reject('no character found');
                return;                
            }

            const energy = parseInt(event.data.value || 0);

            character.recover(energy);
            resolve();
        });
    });

    // world handlers
    EventManager.bind('world.pause', (event) => {
        return new Promise((resolve, reject) => {
            // pause world and stop all events
            loader.world.isPaused = true;
            loader.world.directionInput.stopAll();
            resolve();
        });
    });

    EventManager.bind('world.resume', (event) => {
        return new Promise((resolve, reject) => {
            loader.world.isPaused = false;
            resolve();
        });
    });

    EventManager.bind('world.function', (event) => {
        return new Promise((resolve, reject) => {
            loader.world.executeFunction(event.id, event)
                .then((result) => resolve(result))
                .catch((err) => resolve())
        });
    });
}
