import React from "react";
import {Button, Container, Form, Modal} from 'react-bootstrap';

export class MissionsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      onClick: () => {},
      form: ''
    };
  }

  render() {
    if (!this.props.show)
      return null;

    return (
        <Modal
          show={this.props.show}
          onHide={() => this.props.onClose()}
          keyboard={false}
          centered
          dialogClassName="put-modal"
        >
          <Modal.Body>
              <Container fluid>
                <Form id="mission-data-form">
                  <h2>{this.state.title}</h2>
                  {this.state.form}
                </Form>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="button button-modal" type="button" onClick={this.props.onClose}>Cancel</Button>
            <Button className="button button-modal" type="submit" form="mission-data-form" onClick={this.state.onClick}>Sign Message</Button>
          </Modal.Footer>
        </Modal>
    );
  }
}
