import { AppContext, eventUpdateEnum, rankEnumString } from "AppContext"
import { GameSelectorPlayer } from "./GameSelectorPlayer"
import { useEffect, useState } from "react"
import { iethContract } from "Landing"
import { Container } from "react-bootstrap"
import Img from "Components/Img"
import { isWalletConnected, walletConnect } from "connect/metamask-auth.module"

export const GameSelectorPlayerRebel = (props = {}) => {
  const [tokens, setTokens] = useState([])
  const [profile, setProfile] = useState({})
  const [player, setPlayer] = useState()
  const [costumes, setCostumes] = useState([])

  const onShowSelector = async () => {
    // TODO:
    // stop selector!

    // no world created (??)
    if (!props.world)
      return false;

    // not connected
    try {
      if (!await isWalletConnected()) {
        await walletConnect();
      }
    } catch {
      return false;
    }

    // check if new rebels appeared
    updateRebels()

    // reload costumes (objects)
    const c = props.world.player?.items ? Object.keys(props.world.player?.items).filter(o => o.indexOf('costume-') >= 0) : [];

    // customs from contracts
    c.push(...props.world.player?.costumes);

    setCostumes(c.map(item => ({
      id: item, 
      type: 'skin', 
      metadata: {
        attributes: [],
        name: props.world.loader.skins[item].props?.name
      },
      ...props.world.loader.skins[item].props
    })));

    // no rebels to pick
    if (profile.rebelCount <= 1 && c.length === 0) {
      return false;
    }

    return true;
  }

  const updateRebels = () => {
    const sourcePlayers = AppContext[props.type] || []

    const resistanceFan = {
      metadata: {
        index: 1,
        name: 'Resistance Fan',
        external_url: props.type === 'rebels' ? 'https://invasioneth.art/resistance/rebel/00000001' : 'https://invasioneth.art/resistance.pixelart/00000001',
        attributes: []
      }
    }

    // check if the resistance fan character exists
    const rebels = sourcePlayers.filter(item => !item.hidden && (props.type !== 'rebels' || item.tokenType === 'rebel' || item.tokenType === 'founder'));

    //if (rebels.length > 0 && rebels.filter(rebel => rebel.metadata && rebel.metadata.name === 'Resistance Fan').length === 0) {
    //  rebels.unshift(resistanceFan);
    //}

    setTokens(rebels)
    setProfile({
      rank: rankEnumString[AppContext.rank],
      rebelCount: rebels.length,
      nickname: iethContract.nickname()
    })

    // get random rebel if no player is selected
    if (!player) {
      onTokenSelected(rebels.length > 0 ? rebels[Math.floor(Math.random() * rebels.length)] : resistanceFan);
    }
  }

  const onTokenSelected = (token) => {
    if (!token || !token.metadata || token.locked) {
      return;
    }

    const rebelClass = token.metadata.attributes.find(t => t.trait_type === 'Class');

    const spritesheet = 'https://invasioneth.art/resistance/spritesheet/' + (token.spritesheet || (token.metadata.index || 1).toString(16).padStart(8, '0') + '.spritesheet.png');
    token.class = rebelClass ? rebelClass.value : '';
    setPlayer(token);

    if (props.world && props.world.loader && props.world.player) {
      props.world.player.sprite.spritesheet = spritesheet;
    } 
  }
  

  useEffect(() => {
    AppContext.events.update.add((event) => {
      if ((props.type === 'rebels' && event === eventUpdateEnum.rebels) || (props.type === 'pixels' && event === eventUpdateEnum.pixels)) {
        //updateRebels();
      }
    });
    
    updateRebels();
  }, []);

  useEffect(() => {
    if (!props.world)
      return;

    player && onTokenSelected(player);
  }, [props.world]);

  return (
    <GameSelectorPlayer
      onShowSelector={() => onShowSelector()}
      profile={profile}
      player={player}
      tokens={tokens}
      costumes={costumes}
      onTokenSelected={(token) => onTokenSelected(token)}
      component={RebelSelector}
      status={props.status}
      energy={props.energy}
      mode={props.mode}
    />
  )
}

const RebelSelector = (props = {}) => {
  const groups = props.costumes ? props.costumes.reduce(function(rv, x) { (rv[x['group']] = rv[x['group']] || []).push(x); return rv; }, {}) : [];

  return (
      <Container fluid className="rebel-selector">
        <>
          {
            Object.keys(groups).map((group, index) => {
              return (
                <div key={index}>
                  <div className="token-list-title">{group}</div>
                  <div className={"token-list" + (props.className ? " " + props.className : "")}>
                    {groups[group].map((token, index) => <CostumeSelectorEntry key={index} index={index} token={token} onTokenClick={(token) => props.onTokenClick(token)}></CostumeSelectorEntry>)}
                  </div>
                </div>
              )
            })
          }
          {
            (props.tokens?.length || 0) > 0 &&            
            <>
                <div className="token-list-title">Rebels</div>
                <div className={"token-list" + (props.className ? " " + props.className : "")}>
                {(props.tokens?.length || 0) > 0 && props.tokens.map((token, index) => <RebelSelectorEntry key={index} index={index} token={token} onTokenClick={(token) => props.onTokenClick(token)}></RebelSelectorEntry>)}
              </div>
            </>            
          }
          {
            !props.tokens &&
            <p>Loading...</p>
          }
          {
            (props.tokens?.length || 0) + (props.costumes?.length || 0) === 0 &&
            <p>No {props.title} available</p>
          }
        </>
      </Container>
  );
}

const RebelSelectorEntry = (props = {}) => {
  const image = props.token.metadata.external_url ? (props.token.metadata.external_url.indexOf('.gif') < 0 ? props.token.metadata.external_url + ".png" : props.token.metadata.external_url) : props.token.metadata.image;
  
  return (
    <div className={"token-entry " + (props.token.locked ? "token-entry-locked" : "")} 
        key={props.index} onClick={() => props.onTokenClick(props.token)}>
      <Img 
        src={image} 
        errorImage={""}
        alt="" 
        title={props.token.metadata.name}></Img>
      {
        props.token.metadata.rarity > 0 &&
        <div className="rarity" title={"Rarity #" + props.token.metadata.rarity}>#{props.token.metadata.rarity}</div>
      }
      {
        props.token.metadata.level > 0 &&
        <div className="level" title={"Level " + props.token.metadata.level}>{props.token.metadata.level}</div>
      }
      {
        false &&
        props.token.metadata.level > 0 &&
        AppContext.levelup > 0 &&
        <div className="levelup" title={"Level Up Rebel!"}></div>
      }
    </div>
  );
}

const CostumeSelectorEntry = (props = {}) => {
  const [image, setImage] = useState(props.token?.url);

  useEffect(() => {
    if (!props.token || props.token.url) return;

    props.token.loadImage && 
    props.token.loadImage(props.token.src).then((url) => {setImage(url); props.token.url = url;});  
  }, [props.token])

  if (!props.token || !image) return;

  return (
    <div className={"token-entry token-entry-costume " + (props.token.locked ? "token-entry-locked" : "")} 
        key={props.index} onClick={() => props.onTokenClick(props.token)}>
      <Img 
        src={image} 
        errorImage={""}
        alt="" 
        title={props.token.name}></Img>
    </div>
  );
}
