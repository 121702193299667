import { useEffect, useState } from "react"
import { EventManager } from "../services/EventManager";
import { Badge } from "react-bootstrap";

export const GameOption = (props = {}) => {
  const [showOption, setShowOption] = useState(false)

  useEffect(() => {
    EventManager.bind('keys.escape', (event) => {
        setShowOption(false);
        return Promise.resolve();
    });    
  }, []);  

  if (props.isHidden)
    return

  const onShowOption = () => {
    props.onShowOption && props.onShowOption()
    setShowOption(true)
  }

  return (
    <>
      <div className={`game-option game-option-${props.className}`}>
        <div className={`game-option-${props.className}-click`} onClick={() => onShowOption()}>
          <img src={props.icon} alt="" title={props.title}></img>
        </div>
        {
          props.badge &&
          <Badge bg="danger">{props.badge}</Badge>
        }
      </div>
      {
        showOption &&
        props.component &&
        <div className="world-selector" onClick={() => setShowOption(false)}>
          <props.component {...props} items={props.items || []} onItemClick={props.onItemClick || (() => {})}></props.component>
        </div>
      }
    </>
  )
}