import { MapLayer } from "./MapLayer";

export class MapSection {
  constructor(id, props) {
    this.id = id;  
    this.props = props || {}
    this.map = props.map || {};

    this.props.walls = this.props.walls || [];
    this.props.hotspots = this.props.hotspots || [];
    this.props.objects = this.props.objects || [];
  }

  init(world, layer) {
    if (!world || layer.ready) return;

    // create markers
    this.map.createMarkers(this.props.markers, layer?.props?.x, layer?.props?.y, { id: this.id });

    // add section walls to map (with layer delta)
    this.map.createWalls(this.props.walls, layer?.props?.x, layer?.props?.y);

    // add hotspots (with layer delta)
    this.map.createHotspots(this.props.hotspots, layer?.props?.x, layer?.props?.y);

    // add objects (with layer delta)
    this.props.objects.forEach((o) => this.map.createObject(o.id, o, layer));

    layer.ready = true;
  }

  createLayers(parent) {
    const layers = []
    this.props.layers.forEach(layer => { layers.push(new MapLayer({...layer, imageLoader: this.props.imageLoader, map: this.map, parent: parent})); });
    
    return layers;
  }
}