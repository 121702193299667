import React from "react";
import {Container, Modal} from 'react-bootstrap';
import {iethContract} from "Landing";

class RedeemModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: <p>You just pay for the gas</p>,
      hash: null
    };
  }

  render() {
    if (!this.props.show)
      return null;

    return (
        <Modal
          show={this.props.show}
          onHide={() => { this.setState({hash:null}); return this.props.onClose(this.state.hash !== null); }}
          keyboard={false}
          centered
          dialogClassName="redeem-modal"
        >
          {/*
          <Modal.Header closeButton>
            <Modal.Title>Redeem!</Modal.Title>
          </Modal.Header>
          */}
          <Modal.Body>
              <Container fluid className="text-center">
                {
                  this.props.token.type === '$RBLB' &&
                  <>
                    <h2>Get Rebel Bucks!</h2>
                    <div>{this.props.token.points} Points</div>
                    <div>=</div>
                    <div>{(this.props.token.points * this.props.token.rate).toFixed(4)} $RBLB</div>
                    <p></p>
                  </>
                }
                {
                  this.props.token.type !== '$RBLB' &&
                  <>
                    <h2>Redeem NFT!</h2>
                    <h3>x{this.props.token.voucherBalance} free mint!</h3>
                    <p>{this.props.token.legend}</p>
                  </>
                }
                {
                  this.state.hash === null &&
                  <button className="button button-redeem" 
                  onClick={() => iethContract.redeem(this.props.token, (message, hash) => this.setState({status: message, hash: hash}))}
                  >
                    Lets Do it!
                </button>
                }
                <hr></hr>
                <div className="redeem-message">
                  {this.state.status}
                </div>
                {
                  this.state.hash &&
                  <div className="redeemHash">
                    <b>Hash</b>
                    <div>
                      <a href={process.env.REACT_APP_NETWORK_SCAN + 'tx/' + this.state.hash} target="_blank" rel="noopener noreferrer">{this.state.hash}</a>
                    </div>
                  </div>
                }
            </Container>
          </Modal.Body>
        </Modal>
    );
  }
}

export default RedeemModalComponent;