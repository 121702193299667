import "./Apps.css";

import React from "react";
import Terminal from "./Terminal/Terminal";
import MintRebelApp from "./Mint/MintRebelApp";
import TheResistanceApp from "./Resistance/TheResistanceApp";
import PickGameApp from "PickGame/PickGameApp";
import RoachCounter from "Components/RoachCounter";
import ResistanceCampApp from "Game/ResistanceCampApp";
import InvasionMazeApp from "Game/InvasionMazeApp";
import BattleEthApp from "BattleEth/BattleEthApp";
import RebelCityApp from "Game/RebelCityApp";

class Apps extends React.Component {
  constructor(props) {
    super(props);

    const apps = [
      {
        index: 1,
        name: "Terminal",
        image: "resistance-terminal.png",
        class: "app-resistance-terminal",
        component: <Terminal onAppClose={() => this.props.onAppClose()}></Terminal>,
        enabled: true,
        section: 'small'
      },
      {
        index: 2,
        name: "Missions",
        image: "resistance-terminal-missions.png",
        class: "app-resistance-terminal-missions",
        component: <Terminal defaultMenu={5} onAppClose={() => this.props.onAppClose()}></Terminal>,
        hidden: true,
        section: 'small'
      },
      {
        index: 3,
        name: "The Resistance",
        image: "the-resistance.png",
        class: "app-the-resistance",
        component: <TheResistanceApp onAppClose={() => this.props.onAppClose()}></TheResistanceApp>,
        enabled: true,
        section: 'small'
      },
      {
        index: 4,
        name: "Mint",
        image: "mint-rebel.png",
        class: "app-mint-rebel",
        component: <MintRebelApp onAppClose={() => this.props.onAppClose()}></MintRebelApp>,
        enabled: true,
        section: 'small'
      },
      {
        index: 5,
        name: "",
        image: "",
        class: "app-pixel-land app-entry-full",
        component: <RebelCityApp onAppClose={() => this.props.onAppClose()}></RebelCityApp>,
        extra: <></>,//<div className="app-extra-legend"><small>v0.0.2</small></div>,
        enabled: true
      },
      {
        index: 6,
        name: "Resistance Camp",
        image: "resistance-camp.png",
        class: "app-resistance-camp",
        component: <ResistanceCampApp onAppClose={() => this.props.onAppClose()}></ResistanceCampApp>,
        extra: <div className="app-extra-legend"><small>dev-mode</small></div>,
        enabled: true
      },
      {
        index: 7,
        name: "Battle For Eth",
        image: "battle-for-eth.png",
        class: "app-battle-for-eth",
        component: <BattleEthApp onAppClose={() => this.props.onAppClose()}></BattleEthApp>,
        extra: <div className="app-extra-legend"><small>Stage 1</small></div>,
        enabled: true
      },
      {
        index: 8,
        name: "Invasion Maze",
        image: "invasion-maze.png",
        class: "app-invasion-maze",
        component: <InvasionMazeApp onAppClose={() => this.props.onAppClose()}></InvasionMazeApp>,
        extra: <div className="app-extra-legend">dev-mode<br/><small>v0.0.1</small></div>,
        enabled: true,
        hidden: true
      },
      {
        index: 9,
        name: "Roaches!",
        image: "roaches.png",
        class: "app-roaches",
        component: <PickGameApp onAppClose={() => this.props.onAppClose()}></PickGameApp>,
        extra: <RoachCounter></RoachCounter>,
        enabled: true,
        hidden: true
      }
    ];
  
    this.state = {
      apps: apps
    };
 
    this.menuRef = React.createRef();
  }

  componentDidMount() {
    //this.enableApps(this.props.initialized);
    //this.state.apps[1].enabled = true;
  }

  enableApps(enable) {
    this.state.apps.map((app) => app.enabled = enable);
    this.setState({apps: this.state.apps});
  }

  render() {
    return (
      <>
        <ul ref={this.menuRef} className="app-list app-list-small">
            {
              this.state.apps.map(item => 
                item.section === 'small' &&
                <this.AppEntry 
                  key={item.index} 
                  item={item} 
                  onAppClick={(menu) => this.props.onAppClick(menu)}>
                </this.AppEntry>)
            }
        </ul>
        <ul ref={this.menuRef} className="app-list app-list-medium">
            {
              this.state.apps.map(item => 
                item.section !== 'small' &&
                <this.AppEntry 
                  key={item.index} 
                  item={item} 
                  onAppClick={(menu) => this.props.onAppClick(menu)}>
                </this.AppEntry>)
            }
        </ul>
      </>
    );
  }

  getApp(index) {
    return this.apps.find(item => item.index === index);
  }

  AppEntry = (props) => {
    if (props.item.hidden) {
      return ('');
    }

    return (
        <li className={"app-entry button app-icon " + props.item.class + (!props.item.enabled ? " app-entry-disabled" : "")} key={props.item.index.toString()} onClick={() => props.item.enabled ? props.onAppClick(props.item) : null} style={props.item.style}>
          {props.item.image && <img src={require("./css/images/apps/" + props.item.image)} alt=""></img>}
          {props.item.name && <div className="app-entry-title">{props.item.name}</div>}
          {props.item.extra}
        </li>
    );
  }
}

export default Apps;