import { DirectionInput } from "../services/DirectionInput";

export class Overworld {
    constructor(props) {
        this.element = props.element;
        this.loader = props.loader;
        this.canvas = this.element.querySelector('.world-canvas');
        this.canvas.oncontextmenu = function(e) { e.preventDefault(); e.stopPropagation(); }
        this.canvas.zoom = localStorage && localStorage.getItem('zoom') ? parseInt(localStorage.getItem('zoom')) : 4;
        this.ctx = this.canvas.getContext("2d");
        this.ctx.font = "10px";
        this.ctx.imageSmoothingEnabled = false;
        this.map = null;
        this.config = props.config || {};
        this.maps = props.maps || {};

        this.setCanvasSize();

        this.isPaused = false;
        this.isRuning = true;
        this.functions = {}
    }

    doGameLoopStep(delta) {
        // clear canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.save();
        
        const state = {
            delta,
            arrow: this.directionInput.direction,
            action: this.directionInput.action,
            speed: this.directionInput.speed
        }

        // update map (objects)
        this.map.update(state);

        // update callback
        this.loader.onUpdate(state);

        // draw map (layers)
        this.map.draw(this.ctx);

        this.ctx.restore();
    }

    startGameLoop() {
        let previousMs;
        const step = 1 / (this.loader.def.fps || 60);

        const stepFcn = (timestampMs) => {
            if (!this.isRuning)
                return;
    
            previousMs = previousMs || timestampMs;
            
            let delta = (timestampMs - previousMs) / 1000;            

            if (delta >= step) {
                this.doGameLoopStep(delta);
                delta = step;
            }

            previousMs = timestampMs - (delta * 1000);

            requestAnimationFrame(stepFcn);
        }
        requestAnimationFrame(stepFcn);
    }

    init() {
        this.directionInput = new DirectionInput(this);
        this.directionInput.init();

        if (!this.map) return;

        this.map.init(this);

        this.startGameLoop();
       
        this.loader.onGameInitiated();
    }

    destroy() {
        this.isRuning = false;
        this.directionInput.destroy();
        this.directionInput = null;

        if (this.loader) this.loader.destroy();
        if (this.map) this.map.destroy();
    }

    addFunction(fcn, callback) {
        this.functions[fcn] = callback;
    }

    executeFunction(fcn, params) {
        return new Promise((resolve, reject) => {
            if (!this.functions[fcn])
                reject();

            this.functions[fcn](params)
                .then((result) => resolve(result))
                .catch((err) => reject());
        })
    }

    zoomIn() {
        if (this.canvas.zoom === 5) return;
        this.canvas.zoom++;

        this.setCanvasSize();
        localStorage && localStorage.setItem('zoom', this.canvas.zoom);
    }

    zoomOut() {
        if (this.canvas.zoom === 1) return;
        this.canvas.zoom--;
        
        this.setCanvasSize();
        localStorage && localStorage.setItem('zoom', this.canvas.zoom);
    }

    setCanvasSize() {
        const rect = this.canvas.getBoundingClientRect();
        const zoom = (this.canvas.zoom || 1) - 1;

        this.canvas.width = rect.width - (320 * zoom);
        this.canvas.height = rect.height - (180 * zoom);
        this.canvas.scale = rect.width / this.canvas.offsetWidth / (this.canvas.width / this.canvas.offsetWidth);

        this.ctx.imageSmoothingEnabled = false;
        // console.log(zoom, rect.width, rect.height, this.canvas.width, this.canvas.height, this.canvas.scale);
    }
} 