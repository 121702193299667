import { BigNumber } from "ethers";

export const rankEnum = {
  wannabe: 0,
  rebel: 1,
  squad: 2,
  platoon: 3,
  brigade: 4,
  corps: 5,
  army: 6
}

export const rankEnumString = [ 'Wannabe', 'Rebel', 'Squad', 'Platoon', 'Brigade', 'Corps', 'Army']

export const eventUpdateEnum = {
  rebels: 0,
  assets: 1,
  invaders: 2,
  earthlings: 3,
  missions: 4,
  champions: 5,
  status: 6,
  vouchers: 7,
  roaches: 8,
  roachesCounters: 9,
  bucks: 10,
  pixels: 11,
  gameItems: 12
}

class AppContextClass {
  events = {
    update: {
      add: (fcn) => this.events.update.callbacks.push(fcn),
      dispatch: (event) => this.events.update.callbacks.forEach(fcn => fcn(event)),
      callbacks: []
    },
    initilize: {
      add: (fcn) => this.events.initilize.callbacks.push(fcn),
      dispatch: (event) => {
        this.initialized = true;
        this.events.initilize.callbacks.forEach(fcn => fcn(event));
      },
      callbacks: []
    }
  }

  initialized = false;
  api = {};
  userAddress = null;
  enableWallet = true;
  walletData = null;
  signature = null;
  nonce = null;
  rebels = null;
  pixels = null;
  assets = null;
  earthlings = null;
  invaders = null;
  vouchers = [];
  points = 0;
  score = 0;
  bucks = 0;
  rblb = BigNumber.from(0);
  bucksRate = 0.1;
  chances = 0;
  levelup = 0;
  missions = [];
  rank = rankEnum.wannabe;
  contractsLoaded = false;
  battle = {
    availableAssets: [],
    assets: [],
    champions: [],
    battles: []
  };
}

export const AppContext = new AppContextClass();