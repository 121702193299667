import { ethers } from "ethers";

export class Skin {
    constructor(id, props) {
        this.id = id;
        this.props = props;
        this.contract = props.contract;
    }

    init(player, contracts) {
        if (!this.contract || !player || !player.wallet) return;

        const contract = contracts[this.contract.id]

        if (!contract) return;

        if (!contract.contract) {
          const network = {
              name: contract.networkName,
              chainId: contract.networkChainId || 0,
              _defaultProvider: (providers) => new providers.JsonRpcProvider(contract.networkDefaultProvider)
          }

          contract.contract = new ethers.Contract(contract.address, contract.abi, ethers.getDefaultProvider(network));

          if (!contract.contract) return;
        }

        const query = contract.contract.ownerOf ? contract.contract.ownerOf(this.contract.token) :
                        contract.contract.balanceOf ? contract.contract.balanceOf(player.wallet, this.contract.token) : Promise.resolve({});

        query.then((result) => {
                if (contract.contract.balanceOf && result.toNumber()) player.costumes.push(this.id);
                if (contract.contract.ownerOf && result.toLowerCase() === player.wallet.toLowerCase()) player.costumes.push(this.id);
            })
            .catch((err) => console.log(err));
    }

    destroy() {
    }

    load() {
    }
}