import { GameOption } from "./GameOption"

export const GameOptionMyCamp = (props = {}) => {
  const onShowOption = () => {
    console.log('show my camp')
  }

  return (
    <GameOption 
      {...props}
      title={'My Camp'} 
      className={'mycamp'}
      icon={require("../../../css/images/icons/fire.png")}
      onShowOption={() => onShowOption()}/>
  )
}
