import "./DailyRewards.css";
import { CheckCircle } from "react-bootstrap-icons";
import { useState } from "react";
import { Roulette, useRoulette } from 'react-hook-roulette';
import { iethContract } from "Landing";

export const DailyRewards = (props = {}) => {
  const [showRoulette, setShowRoulette] = useState(false)
  const [allCollected, setAllCollected] = useState(false)

  if (!props.show) {
    return null
  }

  const onClick = (reward) => {
    if (reward.type === 'points') {
      onReward(reward.points)
      return;
    }
    
    if (reward.type === 'roulette') {
      iethContract.signIn()
        .then((results) => {
          setShowRoulette(true);
        })
        .catch((err) => console.log(err));      
    }
  }

  const onReward = (points) => {
    setAllCollected(true);

    // call api
    // signature + score + rewardNumber
    iethContract.signIn()
        .then((results) => {
            iethContract.api.call('post', `wallets/${iethContract.userAddress()}/dailyreward/complete`, {
                signature: results.signature,
                nonce: results.nonce,
                rewardScore: points,
                rewardNumber: props.nextRewardNumber
            })
            .then((res) => res.json())
            .then(async (data) => {
              await iethContract.updateStatus();
            });
        })
        .catch((err) => console.log(err));
  }

  const pointsImage = require('./css/images/icons/points.png')
  const rouletteImage = require('./css/images/icons/roulette.png')

  const rewards = [
    { day: 1, type: 'roulette', image: rouletteImage },
    { day: 2, type: 'points', points: 20, image: pointsImage },
    { day: 3, type: 'roulette', image: rouletteImage },
    { day: 4, type: 'points', points: 30, image: pointsImage },
    { day: 5, type: 'roulette', image: rouletteImage },
    { day: 6, type: 'points', points: 50, image: pointsImage },
    { day: 7, type: 'roulette', image: rouletteImage }
  ]

  return (
    <div className="daily-rewards" onClick={() => allCollected && props.onClose && props.onClose()}>
      <div className="rewards">
        <div className="close-window" title="Close" onClick={() => props.onClose && props.onClose()}>X</div>
        <h2>Daily Rewards</h2>
        {
          showRoulette &&
          <RewardRoulette onReward={(points) => onReward(points)}></RewardRoulette>
        }
        {
          !showRoulette &&
          <div className="days">
            { rewards.map((reward) => 
                <Reward 
                  {...reward}
                  key={reward.day}
                  enabled={allCollected ? false : reward.day === props.nextRewardNumber}
                  done={allCollected ? reward.day <= props.nextRewardNumber : reward.day < props.nextRewardNumber}
                  onClick={() => onClick(reward)}
                  />
              )
            }
          </div>
        }
      </div>
    </div>
  );
}

const Reward = (props = {}) => {
  return (
    <div 
        className={"reward" + (props.enabled ? " enabled" : "") + (props.done ? " bg-success" : "")}
        onClick={() => props.enabled && props.onClick && props.onClick()}>
      <small>
        day {props.day}{"   "}
        {
          props.done &&
          <CheckCircle/>
        }
      </small>
      {
        props.image &&
        <img 
          src={props.image} 
          alt="" 
          title={props.title}></img>
      }
      <div className="points">
        <div>{props.points}</div>
      </div>
    </div>
  )
}

const RewardRoulette = (props = {}) => {
  const items = [
		{ name: "5" },
		{ name: "20" },
		{ name: "2" },
		{ name: "10" },
		{ name: "5" },
		{ name: "2" },
		{ name: "10" },
		{ name: "50" },
		{ name: "2" }
	];

  const [ isSpinning, setIsSpinning ] = useState(false)
  const [ isAvailable, setIsAvailable ] = useState(true)
  const { roulette, onStart, onStop, result } = useRoulette({ 
    items,
    options: {
      style: {
        canvas: { bg: "transparent" },
        arrow: { bg: "#20589f", size: 32 },
        label: { font: "22px Lato", defaultColor: "#fff" },        
        pie: {
          border: true,
          borderColor: '#fff',
          borderWidth: 6,
          theme: [ 
            { bg: "#ffd67b" },
            { bg: "#f5b945" },
            { bg: "#fa6c51" },
            { bg: "#eb5463" },
            { bg: "#eb86be" },
            { bg: "#9579da" },
            { bg: "#5e9cea" },
            { bg: "#73b0f4" },
            { bg: "#66d4f1" },
          ]
        },
      }
    },
    onSpinEnd: (result) => {
      if (!result || result === "0")
        return;

      props.onReward && props.onReward(result);
    }
  });

  const onClick = () => {
    if (!isAvailable)
      return;

    if (isSpinning) {
      setIsAvailable(false);
      onStop()
    } else {
      onStart()
    }

    setIsSpinning(!isSpinning);
  }

  return (
    <div className="roulette" onClick={() => onClick()}>
      <div className="result">
        {
          isAvailable &&
          <div>Click to <span className="result-points">{isSpinning ? 'STOP' : 'START'}</span> spinning</div>
        }
        {
          !isAvailable &&
          !result &&
          <div>Good Luck!</div>
        }
        {
          !isAvailable &&
          result &&
          result !== "0" && 
          <div>You won <span className="result-points">{result}</span> points</div>
        }
        {
          !isAvailable &&
          result &&
          result === "0" && 
          <div>Better luck next time Rebel</div>
        }
      </div>
			<Roulette roulette={roulette}/>
    </div>
  )
}