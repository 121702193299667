import { Utils } from "../Utils";

export class Sprite {
    constructor(props) {
        // create and load spritesheet
        this.image = new Image();
        this.imageSrc = props.src;
        this.loader = props.loader;
        this.loadSpritesheet(this.imageSrc);

        // reference game object
        this.gameObject = props.gameObject;
        // absolute position (ignore deltas)
        this.absolute = props.absolute;

        // data
        this.data = props.data || {};

        // sprite size
        this.spriteSize = props.spriteSize || { x: 32, y: 32};

        // sprite index (default = [0,0])
        this.spriteIndex = props.spriteIndex;

        // animations
        this.animations = props.animations || {};
        this.animations['idle'] = this.animations['idle'] || [[0,0]];

        this.currentAnimation = this.gameObject.state;
        this.currentAnimationFrame = 0;

        this.animationFrameLimit = this.data.animationFrameLimit;
        this.animationFrameProgress = this.animationFrameLimit;
    }

    set spritesheet(src) {
        this.loadSpritesheet(src);
    }

    get spritesheet() {
        return this.image ? this.image.src : null;
    }

    get frame() {
        if (this.spriteIndex) {
            return this.spriteIndex;
        }

        if (!this.animations[this.currentAnimation]) {
            this.currentAnimation = 'idle';
        }

        return this.animations[this.currentAnimation][this.currentAnimationFrame];
    }

    get clip() {
        const [frameX, frameY] = this.frame;
        return { 
            left: frameX * this.spriteSize.x, 
            right: (frameX * this.spriteSize.x) + this.spriteSize.x,
            top: frameY * this.spriteSize.y,
            bottom: (frameY * this.spriteSize.y) + this.spriteSize.y,
            width: this.spriteSize.x,
            height: this.spriteSize.y
        }
    }

    loadSpritesheet(src) {
        this.isLoaded = false;

        if (!this.image) {
            return;
        }
        
        if (this.loader) {
            this.loader(src)
                .then((url) => {
                    this.image.src = url;
                });
        } else {
            this.image.src = src;
        }

        this.image.onload = () => {
            this.isLoaded = true;
        }
    }

    onChangeState(state) {
        if (this.currentAnimation !== state) {
            this.currentAnimation = state;
            this.currentAnimationFrame = 0;
            this.animationFrameProgress = this.animationFrameLimit;
        }
    }

    updateAnimationProgress() {
        if (this.animationFrameProgress > 0) {
            this.animationFrameProgress--;
            return;
        }

        this.animationFrameProgress = this.animationFrameLimit;
        this.currentAnimationFrame++;

        if (this.frame === undefined) {
            this.currentAnimationFrame = 0;
        }
    }

    getPosition(ctx, cameraObject) {
        const offsetX = ((this.spriteSize.x * (this.gameObject.scale || 1) / Utils.cellSize) % 1 / 2) * Utils.cellSize;
        const offsetY = (this.spriteSize.y * (this.gameObject.scale || 1) - Utils.cellSize);

        let x = this.gameObject.x - offsetX - (this.absolute ? 0 : this.gameObject.deltaX) + (cameraObject ? Utils.widthGrid(Utils.gridCenter(ctx).x) - cameraObject.x : 0);
        let y = this.gameObject.y - offsetY - (this.absolute ? 0 : this.gameObject.deltaY) + (cameraObject ? Utils.widthGrid(Utils.gridCenter(ctx).y) - cameraObject.y : 0);

        return [x, y];
    }

    draw(ctx, cameraObject) {
        const [x, y] = this.getPosition(ctx, cameraObject);
        const [frameX, frameY] = this.frame;
        //const zoom = this.data.zoom || 1;

        try {
            this.isLoaded && this.image && ctx.drawImage(this.image,
                frameX * this.spriteSize.x, frameY * this.spriteSize.y,
                this.spriteSize.x, this.spriteSize.y,
                x, y,
                this.spriteSize.x * (this.gameObject.scale || 1), this.spriteSize.y * (this.gameObject.scale || 1)
            );
        } catch(e) {
            console.log(e);
        };

        this.updateAnimationProgress();
    }
}