import { ethers } from "ethers";
import { iethContract } from "Landing";
import abiAEKRebelCity from "./../data/AEKRebelCity.json"
import { AppContext, eventUpdateEnum } from "AppContext";
import { Alert } from "react-bootstrap";

export const rebelCityService = {
  httpDataRoot: 'https://invasioneth.art/rebelcity',
  toPrice: (value) => Number(value.toString()) / Math.pow(10, 18),
  getContract: () => {
    return new ethers.Contract(
      process.env.REACT_APP_CONTRACT_REBELCITY, 
      abiAEKRebelCity.data, 
      iethContract.getProvider()
    );
  },
  getSigner: () => {
    return rebelCityService.getContract().connect(iethContract.getWeb3Provider().getSigner());
  },
  loadAssets: async (player) => {
    if (!player) return;

    const contract = rebelCityService.getContract();

    return iethContract.api.call('get', `contracts/${process.env.REACT_APP_CONTRACT_REBELCITY}/transfers`)      
      .then((res) => res.json())
      .then((transfers) => { 
        if (!transfers || Object.keys(transfers).length === 0 || !transfers.owners || transfers.owners.length === 0) {
          return;
        }
        
        const nfts = {};

        transfers.owners.filter(owner => owner?.owner?.toUpperCase() === AppContext.userAddress?.toUpperCase()).forEach((token) => {
          // check ownership
          contract.ownerOf(token.id)
            .then((result) => {
              if (result.toLowerCase() !== AppContext.userAddress.toLowerCase()) {
                return;
              }

              // get token information
              contract.tokenURI(token.id)
                .then((result) => {
                  const metadata = result;

                  fetch(metadata, { method: 'get', dataType: 'json' })
                    .then((res) => res.json())
                    .then((meta) => { 
                      if (!meta || !meta.code) 
                        return;

                      nfts[meta.code] = (nfts[meta.code] || 0) + 1;

                      // add item
                      player.items[meta.code] = {id: meta.code, count: nfts[meta.code], isNFT: true}
                      AppContext.events.update.dispatch(eventUpdateEnum.gameItems);
                  })
                  .catch((err) => {});        
                })
                .catch((err) => {});
            })
            .catch((err) =>{});
        });
      })
      .catch((err) => console.error(err));
  },
  loadAssetsByType: async (type, onClickClan) => {
    //AppContext.battle.availableAssets = [];

    rebelCityService.getContract().getAssetsByType(type)
      .then((assets) => {
        console.log(assets);
        /*
        assets.forEach((assetId) => {
          if (!assetId?._isBigNumber) return;

          const tokenIndex = AppContext.battle.availableAssets.length;
          AppContext.battle.availableAssets.push({isLoading: true});
          AppContext.events.update.dispatch(eventUpdateEnum.champions);

          rebelCityService.getContract().getAsset(assetId.toString())
            .then((asset) => {
              if (!asset.uri) return;

              const metadata = `${rebelCityService.httpDataRoot}/metadata/${asset.uri.replace(/^.*[\\/]/, '')}`;

              fetch(metadata, { method: 'get', dataType: 'json' })
              .then((res) => res.json())
              .then((meta) => { 
                if (!meta || !meta.image) 
                  return;

                // check if asset is reserved
                rebelCityService.getContract().isAssetReserved(assetId.toString())
                  .then(([reserved, address]) => {
                    if (reserved && address.toLowerCase() !== iethContract.userAddress().toLowerCase()) {
                      // not available for this user
                      delete AppContext.battle.availableAssets[tokenIndex];
                    } else {
                      // add token data
                      AppContext.battle.availableAssets[tokenIndex] = {class: 'marketItem', metadata: meta, market: asset};
                    }

                    AppContext.events.update.dispatch(eventUpdateEnum.champions);
                  })
                  .catch((err) => { throw(err) });
              })
              .catch((err) => {
                // TODO: Show error in token view
                console.error(err);
              });               
            })
            .catch((err) =>{
              delete AppContext.battle.availableAssets[tokenIndex];
              console.error(err);
            });
        })
        */
    });
  },
  getAsset: async (code) => {
    return rebelCityService.getContract().getAsset(code);
  },
  mintAssetNative: (asset, onSetStatus) => {
    // Native price (POL)
    const options = {
      value: asset.priceNative.toString()
    };

    return rebelCityService.getSigner().mint(asset.name, options)
      .then((result) => {
        onSetStatus && onSetStatus(result);

        return iethContract.getProvider().waitForTransaction(result.hash, 1, 360000);
      });
  },
  mintAssetRBLB: (asset, onSetStatus) => {
    // approve RBLB transfer
    return iethContract.RBLB.getSigner().approve(rebelCityService.getContract().address, asset.priceRBLB.toString())
      .then((result) => {
        onSetStatus && onSetStatus(result);

        return iethContract.getProvider().waitForTransaction(result.hash, 1, 360000)
          .then((result) => {
            onSetStatus && onSetStatus(result);

            return rebelCityService.getSigner().mint(asset.name.toString())
              .then((result) => {
                onSetStatus && onSetStatus(result);

                return iethContract.getProvider().waitForTransaction(result.hash, 1, 360000);
              })
          })
      })
  },
  hasRBLBBalance: (price, onClose) => {
    if (!price || price.lt(AppContext.rblb))
      return null;

    return(
      <Alert variant="danger" onClose={() => onClose && onClose()} dismissible>
        You don't have enough RBLB earthling
      </Alert>    
    )
  },
  updateGameItemsEvent: () => AppContext.events.update.dispatch(eventUpdateEnum.gameItems),
  init: (world) => {
    world.nftService = rebelCityService;
    world.player.loadAssets = () => rebelCityService.loadAssets(world.player);
    world.player.loadAssets();
  }
}