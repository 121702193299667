import { Character } from "./Character";
import { Utils } from "../Utils";

export class MapLayer {
  constructor(props) {
    this.id = (props.parent && props.parent.id ? props.parent.id + '-' : '') + (props.id || '');
    this.title = props.title ? (props.parent?.title ? (props.parent?.title + ' ') : '') + props.title : null;
    this.parent = props.parent;
    this.props = props;
    this.map = props.map || {};
    this.layers = [];

    // load layer image
    this.loadImage();
  }

  loadImage() {
    if (!this.props.src) return;

    this.image = new Image();

    if (this.props.imageLoader) {
        this.props.imageLoader(this.props.src).then((url) => { this.image.src = url; });
    } else {
        this.image.src = this.props.src;
    }
            
    this.image.onload = () => { this.isImageLoaded = true; }
  }

  init(world) {
    if (!world) return;

    // initialize section
    if (this.props.type === 'section') {
      this.section = this.map.sections[this.props.section];
      this.layers = this.section.createLayers(this);
      this.section && this.section.init(world, this);

      // init layers
      this.layers.forEach(layer => layer.init(world));
    }
  }

  draw(ctx, root) {
    if (eval(this.props.hidden)) {
        return;
    }

    const scale = this.props.scale || this.map.render.scale || 1;

    // section
    if (this.section) {
      for (const layer of this.layers.filter(layer => !root.props.filter || layer.props.filter === root.props.filter)) {
        layer.draw(ctx, root);
      }

      if (this.section.props.debug || (this.section.props.hover && this.map.hover === this)) {
        ctx.beginPath();
        ctx.fillStyle = this.section.props.debugColor || 'blue';
        ctx.globalAlpha = 0.1;
  
        ctx.fillRect(this.map.currentX + this.x, this.map.currentY + this.y, this.w, this.h);
  
        ctx.fillStyle = "white";
        ctx.fillText(this.id, this.map.currentX + this.x + 10, this.map.currentY + this.y + 10);
  
        ctx.globalAlpha = 1;
      }
    }

    const alpha = this.props.alpha || 1;

    // layer image
    if (this.props.type === 'image' && this.image && this.isImageLoaded) {
        ctx.globalAlpha = alpha;
        ctx.drawImage(this.image, this.map.currentX + this.x, this.map.currentY + this.y, this.image.width * scale, this.image.height * scale);
        ctx.globalAlpha = 1;
    }

    // layer objects
    if (this.props.type === 'objects') {
        Object.values(this.map.gameObjects).sort((a, b) => (a.y + (a instanceof Character ? 1 : 0)) - (b.y + (b instanceof Character ? 1 : 0))).forEach(object => {
            object.draw(ctx, this.map.camera);
        });
    }

    // layer tiled
    if (this.props.type === 'tiled' && this.image && this.isImageLoaded) {
        const pattern = ctx.createPattern(this.image, 'repeat');
        pattern.setTransform(new DOMMatrix([1,0,0,1,this.map.currentX,this.h]));

        ctx.globalAlpha = alpha;
        ctx.rect(this.map.currentX + this.x, this.map.currentY + this.y, this.w, this.h);
        ctx.fillStyle = pattern;
        ctx.fill();                
        ctx.globalAlpha = 1;
    }

    // layer rect
    if (this.props.type === 'rect') {
      ctx.beginPath();
      ctx.fillStyle = this.props.color || 'red';
      ctx.globalAlpha = this.props.alpha || 1;

      ctx.fillRect(this.map.currentX + this.x, this.map.currentY + this.y, this.w, this.h);

      ctx.globalAlpha = 1;
    }
  }

  update(state, root) {
    const scale = this.props.scale || this.map.render.scale || 1;

    // section
    if (this.section) {
      const [w, h] = Utils.asGridCoords((this.section.props.width || 1) * scale, (this.section.props.height || 1) * scale);

      this.w = w;
      this.h = h;

      for (const layer of this.layers.filter(layer => !root.props.filter || layer.props.filter === root.props.filter)) {
        layer.update(state, root);
      }
    } else {
      const [w, h] = Utils.asGridCoords((this.props.width || 1) * scale, (this.props.height || 1) * scale);

      this.w = w;
      this.h = h;
    }

    const x = (this.props.x || 0) + (this !== root ? (root.props.x || 0) : 0);
    const y = (this.props.y || 0) + (this !== root ? (root.props.y || 0) : 0);

    const [xori, yori] = Utils.asGridCoords(x, y);
    this.x = xori;
    this.y = yori;
  }

  isInSection(currentX, currentY) {
    const layers = [];

    if (    this.x <= currentX && 
            this.x + this.w > currentX &&
            this.y <= currentY &&
            this.y + this.h > currentY) {
      layers.push(this);
    }

    // child sector layers
    this.section && this.layers?.filter(layer => layer.section && layer.id && layers.push(...layer.isInSection(currentX, currentY)));

    return layers;
  }
}