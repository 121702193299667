import "./RebelCityApp.css";
import BaseWorldApp from "./rebellion/ui/BaseWorldApp";
import React, { useEffect, useState } from "react";
import { AppContext, eventUpdateEnum } from "AppContext";
import { iethContract } from "Landing";
import { GameSelectorPlayerRebel } from "./rebellion/ui/GameSelectorPlayerRebel";
import { GameOptionInventory } from "./rebellion/ui/GameOptionInventory";
import { GameOptionMissions } from "./rebellion/ui/GameOptionMissions";
import { InfinitySpin } from "react-loader-spinner";
import { RBLBModal } from "Terminal/TerminalComponents/RBLBModal";
import { GameOptionLeaderboard } from "./rebellion/ui/GameOptionLeaderboard";
import { rebelCityService } from "./RebelCityService";

const RebelCityApp = (props = {}) =>  {
  const [world, setWorld] = useState(null)
  const [status, setStatus] = useState({})
  const [energy, setEnergy] = useState(0)
  const [isUnderAttack, setIsUnderAttack] = useState(false)
  const [version, setVersion] = useState()
  const [showmodalExchangePoints, setShowmodalExchangePoints] = useState(false)
  const [exchangePointsVoucher, setExchangePointsVoucher] = useState()

  const onWorldInitialized = (world) => {
    setWorld(world);

    rebelCityService.init(world);

    // get saved energy
    if (localStorage) {
      const e = localStorage.getItem('energy');
      if (e !== null) {
        const t = localStorage.getItem('energyTime');
  
        world.player.restoreEnergy(parseFloat(e), t);
      }
    }

    world.addFunction('atm-exchange-coins', (params) => {
      return new Promise((resolve) => {
        iethContract.RBLB.getExchangeVoucher()
          .then((data) => { setExchangePointsVoucher(data); })
          .catch(() => {})
  
        resolve();
      })
    })
  }

  const onUpdate = (state) => {
    const e = world?.player?.energy || 0;

    setEnergy(e);
    setIsUnderAttack(world?.player?.isUnderAttack);
  }

  useEffect(() => {
    if (!exchangePointsVoucher) return;

    setShowmodalExchangePoints(true); 
  }, [exchangePointsVoucher]);  

  useEffect(() => {
    if (localStorage && energy) {
      localStorage.setItem('energy', energy);
      localStorage.setItem('energyTime', (new Date()).getTime());
    }
  }, [energy]);  

  return(
    <div className="base-app">
      <div className="close-terminal" title="Close" onClick={() => props.onAppClose()}>X</div>
      <BaseWorldApp 
        game="pixel-land" 
        user={{wallet: iethContract.userAddress(),  nickname: AppContext.walletData ? AppContext.walletData.nickname : 'Guest'}}
        {...props}
        profile={<GameSelectorPlayerRebel world={world} type={'pixels'} status={status} energy={energy} mode={isUnderAttack ? 'danger' : 'normal'}/>}
        splash={
          <div className="pixel-land-splash base-game-splash">
            <img src={require('../css/images/banner/rebel-city-logo.png')} alt=""></img>
            {version && <div className="base-game-splash-version"><small>Version {version}</small></div>}
            <div><InfinitySpin width="200" color="#ffffff"/></div>
          </div>
        }
        options={
          <>
            {/*<GameOptionMap world={world} title='Map (M)'/>*/}
            <GameOptionInventory world={world} title='Inventory (I)'/>
            <GameOptionMissions world={world} title='Missions (T)'/>
            <GameOptionLeaderboard world={world} title='Leaderboard'/>
          </>
        }
        chat={true}
        onWorldInitialized={onWorldInitialized}
        onPlayerStatusChanged={(status) => setStatus(status)}
        onUpdate={(state) => onUpdate(state)}
        onDefLoaded={(def) => setVersion(def?.version)}>
      </BaseWorldApp>
      <RBLBModal 
          show={showmodalExchangePoints} 
          token={exchangePointsVoucher}
          onClose={async (close) => {
            setShowmodalExchangePoints(false);

            await iethContract.loadWalletData();
            await iethContract.RBLB.balance();
          }}>
        </RBLBModal>
    </div>
  )
}

export default RebelCityApp;