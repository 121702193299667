import React from "react";
import Img from "Components/Img";
import { Col, Row, ProgressBar } from "react-bootstrap";

// asset modal components

export const Asset = (props = {}) => {
  const asset = props.token;
  if (!asset) return <></>;

  const image = asset.metadata.external_url ? (asset.metadata.external_url.indexOf('.gif') < 0 ? asset.metadata.external_url + ".png" : asset.metadata.external_url) : asset.metadata.image;

  return (
    <div className={"asset-data"} key={props.index}>
      <div className="close-terminal" title="Close" onClick={() => props?.onClose()}>X</div>
      <Row>
        <Col md={6} className="position-relative">
          <Img 
            src={image} 
            errorImage={require("../css/images/resistance-fan.png")}
            alt="" 
            title={asset.name}></Img>
        </Col>
        <Col md={6}>
          <h2>{asset.metadata.name}</h2>
          <AssetAttributes asset={asset}></AssetAttributes>
          <div className="data-actions">
          </div>
        </Col>
      </Row>
    </div>
  );
}

export const AssetAttributes = (props = {}) => {
  const asset = props.asset;
  if (!asset || !asset.metadata?.attributes) return null;

  return (
    <div className="token-attrs">
        {asset.metadata.attributes.filter(attr => !props.onlyNumeric || typeof attr.value === 'number').map(attr => 
          <div key={attr.trait_type} className={`token-attr token-attr-${attr.trait_type}`}>
            <div className="attr-title">{attr.trait_type}</div>
            <div className="attr-value">{attr.value}</div>
            {
              typeof attr.value === 'number' &&
              <ProgressBar now={attr.value * 20} variant={attr.variant}/>
            }
          </div>
        )}
    </div>
  )
}
