import React from "react";
import { AppContext } from "AppContext";
import { iethContract } from "Landing";

function isMobileDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

async function connect(onConnected) {
    if (!iethContract.getWalletProvider()) {
        return;
    }

    iethContract.getWalletProvider().request({ method: "eth_requestAccounts" })
        .then((accounts) => onConnected(accounts[0]))
        .catch((err) => console.error(err));
}

export async function isWalletConnected() {
    try
    {
        const accounts = await iethContract.getWalletProvider().request({ method: 'eth_accounts' });

        if (accounts.length === 0)
            return null;

        //return ('0XD6F64C02C14AC390BF5F772DE6CD590F9412BD1C').toLowerCase();
        //return ('0x5FFD50864A988B6D1B4ceb62b8458c1E9F827377').toLowerCase();
        //return ('0x6B88468FEDb65F6DE5eD65a5542a32a304634a45').toLowerCase();
        //return ('0x4C9D16e7070817D7Ff84fB9430AE25DC5c184AfF').toLowerCase();
        //return ('0x6BE325c75bd6667e75a3549E3E7a847F1a657C8F').toLowerCase();
        //return ('0x0dc4738091d36991EB5716C169Cd0bA1b51363Df').toLowerCase();
        //return ('0XDF25A592B19D05A1C49CBC80A4B416C85D68E2B7').toLowerCase();
        //return ('0x50F7ece9183d1894E7Aa9B6B770B6A4Bb2B7455c').toLowerCase();
        //return ('0XF83DCFDF776C7F2EF781F3E7377DF13BCBF7F734').toLowerCase();
        //return ('0x6814c0A58772d9f75F1dfd930CeC983111F4B47a').toLowerCase();
        //return ('0xf83DCfDF776c7f2Ef781f3E7377df13bCbF7f734').toLowerCase();        
        //return ('0x83B63aE8B1cf62be386F1c546A1676f99BE3E080').toLowerCase();     
        //return ('0x35ACDc9a8E316ee4b932A0DAC26b6c77e4557860').toLowerCase();   
        //return ('0x4202F8903864108977f81E21eD9Cde66fbE362BC').toLowerCase();  
        //return ('0x3819c37EF09EfFCa3B5BC128d77b3a83b18c6298').toLowerCase();  
        //return ('0x900261F1ae03Cd755B45DcEc2d0FE90E94471111').toLowerCase();
        //return ('0x41defd0110133d666c52BDf9fBbcEDe5F3a87d72').toLowerCase();
        
        return accounts[0];
    } catch (e) {
        return null;
    }
}

export async function walletConnect() {
    await connect((_userAddress) => {
        AppContext.userAddress = _userAddress
        window.location.reload();
    });
}

export default function MetaMaskAuth({ onAddressChanged }) {
    const setUserAddress = (_userAddress) => {
        AppContext.userAddress = _userAddress
        window.location.reload();
    };

    return AppContext.userAddress ? (
        <Address userAddress={AppContext.userAddress} />
    ) : (
        <Connect setUserAddress={setUserAddress}/>
    );
}

function Connect({ setUserAddress }) {
    if (!iethContract.getWalletProvider()) {
        return(<NoWallet/>);
    }

    return (
        <div className="button button-connect metamask" title="Connect to Wallet" onClick={() => connect(setUserAddress)}>
            <div>Connect</div>
        </div>
    );
}

function Address({ userAddress }) {
    return (
        <div className="button" title={userAddress}></div>
    );
}

function NoWallet() {
    if (isMobileDevice()) {
        const dappUrl = "app.invasioneth.art";
        const metamaskAppDeepLink = "https://metamask.app.link/dapp/https://" + dappUrl;
        const coinbaseAppDeepLink = "https://go.cb-w.com/dapp?cb_url=https%3A%2F%2F" + dappUrl;
        const trustwalletAppDeepLink = "https://link.trustwallet.com/open_url?url=https://" + dappUrl

        return (
            <div className="buttons-connect-mobile">
                <a className="button button-connect" title="Connect to Metamask" href={metamaskAppDeepLink}>
                    <div>Metamask</div>
                </a>
                <a className="button button-connect" title="Connect to Coinbase" href={coinbaseAppDeepLink}>
                    <div>Coinbase</div>
                </a>
                <a className="button button-connect" title="Connect to Trust Wallet" href={trustwalletAppDeepLink}>
                    <div>Trust Wallet</div>
                </a>
            </div>
        );
    }

    return (
        <>
            <div id="btn-download-metamask" className="button metamask" title="You need a Metamask wallet to gain access" onClick={event =>  window.open(process.env.REACT_APP_METAMASK_URL, "_blank")}>
                <img src={require("./../css/images/logo-metamask.png")} alt=""/>
                <div>
                    <small>download</small><br/>
                    Metamask
                </div>
            </div>
        </>
    );
}