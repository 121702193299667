import { Container } from "react-bootstrap";
import { GameOption } from "./GameOption"
import { useEffect, useState } from "react";
import { MissionStates } from "../core/Mission";

export const GameOptionMissions = (props = {}) => {
  const [items, setItems] = useState([])
  const [pending, setPending] = useState(null)
  
  useEffect(() => {
    if (!props.world?.loader) return;

    setPending(props.world.loader.missionsInProgress || null);
  }, [props.world?.loader?.missionsInProgress])

  const onShowOption = () => {
    if (!props.world?.player) return

    const missions = [];

    Object.keys(props.world.loader.missions).forEach((id) => {
      const mission = props.world.loader.missions[id];

      if (mission.state !== MissionStates.pending) {
        missions.push(mission);
      }
    });

    setItems(missions)
  } 

  const onItemSelected = (item) => {
    //console.log(item)
  }

  return (
    <GameOption 
      {...props}
      title={props.title || 'Missions'} 
      className={'missions'}
      icon={require("../../../css/images/icons/missions.png")}
      onShowOption={() => onShowOption()}
      component={Missions}
      items={items}
      badge={pending}
      onItemClick={(item) => onItemSelected(item)}/>
  )
}

const Missions = (props = {}) => {
  return (
      <Container fluid>
        <>
          {
            props.items.length === 0 &&
            <>
              <div>No missions available</div>
            </>
          }
          {
            props.items.length > 0 &&
            <>
              <div className="token-list-title">In Progress</div>
              <ul className={"token-list" + (props.className ? " " + props.className : "")}>
                {
                  props.items.filter(mission => mission.state !== MissionStates.completed).map((item, index) => 
                    <MissionsItem key={index} item={item} index={index}/>
                  )
                }
              </ul>
              <div className="token-list-title">Completed</div>
              <ul className={"token-list" + (props.className ? " " + props.className : "")}>
                {
                  props.items.filter(mission => mission.state === MissionStates.completed).map((item, index) => 
                    <MissionsItem key={index} item={item} index={index} onItemClick={props.onItemClick}/>
                  )
                }
              </ul>
            </>
          }
        </>
      </Container>
  );
}

const MissionsItem = (props = {}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <li className={`token-entry token-entry-mission token-entry-mission-${props.item.state} ${isCollapsed ? 'token-entry-mission-collapsed' : ''}`}
      key={props.index} index={props.index} item={props.item} onClick={(e) => { e.stopPropagation(); setIsCollapsed(!isCollapsed); }}>
      <div>{props.item.title}</div>
      <div><small>{props.item.subtitle}</small></div>
      <div className="rewards">
        {props.item.reward !== 0 && <small>{props.item.reward} coins</small>}
        {props.item.xp !== 0 && <small>{props.item.xp} XP</small>}
      </div>
      <div className="mission-message">{props.item.message}</div>
    </li>
  );
}
