export class Market {
    constructor(id, props) {
        this.id = id;
        this.props = props;
        this.map = props.map;
        this.name = props.name || id;
        this.description = props.description;
        this.objects = props.objects || [];
        this.nfts = props.nfts || [];
    }

    init() {
        if (!this.map) return;

        this.serverId = `${this.map.id}-${this.id}`
        this.loader = this.map.world.loader;
        this.world = this.map.world;

        // expand objects if string
        if (typeof this.objects === 'string') {
            const data = this.loader[`object.${this.objects}`];

            this.objects = data ? data.map(o => ({id: o, "buy": 0, "sell": 0, "supply": -1})) : [];
        }

        // expand nfts
        //console.log(this.nfts);
        this.nfts.filter(nft => !nft.expanded).forEach(nft => {
            if (nft.type) {
                // TODO:
                // load types from contract
                return;
            }

            nft.isNFT = true;
            nft.expanded = true;            
            this.objects.push(nft);
        });


        this.update();
    }

    destroy() {
    }

    update() {
        // load live data for the market
        this.loader.getGameData(this.serverId)
            .then((data) => {
                data.objects = data.objects || {};

                if (!this.objects) return;

                this.objects.forEach((o) => {
                    o.supply = data.objects[o.id]?.supply || o.supply
                })
            })
    }

    updateNFTPrices() {
        if (!this.world?.nftService?.getAsset) return;

        // get NFT data
        this.nfts.forEach(nft => {
            this.world.nftService.getAsset(nft.id)
                .then((result) => {
                    nft.name = result.code;
                    nft.priceNative = result.priceNative;
                    nft.priceRBLB = result.priceRBLB;
                    nft.supply = result.supply ? result.supply.toNumber() : 0;
                    nft.priceNativeString = this.world.nftService.toPrice && this.world.nftService.toPrice(result.priceNative);
                    nft.priceRBLBString = this.world.nftService.toPrice && this.world.nftService.toPrice(result.priceRBLB);

                    this.world.nftService.updateGameItemsEvent && this.world.nftService.updateGameItemsEvent();
                })
                .catch((err) => {});        
        })
    }

    buy(item, count) {
        if (!this.loader) return;

        const i = {...item};
        delete i.image;
        delete i.imageClip;
        delete i.style;
        delete i.o;

        // emit player.inventory.sell
        this.loader.server.emit('player.inventory.sell', {market: this.serverId, item: i, count});
    }

    sell(item, count) {
        if (!this.loader) return;

        const i = {...item};
        delete i.image;
        delete i.imageClip;
        delete i.style;
        delete i.o;

        // emit player.inventory.buy
        this.loader.server.emit('player.inventory.buy', {market: this.serverId, item: i, count});
    }
}