import { EventManager } from "../services/EventManager";
import { Utils } from "../Utils";

export class GameObject {
    constructor(id, props) {
        this.id = id;
        this.props = props;
        this.isMounted = false;
        this.x = props.x || 0;
        this.y = props.y || 0;

        this.deltaX = props.deltaX || 0;
        this.deltaY = props.deltaY || 0;

        this.type = props.type || null;
        this.data = props.data || {};
        this.events = props.events || {};
        this.actions = props.actions || {};
        this.modifiers = props.modifiers || {};
        this.state = props.state || 'idle';
        this.energy = props.energy || 100;
        this.energyMax = props.energyMax || 100;
        this.energyStep = props.energyStep || 0.1;
        this.energyRecoverTime = props.energyRecoverTime || 15;
        this.energyRecoverValue = props.energyRecoverValue || 1;
        this.behaviour = 'none';
        this.context = props.context || {};
    }

    static create(className, id, props, parent) {
        if (!className)
            return null;

        if (parent != null) {
            const data = Object.assign({}, props.data || {});
            const context = Object.assign({}, props.context || {});
            const modifiers = Object.assign({}, props.modifiers || {});
            const effect = Object.assign({}, props.modifiers?.effect || {});
            props = Object.assign(props, parent.props);
            props.data = Object.assign(data, props.data || {});
            props.context = Object.assign(context, props.context || {});
            props.modifiers = Object.assign(modifiers, props.modifiers || {});
            props.modifiers.effect = Object.assign(effect, props.modifiers?.effect || {});
        }

        let object = new className(id, props);

        return object;
    }

    init() {
    }

    destroy() {
    }

    getPosition(ctx, cameraObject) {
        const x = this.x + (cameraObject ? Utils.widthGrid(Utils.gridCenter(ctx).x) - cameraObject.x : 0);
        const y = this.y + (cameraObject ? Utils.widthGrid(Utils.gridCenter(ctx).y) - cameraObject.y: 0);

        return [x, y];
    }

    set state(state) {
        if (this._state !== state) {
            this._state = state;
            
            this.onChangeState && this.onChangeState(state);
        }
    }

    get state() {
        return this._state;
    }

    set behaviour(behaviour) {
        if (this._behaviour !== behaviour) {
            this._behaviour = behaviour;
            
            this.onChangeBehaviour && this.onChangeBehaviour(behaviour);
        }
    }

    get behaviour() {
        return this._behaviour;
    }

    set energy(energy) {
        this._energy = energy;
        this.onChangeEnergy && this.onChangeEnergy(this._energy);
    }

    get energy() {
        return this._energy;
    }

    get isHidden() {
        return this.state === 'hidden';
    }

    get isPickable() {
        return this.data.pickable;
    }

    get isOverlapped() {
        return this.data.overlapped;
    }

    set isOverlapped(overlapped) {
        this.data.overlapped = overlapped;
    }

    get isFloating() {
        return this.data?.floating;
    }

    set isFloating(floating) {
        this.data.floating = floating;
    }

    draw(ctx, cameraObject) {
        /*
        if (this.data.drawMark) {
            const [x, y] = this.getPosition(ctx, cameraObject);
            this.drawMark(ctx, cameraObject, this.data.drawMarkColor || 'red', x, y, Utils.cellSize, Utils.cellSize);
        }
        */
    }

    drawMark(ctx, cameraObject, color, x, y, w, h) {
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.globalAlpha = 1;
        ctx.arc(x + (w / 2), y + (h / 2), w / 2, 0, 2 * Math.PI, true);
        
        ctx.lineWidth = 3;
        ctx.setLineDash([5]);
        ctx.stroke();

        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.setLineDash([0]);
        const centerX = x + (w / 2);
        const centerY = y + (h / 2);
        const cross = 4;

        ctx.moveTo(centerX - cross, centerY - cross);
        ctx.lineTo(centerX + cross, centerY + cross);
    
        ctx.moveTo(centerX + cross, centerY - cross);
        ctx.lineTo(centerX - cross, centerY + cross);
        ctx.stroke();

        ctx.globalAlpha = 1;
    }

    mount(map) {
        this.isMounted = true;
        this.map = map;
    }

    unMount(map) {
        this.isMounted = false;
        this.map = null;
    }

    update(state) {
    }

    onEvent(event, context) {
        if (!this.events[event]) return Promise.resolve();

        return EventManager.dispatch(this.events[event], {...context || {}, this: this.id});
    }

    save(data) {
        data.id = this.id;
        data.state = this.state;
        data.x = this.x;
        data.y = this.y;
    }

    load(data) {
        data.id = this.id;
        this.state = data.state;
        this.x = data.x;
        this.y = data.y;
    }
}