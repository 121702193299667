import "./Gauge.css";
import React from "react";

class Gauge extends React.Component {
    formatValue(value) {
        return value != null ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
    }    

    render() {
        //const { loaded } = this.state;
        return (
            <div className={(this.props.className || "") + " gauge"} title={this.props.title || ""} onClick={this.props.onClick}>
                <img className="icon" src={this.props.icon} alt=""></img>
                <div className="value-container">
                    <div className="value">{this.formatValue(this.props.value)}</div>
                </div>
            </div>
        );
    }
}

export default Gauge;