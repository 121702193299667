import "./TheResistanceApp.css";
import React from "react";
import { iethContract } from "Landing";
import { BigNumber } from "ethers";
import Img from "../Components/Img";
import BaseApp from "Components/BaseApp";

class TheResistanceApp extends BaseApp {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      count: 0,
      total: 1565
    };
  }

  async componentDidMount() {
    super.setState({
      loading: true,
      title: <h1>Resistance <small className="resistance-count">{this.state.count} / {this.state.total}</small></h1>,
      className: 'the-resistance'
    });

    let rebelData = {};
    let rebelRarity = {};

    await iethContract.api.call('get', `rebels`)      
    .then((res) => res.json())
    .then((data) => rebelData = data);

    await iethContract.api.call('get', `rebelsrarity`)
    .then((res) => res.json())
    .then((data) => rebelRarity = data);

    iethContract.api.call('get', `contracts/${process.env.REACT_APP_CONTRACT_RESISTANCE}/transfers`)      
      .then((res) => res.json())
      .then((data) => { 
        if ((data?.owners?.length || 0) === 0) {
          super.setState({
            loading: false,
            render: <>No Rebels in the collection</>
          });
          
          return;
        }

        let rebels = data.owners.filter((item) => {
          return item.id !== '16632171241560882840900462580621435406543332698332825844039081534165828502504' &&
              item.id !== '16632171241560882840900462580621435406543332698332825844039081533066316874028' &&
              item.id !== '16632171241560882840900462580621435406543332698332825844039081535265340129480'
        });

        const filtered = [];
        rebels.slice().reverse().forEach(item => {
          if (!filtered.find(i => i.id === item.id)) {
            item.index = iethContract.tokenIdParse(BigNumber.from(item.id)).index;
            item.indexhex = item.index.toString(16).padStart(8, '0');
            item.image = 'https://invasioneth.art/resistance/' + item.indexhex + '.png';
            item.type = item.index <= 52 ? 'founder' : 'rebel';
            item.level = rebelData[item.id] && rebelData[item.id].level ? rebelData[item.id].level : item.type === 'rebel' ? 1 : 5;
            item.rarity = rebelRarity[item.id] ? rebelRarity[item.id].ranking : -1;

            delete rebelRarity[item.id];

            filtered.push(item);
          } 
        });

        let availableRebels = 0;

        Object.keys(rebelRarity).forEach((key) => {
          const index = iethContract.tokenIdParse(BigNumber.from(key)).index;
          const indexhex = index.toString(16).padStart(8, '0');
  
          const rebel = {
            id: key,
            rarity: rebelRarity[key].ranking,
            type: 'rebel',
            index: index,
            indexhex: indexhex,
            image: 'https://invasioneth.art/resistance/' + indexhex + '.png',
            owner: 'pending'
          };

          availableRebels++;
          filtered.push(rebel);
        });

        rebels = filtered.sort((a, b) => {
          //let result = b.level - a.level;
          let result = a.rarity < b.rarity ? -1 : 1;

          if (result === 0) {
            result = a.index - b.index;
          }

          return result;
        });

        const groups = [];

        // groups
        // squad 4-8
        // Platoon 9-13
        // Brigade 14-19
        // Corps +20
        const reduced = rebels.reduce( (rv, x) => {
                            (rv[x.owner] = rv[x.owner] || []).push(x);
                            return rv;
                          }, {});

        delete reduced["pending"];

        for (const group in reduced) {
          const size = reduced[group].length;
          if (size < 4)
            continue;

          groups.push({
            group: group,
            address: group,
            size: size,
            type: size <= 8 ? 'Squad' : size <= 13 ? 'Platoon' : size <= 19 ? 'Brigade' : size <= 29 ? 'Corps' : 'Army',
            rebels: reduced[group]
          });
        }

        // founding squad
        groups.push({
          group: 'Founding Squad',
          rebels: rebels.filter(token => token.type === 'founder'),
          size: -1
        });

        // rebels
        groups.push({
          group: 'All Rebels',
          rebels: rebels.filter(token => token.type === 'rebel' || token.type === 'founder'),
          size: -2
        });

        this.setState({count: rebels.length - availableRebels, groups: groups.sort((a, b) => b.size - a.size)});

        const ordered = [...this.state.groups];

        ordered.forEach(group => {
          if (!group.address)
            return true;
          
          iethContract.api.call('get', `wallets/${group.address}`)
          .then((res) => res.json())
          .then((data) => { 
            group.group = data.nickname;
            this.setState({groups: ordered});
            super.setState({
              loading: false,
              title: <h1>Resistance <small className="resistance-count">{this.state.count} / {this.state.total}</small></h1>,
              render: <div className="the-resistance-container">
                          { this.state.groups.map((group, index) => this.renderGroup(group, index)) }
                      </div>
            });
          });
        });
      })
      .catch((err) => console.error(err));
  }

  onTokenClick(token) {
    const os = 'https://opensea.io/assets/matic/0x2ec6f17f2f9c49bbc9c059bfcd9e25cb595d8b6f/' + token.id;
    window.open(os, '_blank').focus();
  }

  renderGroup(group, index) {
    return (
      <div className="resistance-group" key={index}>
        <h4 className="text-center">{group.group}{group.type ? "'s " + group.type : ""} <small>{group.size > 0 ? "(" + group.size + ")" : ""}</small></h4>
        <ul className="resistance-hierarchy">
          {group.rebels.map((token, index) => 
            <li key={index} index={index} token={token} 
              className={token.owner === 'pending' ? 'pending' : ''}
              onClick={() => this.onTokenClick(token)}>
              {
                token.rarity > 0 &&
                <div className="rarity" title={"Rarity #" + token.rarity}>#{token.rarity}</div>
              }
              {
                token.level > 0 &&
                <div className="level" title={"Level " + token.level}>{token.level}</div>
              }
              <Img 
                src={token.image} 
                errorImage={require("../css/images/resistance-fan.png")}
                alt="" 
                title=""></Img>                  
            </li>)}
        </ul>
        <hr></hr>
      </div>
    );
  }
}

export default TheResistanceApp;