import { useEffect, useRef, useState } from "react"

export const Joystick = (props = {}) => {
  const joystickRef = useRef(null);
  const [activatedJoystick, setActivatedJoystick] = useState(false);
  const [direction, setDirection] = useState(null);
  const [velocity, setVelocity] = useState(null);

  const handleJoystickStop = (e) => {
    if (!props.world?.directionInput) return;
    
    setActivatedJoystick(false);
    setDirection(null);

    props.world.directionInput.stopAll();
  }

  const handleJoystickMove = (e) => {
    if (!joystickRef || !joystickRef.current || !props.world?.directionInput) return;

    const rect = joystickRef.current.getBoundingClientRect();
    
    // Get the touch position (assuming single touch)
    const touch = e.touches ? e.touches[0] : e;
    
    // Calculate the touch position relative to the div
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;

    const d = Math.abs(x - 50) < Math.abs(y - 50) ? (y > 50 ? 'Down' : 'Up') : (x > 50 ? 'Right' : 'Left');
    const v = Math.abs(x - 50) < Math.abs(y - 50) ? (Math.abs(y - 50) > 20 ? 4 : 1) : (Math.abs(x - 50) > 20 ? 4 : 1);

    props.world.directionInput.applyMovement(`Arrow${d}`, v);
    setDirection(d.toLocaleLowerCase());
    setVelocity(v);
  }

  return(
    <div className={`game-joystick ${activatedJoystick ? 'activated' : ''}`}
      ref={joystickRef}
      onMouseDown={(e) => setActivatedJoystick(true)}
      onMouseUp={(e) => handleJoystickStop(e)}
      onMouseMove={(e) => {activatedJoystick && handleJoystickMove(e)}}
      onTouchEnd={(e) => handleJoystickStop(e)}
      onTouchMove={(e) => handleJoystickMove(e)}>
        <div className={`game-joystick-button game-joystick-button-left ${direction === 'left' ? `activated-${velocity}` : ''}`}></div>
        <div className={`game-joystick-button game-joystick-button-right ${direction === 'right' ? `activated-${velocity}` : ''}`}></div>
        <div className={`game-joystick-button game-joystick-button-up ${direction === 'up' ? `activated-${velocity}` : ''}`}></div>
        <div className={`game-joystick-button game-joystick-button-down ${direction === 'down' ? `activated-${velocity}` : ''}`}></div>
    </div>
  )
}
