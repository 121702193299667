import { Character } from "./Character";
import { GameObject } from "./GameObject";
import { GameObjectSprite } from "./GameObjectSprite";
import { Hotspot } from "./Hotspot";
import { MapLayer } from "./MapLayer";
import { MapSection } from "./MapSection";
import { Utils } from "../Utils";
import { EventManager } from "../services/EventManager";
import { Market } from "./Market";

export class Map {
    constructor(id, props) {
        this.props = props;
        this.id = id;
        this.json = props.json;
        this.title = props.title;
        this.subtitle = props.subtitle;
        this.music = props.music;
        this.debug = props.debug;
        this.origin = props.origin;
        
        this.render = props.render || {};
        this.render.scale = this.render.scale || 1;
        this.render.layers = this.render.layers || [];

        this.sections = props.sections || [];
        this.gameObjects = props.gameObjects || {};
        this.layout = props.layout || [];
        this.walls = props.walls || {};
        this.hotspots = {}; //props.hotspots || {};
        this.markers = {};
        this.events = [];
        this.markets = {};
        this.characters = props.characters || {};
        this.charactersDOM = this.charactersDOM || [];
        this.width = props.width || 10;
        this.height = props.height || 10;
        this.imageLoader = props.loadImage;
        this.layers = [];

        // load layers
        this.render.layers.forEach(layer => {
            this.layers.push(new MapLayer({...layer, imageLoader: this.imageLoader, map: this}));
        });

        this.onInit = props.onInit || (() => {});
    }

    init(world) {
        if (!this.world) {
            // first creation
            this.world = world;

            // create markers
            this.createMarkers(this.props.markers);

            // add section walls to map (with layer delta)
            this.createWalls(this.props.walls);

            // create events
            this.createEvents(this.props.events);

            // create markets
            this.createMarkets(this.props.markets);

            // create objects
            Object.keys(this.props.objects).forEach((id) => this.createObject(id, this.props.objects[id], null));

            // load sections
            this.sections.forEach(key => {
                // get section definition from loader
                this.sections[key] = new MapSection(key, {...this.world.loader.sections[key] || {}, imageLoader: this.imageLoader, map: this});
            });

            if (!this.layout?.length) {
                // create map this.width x this.height
                // add walls
                for (let w = 0; w < this.width + 2; w++) {
                    this.walls[Utils.asGridCoordsString(w, 0)] = true;
                    this.walls[Utils.asGridCoordsString(w, this.height + 1)] = true;
                }
                for (let h = 0; h < this.height + 2; h++) {
                    this.walls[Utils.asGridCoordsString(0, h)] = true;
                    this.walls[Utils.asGridCoordsString(this.width + 1, h)] = true;
                }    
            }

            // init layers
            this.layers.forEach(layer => layer.init(world));

            // create objects from layout
            // W = Walls
            // H = Hotspot
            this.layout.forEach((row, y) => {
                //console.log(y, row);
                [...row].forEach((c, x) => {
                    switch(c) {
                        case 'W': 
                            this.walls[Utils.asGridCoordsString(x, y)] = true;
                            break;
                        default:
                            break;
                    }
                });
            });

            // create hotspots
            this.createHotspots(this.props.hotspots);
        }

        this.hover = null;
        this.onInit(this.world);
        this.mountObjects();
    }

    destroy() {
        Object.values(this.gameObjects).forEach(object => {
            object.unMount(this);
            object.destroy();
        });
    }

    set camera(object) {
        this.gameObjects["_camera_"] = object;
    }

    get camera() {
        return this.gameObjects["_camera_"];
    }

    drawBlindMode(ctx) {
        if (!this.render.blindMode || !this.camera) return;

        const [xArc, yArc] = this.camera.trackingObject.sprite.getPosition(ctx, this.camera);

        ctx.beginPath();
        ctx.lineWidth = 0;
        ctx.arc(xArc + 24, yArc + 32, this.render.blindModeSize || 48, 0, 2 * Math.PI);
        ctx.fill();
        ctx.clip();
        ctx.closePath();
    }

    draw(ctx) {
        // blind mode
        this.drawBlindMode(ctx, this.camera);

        this.currentX = this.camera ? Utils.widthGrid(Utils.gridCenter(ctx).x) - this.camera.x : 0;
        this.currentY = this.camera ? Utils.widthGrid(Utils.gridCenter(ctx).y) - this.camera.y: 0;

        // draw layers
        this.layers.forEach(layer => layer.draw(ctx, layer));

        if (this.debug) {
            ctx.beginPath();
            ctx.fillStyle = 'white';
            ctx.strokeStyle = 'red';
            ctx.globalAlpha = 0.2;

            // walls
            Object.keys(this.walls).forEach(key => {
                const x = Number(key.split(',')[0]) + this.currentX;
                const y = Number(key.split(',')[1]) + this.currentY;
        
                ctx.fillRect(x, y, Utils.cellSize, Utils.cellSize);
                ctx.strokeRect(x, y, Utils.cellSize, Utils.cellSize);
            });

            // hotspots
            ctx.fillStyle = 'red';

            Object.keys(this.hotspots).forEach(key => {
                const hotspot = this.hotspots[key];

                const x = hotspot.x + this.currentX;
                const y = hotspot.y + this.currentY;
        
                ctx.fillRect(x, y, Utils.cellSize, Utils.cellSize);
            });

            // markers
            ctx.fillStyle = 'blue';
            ctx.globalAlpha = 0.8;

            Object.keys(this.markers).forEach(key => {
                const marker = this.markers[key];
                let [x, y] = Utils.asGridCoords(marker.x, marker.y);

                x += this.currentX;
                y += this.currentY;
        
                ctx.fillRect(x + Utils.cellSize / 4, y + Utils.cellSize / 4, Utils.cellSize / 2, Utils.cellSize  / 2);
            });

            // objects
            ctx.globalAlpha = 1.0;
            ctx.strokeStyle = 'black';

            Object.keys(this.gameObjects).forEach(key => {
                const o = this.gameObjects[key];

                o.strokeRect && o.strokeRect(ctx, this.camera);

                if (o.vision) {
                    const [x,y] = o.getPosition(ctx, this.camera)
                    const v = Utils.getVisionBox(x, y, o.vision);
                    ctx.strokeRect(v.l, v.t, v.r - v.l, v.b - v.t);
                }
            });
    
            ctx.fillStyle = "white";
            let deltaY = -20;
    
            // characters positions
            Object.keys(this.characters).forEach(key => {
                const character = this.characters[key];

                const x = this.world.player.x + this.currentX - Utils.widthGrid(Utils.gridCenter(ctx).x) + 20;
                const y = this.world.player.y + Utils.widthGrid(Utils.gridCenter(ctx).y) + this.currentY + deltaY;

                const [charX, charY] = Utils.asMapCoords(character.x, character.y);

                deltaY -= 15;

                ctx.fillText(`${Math.round(charX)},${Math.round(charY)} - ${character.id}`, x, y);
            });
        }
    }

    update(state) {
        state = state || {};
        state.map = this;

        // update layers
        this.layers.forEach(layer => layer.update(state, layer));

        Object.values(this.gameObjects).forEach(object => {
            object.update(state);
        });
    }

    createObject(id, o, parent) {
        id = (parent && parent.id ? parent?.id + '.' : '') + id;

        if (this.gameObjects[id] || o.hidden) return null;

        parent = parent || {};

        // create new object
        const props = this.world.loader.objects[o.object].props;
        const object = GameObject.create(GameObjectSprite, id, props, props.parent);
        object.object = o.object;

        // get marker position
        const position = this.markers[o.marker] || o.position;
  
        const offsetX = (position?.x || 0) + (parent.props?.x || 0) + (parent.parent ? parent.parent.props.x : 0);
        const offsetY = (position?.y || 0) + (parent.props?.y || 0) + (parent.parent ? parent.parent.props.y : 0);

        object.x = Utils.widthGrid(offsetX);
        object.y = Utils.widthGrid(offsetY);
        object.scale = o.scale;
  
        this.gameObjects[id] = object;

        //console.log(`${id} -> creating at ${offsetX}-${offsetY}`);

        // offsetY must substract the object height

        // create markers
        this.createMarkers(props.markers, offsetX, offsetY - ((props.height - 1) || 0), { id: id });

        // create object walls
        this.createWalls(props.walls, offsetX, offsetY - ((props.height - 1) || 0));

        // create hotspots (if not disabled)
        if (!o.disabled) {
            this.createHotspots(props.hotspots, offsetX, offsetY - ((props.height - 1) || 0), { id: id });
        }

        // create objects inside object
        props.objects?.forEach((child) => this.createObject(child.id || '', child, { id: id, props: { x: offsetX, y: offsetY } }));
        
        return object;
    }

    createWalls(walls, offsetX, offsetY) {
        walls = walls || [];

        walls.forEach((wall) => {
            let [x, y, w, h] = Utils.asNumericCoords(wall);
            x = x + (offsetX || 0);
            y = y + (offsetY || 0);

            for (let deltaX = 0; deltaX < w; deltaX++) {
                for (let deltaY = 0; deltaY < h; deltaY++) {
                    this.walls[Utils.asGridCoordsString(x + deltaX, y + deltaY)] = true;
                }
            }
        })
    }

    createHotspots(hotspots, offsetX, offsetY, parent) {
        hotspots = hotspots || [];
        parent = parent || {};

        hotspots.forEach((hs) => {
            const data = {...(hs.data || {})};
            
            // add context to hotspot
            if (parent.id) {
                data.context = { parent: parent.id }
            }

            // get marker position
            const position = this.markers[hs.marker] ? [`${this.markers[hs.marker].x},${this.markers[hs.marker].y}`]: hs.pos;

            position.forEach((pos) => {
                let [x, y] = Utils.asNumericCoords(pos);
                // if using a marker, ignore offset coordinates
                // markers are absolute to the map
                x = x + (offsetX || 0) - (this.markers[hs.marker] ? (offsetX || 0) : 0);
                y = y + (offsetY || 0) - (this.markers[hs.marker] ? (offsetY || 0) : 0);
    
                const id = (parent.id ? parent.id + '.' : '') + (data.id || `hotspot-${x}-${y}`);

                //console.log(`${id} => creating hotspot... [${x},${y}] - marker: ${hs.marker}`);

                const hotspot = new Hotspot(
                    id,
                    {
                        type: 'hotspot',
                        data: data,
                        x: Utils.widthGrid(x),
                        y: Utils.widthGrid(y)
                    });
    
                //this.gameObjects[hotspot.id] = hotspot;
                this.hotspots[`${x},${y}`] = hotspot;

                // override wall
                if (hotspot.data.overrideWall && this.walls[Utils.asGridCoordsString(x, y)]) {
                    this.walls[Utils.asGridCoordsString(x, y)] = false;
                }
            })
        })
    }

    createMarkers(markers, offsetX, offsetY, parent) {
        markers = markers || [];
        parent = parent || {};

        markers.forEach((m) => {
            const id = (parent.id ? parent.id + '.' : '') + m.id;

            let [x, y] = Utils.asNumericCoords(m.pos);
            x = x + (offsetX || 0);
            y = y + (offsetY || 0);

            this.markers[id] = { x: x, y: y };
        })
    }

    createEvents(events) {
        events = events || [];

        events.forEach((e) => {
            if (!e.name || this.events[e.name]) return true;
            this.events[e.name] = e;
        })
    }

    createMarkets(markets) {
        markets = markets || [];

        markets.forEach((m) => {
            // create new market
            const props = {...this.world.loader.markets[m.market]?.props || {}, map: this};
            this.markets[m.id] = new Market(m.id, props);
            this.markets[m.id].init();
        })
    }

    getMarket(serverId) {
        return this.markets[serverId.replace(`${this.id}-`, '')];
    }

    onEvent(name) {
        if (!this.events[name] || !this.events[name].events) return;

        this.world.isPaused = true;
        this.world.directionInput.stopAll();

        EventManager.dispatch(this.events[name].events)
            .finally(() => this.world.isPaused = false);
    }

    onEnter() { this.onEvent('onEnter') }
    onLeave() { this.onEvent('onLeave') }

    isHotspot(currentX, currentY) {
        const hotspot = this.hotspots[Utils.asMapCoordsString(currentX, currentY)];

        return hotspot != null && !hotspot.isHidden ? hotspot : null;
    }

    getObjectsAt(currentX, currentY) {
        return Object.keys(this.gameObjects)
                .filter(key => 
                    this.gameObjects[key].x === currentX && 
                    this.gameObjects[key].y === currentY &&
                    !(this.gameObjects[key] instanceof Character))
                .map(key => this.gameObjects[key]);
    }

    isSpaceBlocked(currentX, currentY, direction) {
        const {x, y} = Utils.nextPosition(currentX, currentY, direction);

        return this.walls[`${x},${y}`] || false;
    }

    isPlayerAt(currentX, currentY, direction) {
        const {x, y} = Utils.nextPosition(currentX, currentY, direction);
        //return x === this.world.player.x && y === this.world.player.y;
        return  (x - Utils.cellSize / 2) <= this.world.player.x && 
                (x + Utils.cellSize / 2) >= this.world.player.x &&
                (y - Utils.cellSize / 2) <= this.world.player.y &&
                (y + Utils.cellSize / 2) >= this.world.player.y;
    }

    getSectionAt(currentX, currentY) {
        const layers = [];
        this.layers.filter(layer => layer.section && layer.id && layers.push(...layer.isInSection(currentX, currentY)));

        return layers;
    }

    setHoverSection(currentX, currentY) {
        if (this.id !== this.world.map.id) {
            this.hover = null;
            return;
        }

        const sections = this.getSectionAt(currentX, currentY);
        const section = sections.length > 0 ? sections[sections.length - 1] : null;
        
        if (section !== this.hover) {
            this.hover = section;
            this.world.loader.setWorldMessage && 
                this.world.loader.setWorldMessage({
                    type: 'title',
                    title: this.title,
                    subtitle: section && section.title ? section.title : this.subtitle
                });
        }
    }

    mountObjects() {
        Object.values(this.gameObjects).forEach(o => {
            o.mount(this);
        });
    }

    unMountObjects() {
        Object.values(this.gameObjects).forEach(o => {
            o.unMount(this);
        });
    }

    addObject(o) {
        this.gameObjects[o.id] = o;
        o.mount(this);
    }

    removeObject(o) {
        delete this.gameObjects[o.id];
        o.unMount(this);
    }

    getObjectPosition(o) {
        const rect = this.world.canvas.getBoundingClientRect();
        const scale = this.world.canvas.scale;
        const cellSizeScale = Utils.cellSize * scale;

        return new DOMRect(
            Math.floor(((o.x + (this.currentX || 0) - o.deltaX) * scale) + rect.left),
            Math.floor(((o.y + (this.currentY || 0) - o.deltaY) * scale) + rect.top),
            cellSizeScale,
            cellSizeScale
        );
    }

    addCharacterElement(character) {
        // create element in DOM and update position callbacks                    
        this.charactersDOM[character.id] = {
            id: character.id,
            position: {},
            name: character.name || character.id,
            chat: character.chat,
            isPlayerControlled: character.isPlayerControlled,
            onUpdateElement: () => {}
        }

        character.onUpdatePosition = (state) => {
            const c = this.charactersDOM[character.id];
            
            if (!c) return;

            c.position = this.getObjectPosition(character);
            c.name = character.name || character.id;
            c.chat = character.chat;

            c.onUpdateElement && c.onUpdateElement();

            if (character.id === 'player' && this.world.loader) {
                Object.keys(this.charactersDOM).forEach((id) => {
                    id !== character.id && this.world.loader.getCharacter(id).onUpdatePosition();
                })
            }
        }

        this.world.loader.onUpdateDOMCharacters && this.world.loader.onUpdateDOMCharacters(this.charactersDOM);
    }

    removeCharacterElement(character) {
        // delete element in DOM and update position callbacks
        delete this.charactersDOM[character.id];
        this.world.loader.onUpdateDOMCharacters && this.world.loader.onUpdateDOMCharacters(this.charactersDOM);
    }

    addWall(x, y) {
        this.walls[`${x},${y}`] = true;
    }

    removeWall(x, y) {
        delete this.walls[`${x},${y}`];
    }

    moveWall(wasX, wasY, direction) {
        this.removeWall(wasX, wasY);
        const {x, y} = Utils.nextPosition(wasX, wasY, direction);
        this.addWall(x, y);
    }

    save() {
        const data = {
            objects: {}
        }

        Object.values(this.gameObjects).forEach(o => {
            const oData = {};
            o.save(oData);

            data.objects[o.id] = oData;
        });

        data.walls = this.walls;

        // TODO:
        // call api handler
    }

    load(data) {
        Object.keys(data.objects).forEach(k => {
            const o = this.gameObjects[k];

            if (o) {
                o.load(data.objects[k]);
            }
        });

        this.walls = data.walls;
    }        
}