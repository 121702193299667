import "./MintRebelApp.css";

import React from "react";
import RebelFactoryComponent from "Terminal/TerminalComponents/RebelFactoryComponent";
import BaseApp from "Components/BaseApp";

class MintRebelApp extends BaseApp {
  componentDidMount() {
    super.setState({
      loading: false,
      title: null,
      className: 'rebel-factory',
      render: <RebelFactoryComponent></RebelFactoryComponent>
    });
  }
}

export default MintRebelApp;