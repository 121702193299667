export const Utils = {
    cellSize: 32,
    widthGrid: (n) => n * Utils.cellSize,
    canvasSize: (ctx) => ({ w: ctx.canvas.width.toFixed(2), h: ctx.canvas.height.toFixed(2)}),
    gridCenter: (ctx) => ({ x: (ctx.canvas.width.toFixed(2) / Utils.cellSize.toFixed(2) - 1) / 2.0, y: (ctx.canvas.height.toFixed(2) / Utils.cellSize.toFixed(2) - 1) / 2.0}),
    asGridCoords: (x, y) => [x * Utils.cellSize, y * Utils.cellSize],
    asGridCoordsString: (x, y) => `${x * Utils.cellSize},${y * Utils.cellSize}`,
    asMapCoords: (x, y) =>  [x / Utils.cellSize, y / Utils.cellSize],
    asMapCoordsString: (x, y) =>  `${x / Utils.cellSize},${y / Utils.cellSize}`,
    getVisionBox: (x, y, v) => ({ l: (x + Utils.cellSize / 2) - (v * Utils.cellSize), t: (y + Utils.cellSize / 2) - (v * Utils.cellSize), r: (x + Utils.cellSize / 2) + (v * Utils.cellSize), b: (y + Utils.cellSize / 2) + (v * Utils.cellSize) }),
    nextPosition: (initialX, initialY, direction) => {
        let x = initialX;
        let y = initialY;

        switch (direction) {
            case 'left':    x -= Utils.cellSize;    break;
            case 'right':   x += Utils.cellSize;    break;
            case 'up':      y -= Utils.cellSize;    break;
            case 'down':    y += Utils.cellSize;    break;
            default:                                break;
        }

        return {x, y};
    },
    previousPosition: (initialX, initialY, direction) => {
        let x = initialX;
        let y = initialY;

        switch (direction) {
            case 'left':    x += Utils.cellSize;    break;
            case 'right':   x -= Utils.cellSize;    break;
            case 'up':      y += Utils.cellSize;    break;
            case 'down':    y -= Utils.cellSize;    break;
            default:                                break;
        }

        return {x, y};
    },
    asNumericCoords: (coords) => {
        const splitted = coords.split(',');
        return  [splitted.length >= 1 ? +splitted[0] : 1, splitted.length >= 2 ? +splitted[1] : 1, splitted.length >= 3 ? +splitted[2] : 1, splitted.length >= 4 ? +splitted[3] : 1];
    },
    lerp: (currentValue, destinationValue, time) => currentValue * (1 - time) + destinationValue * time,
    dispatchEvent: (eventName, data) => {
        document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    },
    randomNumber: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),
    isJson: (s) => { try { JSON.parse(s); } catch (e) { return false; } return true; },
    eval: (s) => { try { return eval(s); } catch (e) { return null; } }
}