import React, { useState } from "react";
import Img from "Components/Img";
import { Button } from "react-bootstrap";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import { ChampionGridImage, ChampionGridMode, ChampionHeader } from "./Champion";

// battle modal components

export const Battle = (props = {}) => {
  const [ showBattleBanner, setShowBattleBanner] = useState(true);
  const [ mode, setMode ] = useState(ChampionGridMode.defense);

  const battle = props.token;
  if (!battle) props.onClose && props.onClose();

  const champion1 = battle?.champion1 || {};
  const champion2 = battle?.champion2 || {};

  if (showBattleBanner) {
    return(
      <BattleBanner
        show={showBattleBanner} 
        splashMode={true}
        timeout={4000}
        champion1={champion1} 
        champion2={champion2}
        onClose={() => setShowBattleBanner(false)}/>
    )
  }

  // battle mode
  return (
    <div className={"battle-data"} key={props.index}>
      <div className="close-terminal" title="Close" onClick={() => props?.onClose()}>X</div>
      <ChampionHeader champion={champion1}></ChampionHeader>
      {
        mode === ChampionGridMode.defense &&
        <>
          <h2>Set your defense...</h2>
          <div className="position-relative">
            <ChampionGridImage champion={champion1} defenseChampion={champion1} mode={ChampionGridMode.defense}/>
          </div>
          <div className="data-actions">
            <Button variant="primary" onClick={() => setMode(ChampionGridMode.attack)}><CaretRightFill></CaretRightFill></Button>
          </div>
        </>
      }
      {
        mode === ChampionGridMode.attack &&
        <>
          <h2>Attack your oponent!</h2>
          <div className="position-relative">
            <ChampionGridImage champion={champion2} attackChampion={champion1} mode={ChampionGridMode.attack} mirror={true}/>
          </div>
          <div className="data-actions">
            <Button variant="primary" onClick={() => setMode(ChampionGridMode.defense)}><CaretLeftFill></CaretLeftFill></Button>
            <Button variant="primary" onClick={() => {}}>Finish</Button>
          </div>
        </>
      }
    </div>
  )
}

export const BattleBanner = (props = {}) => {
  if (!props.champion1 || !props.champion2) return <></>;

  if (props.splashMode && !props.show) return <></>;

  const champion1 = props.champion1;
  const champion2 = props.champion2;
  
  const image1 = champion1.metadata.external_url ? (champion1.metadata.external_url.indexOf('.gif') < 0 ? champion1.metadata.external_url + ".png" : champion1.metadata.external_url) : champion1.metadata.image;
  const image2 = champion2.metadata.external_url ? (champion2.metadata.external_url.indexOf('.gif') < 0 ? champion2.metadata.external_url + ".png" : champion2.metadata.external_url) : champion2.metadata.image;

  if (props.timeout) {
    setTimeout(() => props.onClose && props.onClose(), props.timeout);
  }

  return (
    <div className={"battle-banner" + (props.splashMode ? " battle-banner-splash" : "")} onClick={() => props.splashMode && props.onClose && props.onClose()}>
      <div className="battle-champion">
        <Img 
        src={image1} 
        errorImage={require("../css/images/resistance-fan.png")}
        alt="" 
        title={champion1.metadata.name}></Img>
        <div className="name">{champion1.metadata.name}</div>
        {/*<ProgressBar now={energy1.value * 10} variant={energy1.variant}/>*/}
      </div>
      <div className="text"><div>VS</div></div>
      <div className="battle-champion">
        <Img 
        src={image2} 
        errorImage={require("../css/images/resistance-fan.png")}
        alt="" 
        title={champion2.metadata.name}
        style={{transform: "rotateY(180deg)"}}></Img>
        <div className="name">{champion2.metadata.name}</div>
        {/*<ProgressBar now={energy2.value * 10} variant={energy2.variant}/>*/}
      </div>
    </div>
  )
}
