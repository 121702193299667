import "./RebelFactoryComponent.css";
import abiAEKResistanceProxy from "../../data/AEKResistanceProxy.json"
import abiAEKPixelRebels from "../../data/AEKPixelRebels.json"
import React, { useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { iethContract } from "Landing";
import { AppContext } from "AppContext";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";

const RebelFactoryComponent = (props = {}) => {
  return (
      <>
        <div className="component-factory">
            <h1>Rebel Factory</h1>
            <ul className="mint-machine-list token-list">
              <MintItem
                className='collection-gen-2'
                image={require('./../../css/images/apps/mint-rebel.png')}
                machine={
                  <MintMachine 
                    address={process.env.REACT_APP_CONTRACT_RESISTANCE_FACTORY}
                    abi={abiAEKResistanceProxy}
                    gen={2} 
                    name='Gen 2 Rebel'/>
                }/>
              <MintItem
                className='collection-pixel-rebel'
                machine={
                  <MintMachine 
                    address={process.env.REACT_APP_CONTRACT_PIXEL_REBELS}
                    abi={abiAEKPixelRebels}
                    gen={0} 
                    name='Pixel Rebel' 
                    status_={<>Coming Soon</>}/>
                }
                image={require('./../../css/images/apps/mint-pixel-rebel.png')}/>
            </ul>          
        </div>
      </>
  );
}

const MintItem = (props = {}) => {
  return (
    <li className={(props.className || "") + " token-entry"} onClick={() => props.onClick && props.onClick()}>
      <img src={props.image} alt={''}/>
      {props.machine}
    </li>
  );
}

const MintMachine = (props = {}) => {
  const [isMinting, setIsMinting] = useState(false);
  const [hasMintError, setHasMintError] = useState(false);
  const [status, setStatus] = useState(props.status || <>Mint Closed</>);
  const [mintCount, setMintCount] = useState(1);
  const [mintPrice, setMintPrice] = useState(BigNumber.from(0));
  const [mintPriceRBLB, setMintPriceRBLB] = useState(BigNumber.from(0));
  const [isLoading, setIsLoading] = useState(true);
  const [freeMintCount, setFreeMintCount] = useState(0);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [state, setState] = useState(0);
  const [supply, setSupply] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  
  const [price, setPrice] = useState(BigNumber.from(0));
  const [priceRBLB, setPriceRBLB] = useState(BigNumber.from(0));
  const [priceWL, setPriceWL] = useState(BigNumber.from(0));

  const [contract, setContract] = useState(null);
  const [loadedGen, setLoadedGen] = useState(false);

  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async() => {
    // load factory contract
    setIsLoading(true);
    setContract(null);

    if (!props.address) {
      setIsLoading(false);
      return;
    }

    //console.log(`Loading factory... ${props.address}`);
    const provider = ethers.getDefaultProvider(iethContract.network());
    setContract(new ethers.Contract(props.address, props.abi.data, provider));
  }

  useEffect(() => {
    if (!contract) return;

    const loadContract = async() => {
      if (contract.getGen) {
        await contract.getGen(props.gen)        
        .then((result) => {
          // no supply
          if (!result?.supply?.toNumber()) {
            setStatus(<>No Supply</>);
            return;
          }

          setLoadedGen(true);
          setState(result.state);
          setPrice(result.price);
          setPriceWL(result.priceWL);
          setSupply(result.supply.toNumber());
          setTotalMinted(result.nextId.toNumber() - result.firstId.toNumber());
        })
        .catch((err) => console.log(err));
      }

      if (contract.isWhitelisted) {
        await contract.isWhitelisted(AppContext.userAddress)        
          .then((result) => setIsWhitelisted(result || false))
          .catch((err) => console.log(err));
      }

      if (contract.getPrice) {
        await contract.getPrice()
          .then((result) => {
            setLoadedGen(true);
            setState(2);
            setPrice(result[0]);
            setPriceRBLB(result[1]);
            setPriceWL(0);
          })
          .catch((err) => console.log(err));

        await contract.totalSupply()
          .then((result) => {
            setSupply(result.toNumber());
          })
          .catch((err) => console.log(err));

        await contract.totalTokens()
          .then((result) => {
            setTotalMinted(result.toNumber());
          })
          .catch((err) => console.log(err));
      }

      if (contract.getFreelistBalance) {
        await contract.getFreelistBalance(AppContext.userAddress)        
        .then((result) => setFreeMintCount(result || 0))
        .catch((err) => console.log(err));
      }

      setIsLoading(false);
    }

    loadContract();
  }, [contract]);

  useEffect(() => {
    setIsClosed((state === 0 && freeMintCount === 0) || (state === 1 && !isWhitelisted && freeMintCount === 0));
  }, [state, freeMintCount, isWhitelisted]);

  useEffect(() => {
    changeMintCount(freeMintCount > 0 ? freeMintCount : 1);
  }, [price, priceWL, isWhitelisted, freeMintCount, state]);

  useEffect(() => {
    if (!contract || !loadedGen)
      return;

    setMintCountStatus();
  }, [mintCount]);

  const setMintCountStatus = () => {
    setStatus(
      <>
        <p>Ready to Mint {mintCount} Rebel{mintCount > 1 ? 's' : ''}</p>
        <p>{freeMintCount > 0 ? 'For Free!' : mintPrice.toString() + ' POL'}</p>
      </>);
  }

  const mintStart = () => {
    setIsMinting(true);
    setHasMintError(false);
  }

  const minting = (result) => {
    setStatus(<><p>Minting... Please, wait a few minutes</p><p>Transaction: <a href={process.env.REACT_APP_NETWORK_SCAN + 'tx/' + result.hash} target="_blank" rel="noopener noreferrer">{result.hash}</a></p></>);

    result.wait()
      .then((waitresult) => {
        setIsMinting(false);
        setStatus(<><p>Done!</p><p>Wait a few moments and reload dashboard to see your rebels.</p></>);
        initialize();
      })
      .catch((err) => mintError(err.data ? err.data.message : err.message));
  }

  const mintError = (message) => {
    setIsMinting(false);
    setHasMintError(true);
    setStatus(message);
  }
  
  const changeMintCount = (count) => {
    if (  !contract ||
          !loadedGen ||
          isMinting || 
          isClosed ||
          (freeMintCount === 0 && price.isZero() && priceWL.isZero()))
      return;

    const p = isWhitelisted ? priceWL : price;
    const pRBLB = priceRBLB;

    setMintCount(count);
    setMintPrice(ethers.utils.formatEther(p.mul(count).toString(), 'ether'));
    setMintPriceRBLB(ethers.utils.formatEther(pRBLB.mul(count).toString(), 'ether'))
    setHasMintError(false);

    setMintCountStatus();
  }

  const mint = async(useRBLB) => {
    if (isMinting) return;

    if (!AppContext.userAddress) {
      // Connect to MM
      setStatus(<>Please, connect to your wallet to continue.</>);
      setHasMintError(true);
      return;
    }

    setStatus(<><p>Minting...</p><p>Follow instructions in your wallet.</p></>);

    if (!await iethContract.isCorrectNetwork()) {
      mintError(<><p>Please, switch wallet network</p><div>Name: {process.env.REACT_APP_NETWORK_NAME}</div><div>Chain Id: {process.env.REACT_APP_NETWORK_CHAINID}</div><div>RPC: {process.env.REACT_APP_NETWORK_DEFAULT_PROVIDER}</div></>);
      return;
    }

    const signer = contract.connect(iethContract.getWeb3Provider().getSigner());
    
    mintStart();

    if (freeMintCount > 0) {
      // check contract methods -> mintFree & mint or just mint 
      if (signer.mintFree) {
        signer.mintFree(props.gen, mintCount)
        .then((result) => minting(result))
        .catch((err) => mintError(err.data ? err.data.message : err.message));
      } else {
        signer.mint(props.gen, mintCount)
        .then((result) => minting(result))
        .catch((err) => mintError(err.data ? err.data.message : err.message));
      }

      return;
    } 

    if (useRBLB) {
      // RBLB requires previous approvement
      // approve RBLB transfer
      return iethContract.RBLB.getSigner().approve(props.address, priceRBLB.mul(mintCount).toString())
        .then((result) => {
          minting(result);

          return iethContract.getProvider().waitForTransaction(result.hash, 1, 180000)
            .then(() => {
              signer.mint(0, mintCount)
                .then((result) => minting(result))
                .catch((err) => mintError(err.data ? err.data.message : err.message));
            })
            .catch((err) => mintError(err.data ? err.data.message : err.message));
        })
        .catch((err) => mintError(err.data ? err.data.message : err.message));
    } else {
      const options = {value: ethers.utils.parseEther(mintPrice.toString())};

      signer.mint(props.gen, mintCount, options)
        .then((result) => minting(result))
        .catch((err) => mintError(err.data ? err.data.message : err.message));
    }
  }

  if (props.fullview) {
    return (
      <div className="machine unselectable">
        <div className="machine-status">
          <div className={"machine-gen machine-display " + (freeMintCount > 0 || state > 0 ? "machine-mode-on" : "machine-mode-off")}>GEN {props.gen}</div>
          <div className="machine-supply-count machine-display">{totalMinted} / {supply} <small>({Math.round(!supply ? 0 : totalMinted / supply * 100)}%)</small></div>
        </div>
        <hr></hr>
        <div className="machine-mode">
            <div className={"machine-display " + (freeMintCount > 0 ? "machine-mode-on" : "")}>Free {freeMintCount > 0 ? "x" + freeMintCount: ""}</div>
            <div className={"machine-display " + (freeMintCount === 0 && isWhitelisted ? "machine-mode-on" : "")}>Whitelist</div>
            <div className={"machine-display " + (freeMintCount === 0 && !isWhitelisted && state === 2 ? "machine-mode-on" : "")}>Public</div>
          </div>
        <div className={"machine-monitor machine-display" + (hasMintError ? " machine-display-error" : "")}>
          {status}
          {isLoading && <p><small>Loading...</small></p>}
        </div>
        <hr></hr>
        <div className="machine-gadgets">
          <div className="machine-mint-count-gadget">
            {
              !isMinting && !isLoading && mintCount > 1 && freeMintCount === 0 &&
              <CaretLeftFill className="machine-mint-caret" onClick={() => changeMintCount(mintCount - 1)}></CaretLeftFill>
            }
            {
              (isMinting || isLoading || mintCount === 1 || freeMintCount > 0) &&
              <CaretLeftFill className="machine-mint-caret disabled"></CaretLeftFill>
            }
            <div className="machine-mint-count machine-display">{mintCount}</div>
            {
              !isMinting && !isLoading && mintCount < 5 && freeMintCount === 0 && state > 0 &&
              <CaretRightFill className="machine-mint-caret" onClick={() => changeMintCount(mintCount + 1)}></CaretRightFill>
            }
            {
              (isMinting || isLoading || mintCount >= 5 || freeMintCount > 0 || state === 0) &&
              <CaretRightFill className="machine-mint-caret disabled"></CaretRightFill>
            }
          </div>
          {
            AppContext.userAddress && !isMinting && !isLoading && (freeMintCount > 0 || (state === 1 && isWhitelisted) || state === 2 ) &&
            <div className="machine-mint-button" title="Mint Rebels!" onClick={() => mint()}>MINT!</div>
          }
        </div>
      </div>            
    );
  }

  return (
    <>
    {
      (isMinting || hasMintError) &&
      <div className="minting-screen">
        <div>{status}</div>
        {!isMinting && <div className="close-btn" onClick={() => !isMinting && setHasMintError(false)}>Close</div>}
      </div>
    }
      <div className="machine-mini unselectable">
        <div className="title">{props.name}</div>
        <>
          {
            isLoading &&
            <div>Loading...</div>
          }
          {
            !isLoading && 
            (!contract || !loadedGen || isClosed) && 
            <div>{isClosed ? props.status || <>Mint Closed</> : status}</div>
          }
          {
            !isLoading && 
            contract && loadedGen && !isClosed &&
            <>
              <div className="">{totalMinted} / {supply} <small>({Math.round(!supply ? 0 : totalMinted / supply * 100)}%)</small></div>
              <div className="mint-type">{freeMintCount > 0 ? "Free" : isWhitelisted ? "WL" : "Public"}</div>
              { 
                  false &&                  
                  (
                    freeMintCount > 0 ? 
                    <span>Free</span> : 
                    <div className="mint-price">
                      <span><b>{mintPrice.toString()}</b> POL</span>
                      {
                        priceRBLB &&
                        !priceRBLB.isZero() &&
                        <span><b>{mintPriceRBLB.toString()}</b> RBLB</span>
                      }
                    </div>
                  )
              }
              <div className="machine-mint-count-gadget">
                {
                  !isMinting && mintCount > 1 && freeMintCount === 0 &&
                  <CaretLeftFill className="machine-mint-caret" onClick={() => changeMintCount(mintCount - 1)}></CaretLeftFill>
                }
                {
                  (isMinting || mintCount === 1 || freeMintCount > 0) &&
                  <CaretLeftFill className="machine-mint-caret disabled"></CaretLeftFill>
                }
                <div className="machine-mint-count machine-display">{mintCount}</div>
                {
                  !isMinting && mintCount < 5 && freeMintCount === 0 && state > 0 &&
                  <CaretRightFill className="machine-mint-caret" onClick={() => changeMintCount(mintCount + 1)}></CaretRightFill>
                }
                {
                  (isMinting || mintCount >= 5 || freeMintCount > 0 || state === 0) &&
                  <CaretRightFill className="machine-mint-caret disabled"></CaretRightFill>
                }
              </div>
              {
                !isMinting && 
                !isClosed &&
                <div className="mint-buttons">
                  {
                    freeMintCount > 0 ?
                    <div className="machine-mint-button" title="Mint!" onClick={() => mint(false)}>MINT<br/><small><b>Free</b></small></div>
                    :
                    <>
                      <div className="machine-mint-button" title="Mint!" onClick={() => mint(false)}>MINT<br/><small><b>{mintPrice.toString()}</b> POL</small></div>
                      {
                          freeMintCount === 0 &&
                          priceRBLB &&
                          !priceRBLB.isZero() &&
                          <div className="machine-mint-button" title="Mint!" onClick={() => mint(true)}>MINT<br/><small><b>{mintPriceRBLB.toString()}</b> RBLB</small></div>
                      }
                    </>
                  }
                </div>
              }
            </>
          }
        </>
      </div>
    </>      
  );
}

export default RebelFactoryComponent;