import React, { useEffect, useState } from "react";
import Img from "Components/Img";
import { Col, Row, Badge, Button, Tabs, Tab } from "react-bootstrap";
import { AppContext } from "AppContext";
import { Battle } from "./Battle";
import { AssetAttributes } from "./Asset";
import { BarChartFill } from "react-bootstrap-icons";
import { PiShieldCheckeredFill, PiSwordFill, PiTShirtFill } from "react-icons/pi";
import { FaBottleDroplet } from "react-icons/fa6";
import { TbSquareNumber1Filled, TbSquareNumber2Filled, TbSquareNumber3Filled, TbSquareNumber4Filled, TbSquareNumber5Filled } from "react-icons/tb";

// champion modal components
export const getCPUChampion = () => {
  
  return AppContext.battle.champions[Math.floor(Math.random() * AppContext.battle.champions.length)];

  /*
  return({
    index: -1,
    tokenId: -1,
    metadata: {
      image: require(`./../css/images/battle/champion-cpu.png`),
      name: "IA Champion",
      attributes: [
        {"trait_type": "Attack", "value": 2},
        {"trait_type": "Defense", "value": 2}
      ]
    }
  })
  */
}

export const Champion = (props = {}) => {
  const [ mode, setMode ] = useState(ChampionGridMode.view);

  const champion = props.token;
  if (!champion) return <></>;

  if (mode === ChampionGridMode.battle) {
    const battle = {
      champion1: champion,
      champion2: getCPUChampion()
    }

    return (
      <Battle token={battle} onClose={() => setMode(ChampionGridMode.view)}></Battle>
    )
  }

  return (
    <div className={"asset-data champion-data"} key={props.index}>
      <div className="close-terminal" title="Close" onClick={() => props?.onClose()}>X</div>
      <Row>
        <Col md={6} className="champion-grid-image">
          <ChampionGridImage 
            champion={champion}
            mode={mode}
            showBaseChampion={true}/>
        </Col>
        <Col md={6}>
          <h2>{champion.metadata.name}</h2>
          <div className="champion-detail">
            <Tabs
                defaultActiveKey="attributes"
                className="mb-3"
                fill>            
                <Tab eventKey="attributes" title={<BarChartFill/>}>
                  <div>
                    <div className="token-battles">
                      <div><Badge bg="success"><div>{0}</div><div className="text">won</div></Badge></div>
                      <div><Badge bg="warning"><div>{0}</div><div className="text">draw</div></Badge></div>
                      <div><Badge bg="danger"><div>{0}</div><div className="text">lost</div></Badge></div>
                    </div>
                  </div>
                  <AssetAttributes asset={champion}></AssetAttributes>
                </Tab>
                <Tab eventKey="items" title={<PiSwordFill/>}>
                  <div>Items</div>
                  <div>Add items to your champions and increase their skills</div>
                </Tab>
                <Tab eventKey="weareables" title={<PiTShirtFill />}>
                  <div>Wearables</div>
                  <div>Use a wearable to change the appearence of your base champion</div>
                  <div>This will mutate your NFT</div>
                </Tab>
                <Tab eventKey="potions" title={<FaBottleDroplet />}>
                  <div>Potions</div>
                  <div>Use a potion to change the appearence of your base champion</div>
                  <div>This will mutate your NFT</div>
                </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
      {
        false &&
        <>
          <hr></hr>
          <Row>
            <Col md={12}>
              <div className="data-actions">
                <Button variant="primary" onClick={() => setMode(ChampionGridMode.battle)}>Let's Fight!</Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export const ChampionHeader = (props = {}) => {
  const champion = props.champion;
  if (!champion) return null;

  return (
    <div className="champion-header">
      <ChampionGridImage champion={champion} mode={ChampionGridMode.view}/>
      <AssetAttributes asset={champion} onlyNumeric={true}/>
    </div>
  )
}

export const ChampionGridImage = (props = {}) => {
  if (!props.champion) return null;

  const champion = props.champion;
  const image = champion.metadata.external_url ? (champion.metadata.external_url.indexOf('.gif') < 0 ? champion.metadata.external_url + ".png" : champion.metadata.external_url) : champion.metadata.image;

  const defenseChampion = props.defenseChampion || props.champion;
  const attackChampion = props.attackChampion || props.champion;

  const defense = defenseChampion.metadata.attributes.filter((attr) => attr.trait_type === 'Defense')[0]?.value || 0;
  const attack = attackChampion.metadata.attributes.filter((attr) => attr.trait_type === 'Attack')[0]?.value || 0;

  const getIcon = (number) => {
    switch (number) {
      case 1: return <TbSquareNumber1Filled/>
      case 2: return <TbSquareNumber2Filled/>
      case 3: return <TbSquareNumber3Filled/>
      case 4: return <TbSquareNumber4Filled/>
      case 5: return <TbSquareNumber5Filled/>
      default: return <></>;
    }    
  }

  return (
    <>
      <Img 
        src={image} 
        errorImage={require("../css/images/resistance-fan.png")}
        alt="" 
        title={champion.name}
        style={props.mirror && {transform: "rotateY(180deg)"}}></Img>
      <ChampionGrid {...props} size={5} defense={defense} attack={attack}></ChampionGrid>
      {props.showBaseChampion && <BaseChampionGrid {...props}></BaseChampionGrid>}
      {
        props.mode === ChampionGridMode.view &&
        <div className="stats">
          <div className="attack">{<PiSwordFill/>} {getIcon(attack)}</div>
          <div className="defense">{<PiShieldCheckeredFill/>} {getIcon(defense)}</div>
        </div>
      }
    </>
  )
}

export const ChampionGridMode = Object.freeze({
  view: 'mode-view',
  defense: 'mode-defense',
  attack: 'mode-attack',
  battle: 'mode-battle'
})

export const ChampionGrid = (props = {}) => {
  const mode = props.mode || ChampionGridMode.view;
  const size = props.size || 5;
  const cells = size * size;
  const flexBasis = `${100 / size}%`;

  const [ defendedCells, setDefenseCells ] = useState([]);
  const [ attackedCells, setAttackedCells ] = useState([]);
  const [, setCount] = useState(0);

  useEffect(() => {
  }, []);

  const generateRandomDefense = () => {
    const defendable = cells * 0.8;
    const defended =  defendable - Math.ceil(defendable * ((5 - props.defense) / 10));
    
    const defendedCells = [];
    while(defendedCells.length < defended){
      var r = Math.floor(Math.random() * cells) + 1;
      if(defendedCells.indexOf(r) === -1) defendedCells.push(r);
    }

    setDefenseCells(defendedCells);
  }

  const onSelect = (column) => {
    if (mode !== ChampionGridMode.attack)
      return;

    // check attack limit and leave last attacks
    const attacked = attackedCells;

    if (props.attack === attacked.length) {
      attacked.shift();
    }

    attacked.push(column);
    setAttackedCells(attacked);

    console.log(`selected columns`, attackedCells);

    props.onSelect && props.onSelect(column);

    setCount(c => c + 1);
  }

  if (mode === ChampionGridMode.view) {
    return null;
  }

  if (mode === ChampionGridMode.defense && defendedCells.length === 0) {
    generateRandomDefense();
  }

  return (
    <div className={`champion-grid champion-grid-${mode}`}>
    {
      [...Array(cells).keys()].map((column) => 
        <div 
          key={column + 1} 
          className={"champion-grid-cell" + 
              (mode === ChampionGridMode.defense && defendedCells.indexOf(column + 1) !== -1 ? " protected" : "") + 
              (mode === ChampionGridMode.attack && attackedCells.indexOf(column + 1) !== -1 ? " attacked" : "")} 
          style={{flexBasis: flexBasis}}
          onClick={() => onSelect(column + 1)}
          />
      )
    }
    {
      mode === ChampionGridMode.defense &&
      <div className="grid-buttons">
        <Button variant="danger" onClick={() => generateRandomDefense()}>Randomize!</Button>
      </div>
    }
    </div>
  )
}

export const BaseChampionGrid = (props = {}) => {
  if (!props.champion || !props.champion.isBaseChampion) return null;

  // const champion = props.champion;

  return (
    <div className="base-champion-grid">
      <div className="item item-wearable"><span><PiTShirtFill/></span></div>
      <div className="item item-1"><span><PiSwordFill/></span></div>
      <div className="item item-2"><span><PiSwordFill/></span></div>
    </div>
  );
}