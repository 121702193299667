import "./PickGameModal.css";
import React from "react";
import {Button, Modal} from 'react-bootstrap';
import { AppContext } from "AppContext";
import Countdown from "react-countdown";
import {QuestionCircleFill} from 'react-bootstrap-icons'
import { iethContract } from "Landing";

class PickGameModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }  

  async onCompleteWait() {
    await new Promise(res => setTimeout(res, 1000));
    await iethContract.updateChances();
    
    if (this.props.onCompleteWait) {
      await this.props.onCompleteWait();
    }

    this.forceUpdate();
  }

  render() {
    if (!this.props.show)
      return null;

    const rendererCounterDays = ({days, hours, minutes, seconds, completed}) => {
      return <span>{days}d {hours}h {minutes}m</span>;
    };
    
    // wait to capture
    const now = iethContract.apiRootTime();
    let countDown = 0;
    if (AppContext.walletData.raffles && 
        AppContext.walletData.raffles.lastDraw && 
        AppContext.walletData.raffles.lastDraw.waitTimeFrom) {
        let waitTimeTo = new Date();
        waitTimeTo.setTime(new Date(AppContext.walletData.raffles.lastDraw.waitTimeFrom));
        waitTimeTo.setTime(waitTimeTo.getTime() + (AppContext.walletData.raffles.lastDraw.waitTime * 60 * 60 * 1000));

        countDown = waitTimeTo.getTime() - now.getTime();
    }
    
    // wait to open season
    let countDownSeason = 0;
    if (AppContext.roachesData && AppContext.roachesData.seasonStartDate) {
        let waitTimeToSeason = new Date();
        waitTimeToSeason.setTime(new Date(AppContext.roachesData.seasonStartDate));

        countDownSeason = waitTimeToSeason.getTime() - now.getTime();
        //countDownSeason = 0;
    }

    // closed season
    let closedSeason = false;
    if (AppContext.roachesData && AppContext.roachesData.seasonEndDate) {
      let closeTimeToSeason = new Date();
      closeTimeToSeason.setTime(new Date(AppContext.roachesData.seasonEndDate));

      closedSeason = closeTimeToSeason.getTime() < now.getTime();
    }

    const needToWait = countDown > 0 || countDownSeason > 0 || AppContext.chances === 0;

    const registered = AppContext && AppContext.walletData && AppContext.walletData.nickname;

    return (
        <Modal
          show={this.props.show}
          onHide={this.props.onClose}
          dialogClassName="pick-game-modal"
          keyboard={false}
          backdrop="static"
          centered>
          <Modal.Body className="text-center">
              {
                this.props.result.score >= 0 &&
                <>
                  <div className="pick-game-result">
                    {
                        this.props.result.image &&
                        <img src={this.props.result.image} alt=""></img>
                    }
                    {
                        this.props.result.prize === -1 &&
                        <>
                          <div>Congrats Rebel!</div>
                          <div>You captured a Roach!</div>
                          <div>Redeem your voucher</div>
                        </>
                    }
                    {
                        this.props.result.ufo &&
                        <>
                          <div>Damn it Rebel, the UFO got you</div>
                        </>
                    }
                    {
                        this.props.result.prize > 0 &&
                        <>
                          <div>Congrats Rebel!</div>
                          <div>You got +{this.props.result.score} score</div>
                        </>
                    }
                    {
                        this.props.result.score === 0 &&
                        !this.props.result.ufo &&
                        <>
                          <div>Sorry Rebel</div>
                          <div>Empty Window</div>
                          <div>Better luck next time</div>
                        </>
                    }
                  </div>
                  <hr></hr>
                </>
              }
              {
                closedSeason &&
                <>
                  <img className="modal-icon" src={require("../css/images/icons/wave-3-roach.png")} alt=""></img>
                  <div>The roach season is closed</div>
                </>
              }
              {
                countDownSeason > 0 &&
                <>
                  <img className="modal-icon" src={require("../css/images/icons/wave-3-roach.png")} alt=""></img>
                  <div>The roach season will open in</div>
                  <div className="counter counter-season">
                    <Countdown date={Date.now() + countDownSeason} daysInHours onComplete={() => this.onCompleteWait()} renderer={rendererCounterDays}/>
                  </div>
                </>
              }
              {
                !closedSeason &&
                registered &&
                !needToWait &&
                <>
                  <div className="stats">
                    <div>
                      <div>Chances</div>
                      <div className="stat-counter">{AppContext.chances}</div>
                      <img src={require("./../css/images/icons/tickets.png")} alt=""></img>
                    </div>
                    <div>
                      <div>Roaches</div>
                      <div className="stat-counter">{AppContext.chances < this.props.availableChances ? AppContext.chances : this.props.availableChances} </div>
                      <img src={require("./../css/images/scenarios/building/spot-roach.png")} alt=""></img>
                    </div>
                    <div>
                      <div>UFOs</div>
                      <div className="stat-counter">{this.props.availableUfos}</div>
                      <img src={require("./../css/images/scenarios/building/spot-ufo.png")} alt=""></img>
                    </div>
                  </div>
                  <div className="stats-legend">Do you want to pick a window?</div>
                </>
              }
              {
                !closedSeason &&
                registered &&
                needToWait &&
                countDownSeason <= 0 &&
                <>
                  {
                    Object.keys(this.props.result).length === 0 &&
                    <img className="modal-icon" src={require("../css/images/icons/wave-3-roach.png")} alt=""></img>
                  }
                  <div>You'll have to wait Rebel</div>
                  <div>You can capture another roach in</div>
                  <div className="counter"><Countdown date={Date.now() + countDown} daysInHours onComplete={() => this.onCompleteWait()}/></div>
                </>
              }
              {
                !closedSeason &&
                !registered &&
                <>
                  <div>You'll have to register Rebel</div>
                  <div>Go to the Terminal and complete the first mission</div>
                </>
              }
          </Modal.Body>
          <Modal.Footer>
              <>
                <Button className="button button-modal button-help mr-auto" type="button" onClick={this.props.onHelp}><QuestionCircleFill/></Button>
                {
                  !closedSeason &&
                  registered &&
                  !needToWait &&
                  <>
                    <Button className="button button-modal" type="button" onClick={this.props.onStart}>Yeah! let's do it!</Button>
                    <Button className="button button-modal" type="button" onClick={this.props.onCancel}>Nah, maybe later...</Button>
                  </>
                }
                {
                  !closedSeason &&
                  registered &&
                  needToWait &&
                  <>
                    <Button className="button button-modal" type="button" onClick={this.props.onCancel}>Ok, I'll wait...</Button>
                  </>
                }
                {
                  closedSeason &&
                  <>
                    <Button className="button button-modal" type="button" onClick={this.props.onCancel}>Damn It!</Button>
                  </>
                }
                {
                  !registered &&
                  <>
                    <Button className="button button-modal" type="button" onClick={this.props.onCancel}>Ok, I'll do it...</Button>
                  </>
                }
              </>
          </Modal.Footer>
        </Modal>
    );
  }
}

export default PickGameModalComponent;