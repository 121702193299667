import { EventManager } from "./EventManager";
import { Utils } from "../Utils";

const __eventListeners = { 
    keydown: (e) => {},
    keyup: (e) => {},
    mousemove: (e) => {},
    touchmove: (e) => {},
    addEventListeners: () => {
        document.addEventListener('keydown', __eventListeners.keydown);
        document.addEventListener('keyup', __eventListeners.keyup);
        document.addEventListener('mousemove', __eventListeners.mousemove);
        document.addEventListener('touchmove', __eventListeners.touchmove);
    },
    removeEventListeners: () => {
        document.removeEventListener('keydown', __eventListeners.keydown);
        document.removeEventListener('keyup', __eventListeners.keyup);
        document.removeEventListener('mousemove', __eventListeners.mousemove);
        document.removeEventListener('touchmove', __eventListeners.touchmove);
    }
}

export class DirectionInput {
    constructor(world) {
        this.world = world;
        this.heldDirections = [];
        this.heldActions = [];
        this.ctrlKey = false;
        this.shiftKey = false;

        this.directions =  {
            'ArrowUp': 'up',
            'KeyW': 'up',
            'ArrowDown': 'down',
            'KeyS': 'down',
            'ArrowLeft': 'left',
            'KeyA': 'left',
            'ArrowRight': 'right',
            'KeyD': 'right'
        }

        this.actions = {
            //'Space': 'jump'
        }

        // actions key shortcuts
        Object.keys(this.world.loader.actions).forEach(key => {
            const action = this.world.loader.actions[key];

            if (action.key) {
                this.actions[action.key] = action;
            }
        })

        this.keyPressed = {};
    }

    get speed() {
        return this.ctrlKey || this.shiftKey || this.velocity >= 4 ? 2 : 1;
    }

    get direction() {
        return this.heldDirections[0];
    }

    get action() {
        return this.heldActions.pop();
    }

    init() {
        __eventListeners.keydown = (e) => this.handleKeyDown(this, e);
        __eventListeners.keyup = (e) => this.handleKeyUp(this, e);
        __eventListeners.mousemove = (e) => { this.mousePosition = e; };
        __eventListeners.touchmove = (e) => { this.mousePosition = e; };
        __eventListeners.addEventListeners();

        this.world.canvas.addEventListener('mousedown', (e) => { e.preventDefault(); this.handleOneClick(); this.mousePosition = e; this.isMouseDown = true; this.handleMouseDown(e); });
        this.world.canvas.addEventListener('mouseup', (e) => { e.preventDefault(); this.mousePosition = e; this.isMouseDown = false; this.stop(); });

        this.world.canvas.addEventListener('touchstart', (e) => { e.preventDefault(); this.mousePosition = e; this.handleOneClick(); this.isMouseDown = true; this.handleMouseDown(e); });
        this.world.canvas.addEventListener('touchend', (e) => { e.preventDefault(); this.mousePosition = e; this.isMouseDown = false; this.stop(); });
    }

    destroy() {
        __eventListeners.removeEventListeners();
        this.stopAll();
        this.world = null;
    }

    move(code) {
        const direction = this.directions[code];

        if (direction && this.heldDirections.indexOf(direction) === -1) {
            this.heldDirections.unshift(direction);
        }

        const action = this.actions[code];
        if (action && this.heldActions.indexOf(action) === -1) {
            this.heldActions.unshift(action);
        }

        return direction || action;
    }

    stop(code) {
        const direction = this.directions[code];
        const index = this.heldDirections.indexOf(direction);

        if (index > -1) {
            this.heldDirections.splice(index, 1);
        } else {
            this.heldDirections = [];
        }
    }

    stopAll() {
        this.heldActions = [];
        this.heldDirections = [];
    }

    handleKeyDown(directionInputClass, e) {
        this.ctrlKey = e.ctrlKey;
        this.shiftKey = e.shiftKey;

        if ((e.code === 'Escape' || e.code === 'Space') && !this.keyPressed[e.code]) {
            // inform world to close all windows
            EventManager.dispatch(`keys.${e.code.toLowerCase()}`);
        } 

        this.keyPressed[e.code] = true;

        if (!this.world || this.world.isPaused) {
            return;
        }

        if (e.code === 'NumpadAdd') {
            EventManager.dispatch('map.zoom.in');
        } else if (e.code === 'NumpadSubtract') {
            EventManager.dispatch('map.zoom.out');
        } else if (e.key === '0' && e.altKey && e.ctrlKey && this.world.map) {
            this.world.map.debug = !this.world.map.debug;
        }

        if (this.actions[e.code]?.execute) {
            this.actions[e.code].execute();
        }

        if (directionInputClass.move(e.code)) {
            e.preventDefault();
        }
    }

    handleKeyUp(directionInputClass, e) {
        this.ctrlKey = e.ctrlKey;
        this.shiftKey = e.shiftKey;

        this.keyPressed[e.code] = false;

        directionInputClass.stop(e.code);
    };    

    handleOneClick() {
        if (!this.isMouseDown && this.world.isPaused) {
            // inform world to close all windows
            EventManager.dispatch('keys.escape');
        }
        
        // this.world.canvas.focus();
    }

    handleMouseDown() {
        this.velocity = 1;

        if (!this.isMouseDown || !this.world || this.world.isPaused) {
            return;
        }

        const rect = this.world.canvas.getBoundingClientRect();

        const clientX = (this.mousePosition.clientX || (this.mousePosition.touches ? this.mousePosition.touches[0].clientX : 0)) - rect.left;
        const clientY = (this.mousePosition.clientY || (this.mousePosition.touches ? this.mousePosition.touches[0].clientY : 0)) - rect.top;
        const clickX = (clientX / this.world.canvas.scale) - this.world.map.currentX;
        const clickY = (clientY / this.world.canvas.scale) - this.world.map.currentY;

        const cellSize = Utils.cellSize;

        const cellClickedX = Math.floor(clickX / cellSize);
        const cellClickedY = Math.floor(clickY / cellSize);
        const cellPlayerX = this.world.player.x / cellSize;
        const cellPlayerY = this.world.player.y / cellSize;

        let deltaX = Math.floor(cellClickedX - cellPlayerX);
        let deltaY = Math.floor(cellClickedY - cellPlayerY);

        deltaX = Math.abs(deltaX) >= Math.abs(deltaY) ? deltaX : 0;
        deltaY = Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : 0;

        this.velocity = Math.abs(deltaX) + Math.abs(deltaY);

        const code = deltaX > 0 ? 'ArrowRight' : 
                        deltaX < 0 ? 'ArrowLeft' : 
                            deltaY > 0 ? 'ArrowDown' :
                                deltaY < 0 ? 'ArrowUp' : '';

        this.applyMovement(code, this.velocity);

        //console.log('delta Player', deltaX, deltaY, code);

        setTimeout(() => {
            this.handleMouseDown();
        }, 200);
    }

    applyMovement(direction, velocity) {
        this.velocity = velocity;

        if (this.lastMoveCode !== direction && this.lastMoveCode) {
            this.stop(this.lastMoveCode);
            this.lastMoveCode = null;
        }

        if (direction) {
            this.move(direction);
            this.lastMoveCode = direction;
        }
    }
}