import { Button, Container } from "react-bootstrap"

export const GameTrivia = (props = {}) => {
  if (!props.visible)
    return

  return (
    <div className="world-selector">
      <Container fluid className="game-trivia">
        <h3>{props.trivia.title}</h3>
        <div>{props.trivia.subtitle}</div>
        <div className="game-trivia-buttons">
          {props.trivia.options.map(option => <Button key={option.code} onClick={() => props.onOptionSelected && props.onOptionSelected(option)}>{option.value}</Button>)}
        </div>
      </Container>
    </div>
  )
}