import { EventManager } from "../services/EventManager";

export class Milestone {
    constructor(id, props) {
        this.id = id;
        this.text = props.text || '';
        this.type = props.type || 'General';
        this.xp = props.xp || 0;
        this.reward = props.reward || 0;
        this.completed = false;
        
        this.events = props.events || {};
    }

    complete(data) {
        if (this.completed) return;

        const events = this.events?.complete || [];

        if (this.reward) {
            events.push(`character.points:player|${this.reward}^${this.id}`);
        }

        // add complete message event
        events.push(
            {
                code: 'world.message',
                data: {
                    type: 'message',
                    title: `${this.type} Milestone Completed!`,
                    subtitle: this.text,
                    className: 'mission-message',
                    message: [
                        this.reward ? `+${this.reward} Coins` : '', 
                        this.xp ? `+${this.xp} XP` : ''
                    ],
                    timeout: 6000
                }
            }        
        );

        EventManager.dispatch(events).then(() => {this.completed = true});
    }
}