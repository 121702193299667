import "./InvaderGameRouletteComponent.css";
import React from "react";
import Wheel from '../../Components/wheel';

class InvaderGameRouletteComponent extends React.Component {
  render() {
    return (
      <>
        <Wheel 
          items={this.props.chances} 
          image={this.props.selectedInvader.image}
          onStartSpin={() => this.props.onStartSpin()}
          onFinishSpin={(selectedItem) => this.props.onFinishSpin(selectedItem)}
        />
        <div className="wheel-legend">Invader Max Chances: {this.props.chances.filter((item) => item.winner).length}</div>
        <div className="wheel-legend">Click on the wheel and good luck Rebel!</div>
      </>
    );
  }
}

export default InvaderGameRouletteComponent;