import { EventManager } from "../services/EventManager";

export const MissionStates = {
    pending: 0,
    progress: 1,
    completed: 2
}

export class Mission {
    constructor(id, props) {
        this.id = id;
        this.state = MissionStates.pending;
        this.title = props.title || id;
        this.subtitle = props.subtitle || '';
        this.message = props.message || '';
        this.reward = props.reward || 0;
        this.xp = props.xp || 0;
        this.autoStart = props.autoStart;
        this.onMissionUpdate = props.onMissionUpdate || (() => {});

        this.events = props.events || {};
    }

    start(data) {
        const events = this.events?.start || [];
        this.state = MissionStates.progress;

        this.onMissionUpdate(this);

        // add start message event
        events.push(
            {
                code: 'world.message',
                data: {
                    type: 'message',
                    title: this.title,
                    subtitle: this.subtitle,
                    className: 'mission-message',
                    message: [
                        this.message,
                        this.reward ? `+${this.reward} Coins` : '', 
                        this.xp ? `+${this.xp} XP` : ''
                    ],
                    timeout: 10000
                }
            }        
        );

        EventManager.dispatch(events);
    }

    complete(data) {
        const events = this.events?.complete || [];

        if (this.reward) {
            events.push(`character.points:player|${this.reward}^${this.id}`);
        }

        // add complete message event
        events.push(
            {
                code: 'world.message',
                data: {
                    type: 'message',
                    title: this.title,
                    subtitle: 'Mission Completed!',
                    className: 'mission-message',
                    message: [
                        this.reward ? `+${this.reward} Coins` : '', 
                        this.xp ? `+${this.xp} XP` : ''
                    ],
                    timeout: 6000
                }
            }        
        );

        EventManager.dispatch(events)
            .then(() => {
                this.state = MissionStates.completed;
                this.onMissionUpdate(this);
            });
    }
}