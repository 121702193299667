import "./SideMenu.css";

import React from "react";
import { Badge } from "react-bootstrap";

class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
      show: props.show
    }
  }

  componentDidMount() {
  }

  showMenu() {
    this.setState({show: true});
  }

  render() {
    return (
        <ul className={"menu-list" + (this.state.show ? " is-nav-open" : "")}>
            {
              this.state.items.map(item => 
                !item.hidden &&
                <this.MenuEntry 
                  key={item.index} 
                  item={item} 
                  onMenuClick={(menu) => this.props.onMenuClick(menu)}>
                </this.MenuEntry>)
            }
        </ul>
    );
  }

  getMenu(index) {
    return this.state.items.find(item => item.index === index);
  }

  MenuEntry = (props) => {
    const pending = props.item.pendingFcn ? props.item.pendingFcn() : 0;
    return (
        <li className="menu-entry" key={props.item.index.toString()} onClick={() => props.onMenuClick(props.item)}>
          <img src={require("../css/images/menu/" + props.item.image)} alt="" title={props.item.name}></img>
          {
            pending > 0 &&
            <Badge className="pending" pill bg="danger">{pending}</Badge>
          }
        </li>
    );
  }
}

export default SideMenu;