import "./StatusComponent.css";
import React from "react";
import { AppContext } from "AppContext";

class StatusComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  update() {
    this.forceUpdate();
  }

  render() {
    return (
        <div className="status">
          <StatusEntry icon={require("./../../css/images/icons/points.png")} title="Points" value={AppContext.points}></StatusEntry>
          <StatusEntry icon={require("./../../css/images/icons/quality.png")} title="Score" value={AppContext.score}></StatusEntry>
          <StatusEntry icon={require("./../../css/images/icons/tickets.png")} title="Chances" value={AppContext.chances}></StatusEntry>
          <StatusEntry icon={require("./../../css/images/icons/level-up.png")} title="Level Up Points" value={AppContext.levelup}></StatusEntry>
        </div>
    );
  }
}

class StatusEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="entry">
        <img className="icon" src={this.props.icon} title={this.props.title} alt=""></img>
        <span className="value">{this.props.value || 0}</span>
      </div>
    );
  }
}

export default StatusComponent;