import { useEffect, useState } from "react"
import { GameWorldTime } from "./GameWorldTime"
import { ProgressBar } from "react-bootstrap"

export const GameSelectorPlayer = (props = {}) => {
  const [showSelector, setShowSelector] = useState(false)
  const [xpUpdated, setXPUpdated] = useState(false)
  const [levelup, setLevelUp] = useState(false)

  useEffect(() => {
    setXPUpdated(true);
    setTimeout(() => setXPUpdated(false), 1000);

    setLevelUp(props.status?.levelup);
    setTimeout(() => setLevelUp(false), 700);
  }, [props.status]);  

  if (props.isHidden)
    return

  return (
    <>
      <div className={`profile world-profile profile-${props.mode}`}>
        <div className="profile-picture" onClick={async () => setShowSelector(!props.onShowSelector || await props.onShowSelector())}>
          <img src={props.player?.url || (props.player?.metadata?.external_url + ".png")} alt=""></img>
        </div>
        <div className="profile-data">
          <div className="nickname">{props.profile?.nickname} {props.profile?.rank}</div>
          <div>{props.player?.metadata?.name}</div>
          {
            props.player?.metadata?.level &&
            <div className="profile-level">Level {props.player?.metadata?.level} - {props.player?.class}</div>
          }
          {
            props.energy !== undefined && 
            <ProgressBar className={'profile-game-energy'} 
              now={props.energy} 
              label={`${Math.floor(props.energy).toFixed(0)}%`} 
              variant={props.energy > 50 ? 'success' : props.energy > 20 ? 'warning' : 'danger'}>
            </ProgressBar>
          }
          {props.status?.level && <div className={`profile-game-level ${levelup ? 'profile-game-level-updated' : ''}`} title={`Level ${props.status.level}`}>{props.status.level || 1}</div>}
          <GameWorldTime></GameWorldTime>
          {props.status?.xp !== undefined && <div className={`profile-game-xp ${xpUpdated ? 'profile-game-xp-updated' : ''}`}>XP {props.status.xp}</div>}
        </div>
      </div>
      {
        showSelector &&
        props.component &&
        <div className="world-selector" onClick={() => setShowSelector(false)}>
          <props.component tokens={props.tokens} costumes={props.costumes} onTokenClick={(token) => props.onTokenSelected && props.onTokenSelected(token)}/>
        </div>
      }
    </>
  )
}