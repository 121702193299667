import { EventManager, registerEvents } from "./EventManager";
import { GameObjectSprite } from "../core/GameObjectSprite";
import { Map } from "../core/Map";
import { Mission } from "../core/Mission";
import { Overworld } from "../core/Overworld";
import { Character } from "../core/Character";
import { Utils } from "../Utils";
import { Cutscence } from "../core/Cutscene";
import { Camera } from "../core/Camera";
import { GameServer } from "./GameServer";
import { iethContract } from "Landing";
import { GameObject } from "../core/GameObject";
import { Action } from "../core/Action";
import { wait } from "@testing-library/user-event/dist/utils";
import { Milestone } from "../core/Milestone";
import { AudioManager } from "./AudioManager";
import { Market } from "../core/Market";
import { Skin } from "../core/Skin";

export class Loader {
    constructor(module) {
        this.module = module;
        this.path = 'data/games/' + module + '/';
        this.loading = true;
        this.players = [];
        this.mapPrevious = null;
        this.server = null;
        this.images = {};
        this.imagesLoader = {};
        this.missionsInProgress = 0;

        this.assetCreator = {
            Character: (id, props) => this.createCharacter(id, props),
            Map: (id, props) => this.createMap(id, props),
            MapSection: (id, props) => props,
            GameObject: (id, props) => GameObject.create(GameObjectSprite, id, props, this.objects[props.parent]),
            Mission: (id, props) => new Mission(id, {...props, onMissionUpdate: (m) => this.onMissionUpdate(m)}),
            Milestone: (id, props) => new Milestone(id, props),
            Cutscene: (id, props) => new Cutscence(id, props),
            Action: (id, props) => new Action(id, {...props, loader: this}),
            Market: (id, props) => new Market(id, props),
            Skins: (id, props) => new Skin(id, props),
            Contracts: (id, props) => props
        }

        this.audio = new AudioManager(this);
        this.contracts = {};

        // callbacks
        this.onDefLoaded = this.onDefLoaded || (() => {});
        this.onGameInitiated = this.onGameInitiated || (() => {});
        this.setWorldMessage = this.setWorldMessage || (() => {});
        this.setMarket = this.setMarket || (() => {});
        this.onPointsAwarded = this.onPointsAwarded || (() => {});
        this.onItemAdded = this.onItemAdded || (() => {});
        this.onItemRemoved = this.onItemRemoved || (() => {});
        this.onPlayerStatusChanged = this.onPlayerStatusChanged || (() => {});
        this.onConnectedPlayersChanged = this.onConnectedPlayersChanged || (() => {});
        this.onOption = this.onOption || (() => {});
        this.onUpdate = this.onUpdate || (() => {});
    }

    async load() {
        // load main definitions
        await this.loadDef();

        // load reactions
        this.reactions = this.def.reactions || {};
        this.reactions.map = this.reactions.map || {};
        this.loadGameImage(this.reactions.imageFile)
            .then(image => this.reactions.image = image);

        // load assets

        // objects
        for (const o of this.def.objects || []) {
            this[`object.${o}`] =
                await this.loadAssets(`objects`, 'GameObject', `.${o}`)
                    .then((data) => Object.keys(data).map(key => key));
        }

        if (!this.def.objects || this.def.objects.length === 0) {
            await this.loadAssets('objects', 'GameObject');
        }

        await this.loadAssets('characters', 'Character');
        await this.loadAssets('sections', 'MapSection');
        await this.loadAssets('maps', 'Map');
        await this.loadAssets('missions', 'Mission');
        await this.loadAssets('milestones', 'Milestone');
        await this.loadAssets('actions', 'Action');
        await this.loadAssets('markets', 'Market');
        await this.loadAssets('skins', 'Skins');
        await this.loadAssets('contracts', 'Contracts');

        // init audio manager
        this.audio.muted = this.def.muted;
        this.audio.init();

        this.loading = false;
    }

    loadDef() {
        return this.getGameAsset('def')
            .then(response => response.json())
            .then(data => {
                this.def = data;
                this.onDefLoaded(this.def);
            });
    }

    exit() {
        this.server.close();
        this.world.destroy();
    }

    getGameAsset(asset) {
        return iethContract.api.call('get', `games/${this.module}/assets/${asset}`);
    }

    getGameImage(image) {
        return iethContract.api.call('get', `games/${this.module}/images?image=${image}`);
    }

    getGameAudio(audio) {
        return iethContract.api.call('get', `games/${this.module}/audio?audio=${audio}`);
    }

    getGameData(object) {
        return iethContract.api.call('get', `games/${this.module}/data?object=${object}`).then(response => response.json());
    }

    loadGameImage(image) {
        return new Promise((resolve, reject) => {
            if (!image || (image && image.substring(0, 4).toLowerCase() === 'http')) {
                resolve(image);
                return;
            }

            // if image already exists in repository, use it!
            if (this.images[image]) {
                //console.log(image, 'cached!');
                resolve(this.images[image]);
                return;
            }

            // if image is loading, wait until its finished
            // TODO: review this code...
            if (this.imagesLoader[image]) {
                //console.log(image, 'delay loaded!');
                wait(500).then(() => this.images[image] && resolve(this.images[image]));
                return;
            }

            this.getGameImage(image)
                .then((res) => {
                    res.blob().then((blob) => {
                        //console.log(image, 'loaded!');
                        // add url object to repository
                        this.images[image] = URL.createObjectURL(blob);
                        resolve(this.images[image]);
                    })
                });
        })
    }

    loadAssets(asset, className, sufix) {
        return this.getGameAsset(`${asset}${(sufix || '')}`)
            .then(response => response.json())
            .then(data => {this.createAssets(asset, className, data); return data;})
            .catch((err) => console.log(`cannot find ${this.path}${asset}${(sufix || '')}.json`, err));
    }

    createAssets(asset, className, data) {
        this[asset] = this[asset] || {};

        Object.keys(data).forEach(key => {
            // image loader
            data[key].loadImage = (image) => this.loadGameImage(image);
            this[asset][key] = this.assetCreator[data[key].className || className](key, data[key]);
        });
    }

    getCharacter(id) {
        return this.characters[id];
    }

    deleteCharacter(id) {
        delete this.characters[id];
    }

    addCharacter(id, props) {
        // only create the character if it doesn't exists
        if (!this.characters[id]) {
            this.characters[id] = this.createCharacter(id, props);
        }
        
        return this.characters[id];
    }

    createCharacter(id, props) {
        const character = GameObject.create(Character, id, props, this.characters[props.parent]);

        if (character.isPlayerControlled) {
            this.players.push(character);
        }

        return character;
    }

    getObject(id) {
        return this.objects[id];
    }

    createMap(id, props) {
        props.json = JSON.stringify(props);

        if (props.parent) {
            const parent = this.maps[props.parent];

            if (parent && parent.json) {
                const parentProps = JSON.parse(parent.json);
                props = Object.assign(parentProps, props);
            }
        }

        const loader = this;

        let objectsState = {};

        props.gameObjects = {};
        props.characters = props.characters || {};
        props.objects = props.objects || {};

        // create characters
        Object.keys(props.characters).forEach(id => {
            if (props.characters[id].hidden) return true;
            
            objectsState[id] = {...this.characters[id].context || {}, ...props.characters[id]};

            props.gameObjects[id] = this.characters[id];
            props.characters[id] = this.characters[id];
        })

        let map = new Map(id, props);

        // create camera object
        this.def.camera = this.def.camera || {};
        this.def.camera.tracking = this.def.camera.tracking || ['', ''];
        const [className, asset] = this.def.camera.tracking;

        if (className && this[className]) {
            map.camera = new Camera('_camera_',
                {
                    trackingObject: this[className][asset],
                    data: {
                        debug: this.def.camera.debug
                    }
                });
        }
        
        // load cutscenes for map
        this.loadAssets('cutscenes.' + id, 'Cutscene');

        map.onInit = (world) => {
            // clean up DOM elements
            map.charactersDOM = {};
            world.onMapInit && world.onMapInit();
            
            // initial positions
            Object.keys(objectsState).forEach(key => {
                const state = objectsState[key] || {};
                const position = state.positions && state.positions[loader.mapPrevious] ? state.positions[loader.mapPrevious] : state.position || {};
                const object = map.gameObjects[key];

                if (position?.random && map.width && map.height) {
                    position.x = Utils.randomNumber(2, map.width);
                    position.y = Utils.randomNumber(2, map.height);

                    // TODO:
                    // check for walls!
                } else {
                    const m = map.markers[state.marker];
                    position.x = m ? m.x : position.x
                    position.y = m ? m.y : position.y;
                }

                if (object) {
                    object.x = position ? Utils.widthGrid(position.x) : object.x;
                    object.y = position ? Utils.widthGrid(position.y) : object.y;
                    object.state = !position ? 'hidden' : object.state;

                    object.state = state.state || object.state;
                    object.context = {...object.context, ...state};
                }

                // player initial position (saved)
                if (object === world.player && world.player.setInitialStatus) {
                    world.player.setInitialStatus(map);
                    world.player.setInitialStatus = null;
                }

                object.init();
            });

            // map title
            loader.setWorldMessage({
                type: 'title',
                title: map.title,
                subtitle: map.subtitle
            });

            // add player DOM element
            if (world.player != null) {
                Object.keys(objectsState).forEach(key => {
                    const object = map.gameObjects[key];
                    if (object instanceof Character) {
                        map.addCharacterElement(object);
                    }
                });
            }

            setTimeout(() => map.setHoverSection(this.world.player.x, this.world.player.y), 1000);
        }

        return map;
    }

    createWorld(element, map, user) {
        return new Promise((resolve, reject) => {
            this.world = new Overworld({
                element: element,
                loader: this
            });
          
            // main player
            this.world.player = this.players.length > 0 ? this.players[0] : null;
    
            if (this.world.player != null) {
                this.world.player.wallet = user.wallet;
                this.world.player.name = user.nickname;
            }
    
            // game server connection
            this.server = new GameServer({ 
                url: this.def.server || process.env.REACT_APP_API_URL,
                world: this.world
            });
            
            this.server.connect({ 
                map: map || this.def.map,
                game: this.module
            }).then((status) => {
                // load server player status
                status = status || {};
                status.status = status.status || {};
                status.inventory = status.inventory || [];
                status.missions = status.missions || {};
                status.milestones = status.milestones || [];
                status.xp = status.xp || 0;
                status.level = status.level || 1;
                status.energy = status.energy || 100;

                // update mission states
                Object.keys(status.missions).forEach((id) => {
                    if (this.missions[id]) {
                        this.missions[id].state = status.missions[id].state;
                    }
                });

                this.onMissionUpdate();

                // update milestones
                status.milestones.forEach(id => {
                    if (this.milestones[id]) {
                        this.milestones[id].completed = true;
                    }
                });

                // hide elements from inventory
                Object.keys(status.inventory).forEach((id) => {
                    if (this.objects[id]) {
                        this.objects[id].state = 'hidden';
                    }
                });

                // check existance of status.map
                if (!this.maps[status.status.map]) {
                    status.status.map = null;
                    status.status.position = null;
                }

                // main map
                this.setMap(status.status.map || map || this.def.map);

                // character last position, inventory items and skins    
                if (this.world.player) {
                    if (status.status.position) {
                    this.world.player.setInitialStatus = (map) => {
                        const defX = this.world.player.x;
                        const defY = this.world.player.y;

                        map.removeObject(this.world.player);
                        this.world.player.x = status.status.position[0];
                        this.world.player.y = status.status.position[1];
                        this.world.player.orientation = status.status.orientation;

                        // validations
                        // 1. round position to Utils.cellSize
                        this.world.player.x = Math.round(this.world.player.x / Utils.cellSize) * Utils.cellSize;
                        this.world.player.y = Math.round(this.world.player.y / Utils.cellSize) * Utils.cellSize;
    
                        // 2. if position is on wall -> send to map default position
                        if (map.walls[`${this.world.player.x},${this.world.player.y}`]) {
                            this.world.player.x = defX;
                            this.world.player.y = defY;
                        }

                        map.addObject(this.world.player);
                        setTimeout(() => map.setHoverSection(this.world.player.x, this.world.player.y), 1000);
                    }
                    }

                    this.world.player.addItems(status.inventory);

                    // load character costumes from contracts
                    Object.keys(this.skins).forEach(key => {
                        this.skins[key].init(this.world.player, this.contracts);
                    });
                }

                // register events
                registerEvents(this);

                // auto start missions
                Object.keys(this.missions).forEach((key) => {
                    this.missions[key].autoStart && EventManager.dispatch(`mission.start:${key}`);
                });

                resolve({world: this.world, status});
            });
        });
    }

    onMissionUpdate(mission) {
        this.missionsInProgress = Object.keys(this.missions).map(m => this.missions[m]).filter(m => m.state === 1).length;
    }

    setMap(mapId, marker) {
        // find new map
        const newMap = this.maps[mapId];

        if (!newMap) {
            return;
        }

        if (this.world.map) {
            this.mapPrevious = this.world.map.id;

            // save previous map state
            this.world.map.save();

            // unmount old map objects
            this.world.map.unMountObjects();

            // emit map.leave
            this.server.emit('map.leave', this.world.map.id);
            this.world.map.onLeave();
        }

        this.audio.music(newMap.music);

        newMap.init(this.world);
        this.world.map = newMap;
        setTimeout(() => this.world.map.onEnter(), 500);

        // emit map.enter
        this.server.emit('map.join', newMap.id);

        if (!this.mapPrevious) {
            this.mapPrevious = newMap.id;            
        }

        // set player position
        if (marker && this.world.player) {
            this.world.player.unMount(newMap);

            // find marker in map
            const m = newMap.markers[marker];
            const position = m ? `${m.x},${m.y}` : marker;

            // player position
            const [x, y] = Utils.asNumericCoords(position);
            this.world.player.x = x * Utils.cellSize;
            this.world.player.y = y * Utils.cellSize;

            this.world.player.mount(newMap);
        }
    }

    destroy() {
        this.audio && this.audio.stop();
        EventManager.clear();
    }
}