import "./InvaderGameModal.css";
import React from "react";
import {Container, Col, Row, Modal} from 'react-bootstrap';

class InvaderGameModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }  

  render() {
    if (!this.props.show || !this.props.selectedInvader || !this.props.selectedInvader.game)
      return null;

    return (
        <Modal
          show={this.props.show}
          onHide={this.props.onClose}
          dialogClassName="invader-game-modal"
          keyboard={false}
          centered>
          <Modal.Body>
              <Container fluid>
                  <Row>
                    <Col>
                    { 
                      React.createElement(this.props.selectedInvader.game, {
                        chances: this.props.chances,
                        onStartSpin: () => this.props.onStartSpin(),
                        onFinishSpin: (selectedItem) => this.props.onFinishSpin(selectedItem),
                        selectedInvader: this.props.selectedInvader
                      })
                    }
                    </Col>
                  </Row>
            </Container>
          </Modal.Body>
        </Modal>
    );
  }
}

export default InvaderGameModalComponent;