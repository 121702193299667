import { io } from 'socket.io-client';
import { EventManager } from './EventManager';

export class GameServer {
    constructor(props) {
        this.world = props.world;
        this.loader = this.world.loader;
        this.url = props.url;
        this.socket = null;
        this.status = {
            map: null,
            position: null
        }
    }

    connect(data) {
        return new Promise((resolve, reject) => {
            if (!this.url || !this.world || !this.world.player) {
                resolve({ status: {}, players: [] });
            };

            this.socket = io(this.url);
    
            // send handshake
            this.socket.emit('player.hello', { 
                wallet: this.world.player.wallet,
                game: data.game,
                map: data.map
            });

            // Debug
            // this.socket.onAny((eventName, ...args) => console.log(eventName, args));

            // wait to receive handshake and player last status (map, skin and position)
            this.socket.on('server.hello', (playerStatus) => {
                this.initSendStatus();
                resolve(playerStatus);
            });

            // player entered map
            this.socket.on('player.joined.map', (id, p) => {
                p.status = p.status || {};
                const playerId = 'player-' + id;

                const player = this.loader.addCharacter(playerId, { 
                    parent: 'rebel',
                    src: p.status.skin || 'characters/people/rebel.png'
                });

                player.isOverlapped = true;
                player.name = p.name || id;
                player.isRemotePlayer = true;

                this.world.map.addObject(player);
                this.world.map.addCharacterElement(player);
            });

            // player in map
            this.socket.on('map.players', (players) => {
                // create existing players
                players.map((p) => {
                    p.status = p.status || {};
                    const playerId = 'player-' + p.id;

                    const player = this.loader.addCharacter(playerId, { 
                        parent: 'rebel',
                        src: p.status.skin || 'characters/people/rebel.png'
                    });
    
                    player.x = p.status.position ? p.status.position[0] : 0;
                    player.y = p.status.position ? p.status.position[1] : 0;
                    player.orientation = p.status.orientation;
                    player.isOverlapped = true;
                    player.name = p.status.name || p.id;
                    player.isRemotePlayer = true;

                    this.world.map.addObject(player);
                    this.world.map.addCharacterElement(player);

                    return player;
                });
            });

            // player leaved map
            this.socket.on('player.leaved.map', (id) => {
                const playerId = 'player-' + id;
                const player = this.loader.getCharacter(playerId);

                if (player) {
                    this.world.map.removeCharacterElement(player);
                    this.world.map.removeObject(player);
                    this.loader.deleteCharacter(playerId);
                }
            });

            // players connected changed
            this.socket.on('player.connected.changed', (players) => {
                this.world.players = players;
                this.world.loader.onConnectedPlayersChanged && this.world.loader.onConnectedPlayersChanged(players.length);
            });

            // player exited
            this.socket.on('player.exited', (id) => {
                const playerId = 'player-' + id;
                const player = this.loader.getCharacter(playerId);

                if (player) {
                    this.world.map.removeCharacterElement(player);
                    this.world.map.removeObject(player);
                    this.loader.deleteCharacter(playerId);
                }
            });

            // player status
            this.socket.on('player.status', (status) => {
                const playerId = 'player-' + status.id;
                let player = this.loader.getCharacter(playerId);

                if (!player) return;
                
                player.x = status.status.position[0];
                player.y = status.status.position[1];
                player.deltaX = status.status.deltaX || player.deltaX;
                player.deltaY = status.status.deltaY || player.deltaY;
                player.orientation = status.status.orientation;
                player.name = status.status.name;
                player.chat = status.status.chat;
                player.energy = status.status.energy;
                player.state = status.status.state;

                if (status.status.skin && status.status.skin !== player.sprite.spritesheet) {
                    player.sprite.spritesheet = status.status.skin;
                }

                if (status.status.effects && Object.keys(player.effects).join('|') != status.status.effects.visible) {
                    status.status.effects.def = status.status.effects.def || [];

                    status.status.effects.def.forEach(effect => {
                        if (!player.effects[effect.id]) {
                            const _e = {};
                            _e[effect.id] = player.createEffect(effect);
            
                            player.effects = {..._e, ...player.effects};
                        }
    
                        player.effects[effect.id].visible = true;
                    });

                    status.status.effects.hidden.split('|').forEach(hide => {
                        if (player.effects[hide]) player.effects[hide].visible = false;
                    })
                }

                player.onUpdatePosition && player.onUpdatePosition();
            });

            // my status
            this.socket.on('me.status', (status) => {
                // update player status level and xp after emiting player.xp
                this.loader.onPlayerStatusChanged({xp: status.xp || 0, level: status.level || 1, levelup: status.levelup, energy: status.energy});
            });

            // market callbacks
            this.socket.on('market.transaction', (transaction, id, item, price) => {
                if (!this.loader.world.map) return;

                const market = this.loader.world.map.getMarket(id);
                if (!market) return;

                EventManager.dispatch(`character.points:player|${price}`);

                if (transaction === 'buy') {
                    if (item.count === 0) this.loader.world.player.removeItem(item);
                    else this.loader.world.player.addItem(item);
                    this.loader.onItemRemoved(item);
                } else if (transaction === 'sell') { 
                    this.loader.world.player.addItem(item);
                    this.loader.onItemAdded(item);
                }

                market.update();
            });
        })
    }

    initSendStatus() {
        // send player status to server
        setInterval( () => {
            const player = this.world.player;

            const status = {
                map: this.world.map.id,
                position: player.getPosition(),
                deltaX: player.deltaX,
                deltaY: player.deltaY,
                orientation: player.orientation,
                state: player.state,
                skin: player.sprite.spritesheet,
                name: player.name,
                chat: player.chat,
                energy: player.energy,
                effects: {
                    visible: Object.keys(player.effects).filter(effect => player.effects[effect].visible).join('|'),
                    hidden: Object.keys(player.effects).filter(effect => !player.effects[effect].visible).join('|'),
                    def: Object.keys(player.effects).filter(effect => player.effects[effect].visible).map(effect => player.effects[effect].def)
                }
            }

            if (this.status.skin === status.skin &&
                this.status.orientation === status.orientation && 
                this.status.chat === status.chat &&
                this.status.state === status.state &&
                this.status.effects.visible === status.effects.visible &&
                this.status.deltaX === status.deltaX &&
                this.status.deltaY === status.deltaY &&
                JSON.stringify(this.status.position) === JSON.stringify(status.position))
                return;

            this.status.skin = status.skin;
            this.status.position = status.position;
            this.status.deltaX = status.deltaX;
            this.status.deltaY = status.deltaY;
            this.status.orientation = status.orientation;
            this.status.chat = status.chat;
            this.status.energy = status.energy;
            this.status.state = status.state;
            this.status.effects = status.effects;

            this.socket.emit('player.status', status);
        }, 10);
    }

    emit(msg, data) {
        if (!this.socket) return;

        this.socket.emit(msg, data);
    }

    close() {
        if (!this.socket) return;

        this.socket.close();
    }    
}