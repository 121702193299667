import { GameObject } from "./GameObject";

export class Camera extends GameObject {
    constructor(id, props) {
        super(id, props);

        this.trackingObject = props.trackingObject;
    }

    set trackingObject(object) {
        if (this._trackingObject) this._trackingObject.isCameraTracked = false;

        this._trackingObject = object;
        
        object.isCameraTracked = true;
    }

    get trackingObject() {
        return this._trackingObject;
    }

    moveTo(object, speed) {
        return new Promise((resolve, reject) => {
            this._moveToObject = object;
            this._moveToSpeed = speed;
            this.onNewTrackingObject = () => resolve();
        });
    }

    draw(ctx, cameraObject) {
        super.draw(ctx, cameraObject);
    }

    update(state) {
        if (this._moveToObject) {
            // distance to _moveToObject
            const xDistance = this._moveToObject.x - this.x;
            const yDistance = this._moveToObject.y - this.y;

            this.x = xDistance !== 0 ? this.x + this._moveToSpeed * (xDistance > 0 ? 1 : -1) : this.x;
            this.y = yDistance !== 0 ? this.y + this._moveToSpeed * (yDistance > 0 ? 1 : -1) : this.y;

            if (this._moveToObject.x === this.x && this._moveToObject.y === this.y) {
                this.trackingObject = this._moveToObject;
                this._moveToObject = null;

                if (this.onNewTrackingObject) {
                    this.onNewTrackingObject();
                }
            } 
            
        } else {
            this.x = this._trackingObject.x;
            this.y = this._trackingObject.y;
        }
    }
}