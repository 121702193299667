import { EventManager } from "../services/EventManager";
import { GameObjectSprite } from "./GameObjectSprite";
import { Sprite } from "./Sprite";
import { Utils } from "../Utils";

export class Character extends GameObjectSprite {
    constructor(id, props) {
        super(id, props);

        this.items = {};
        this.steps = 0;
        this.idleTime = 0;
        this.idleTick = 0;
        this.recoveryTick = 0;
        this.isPlayerControlled = props.isPlayerControlled || false;
        this.name = props.name;
        this.directionUpdate = {
            'up': ['y', -1],
            'down': ['y', 1],
            'left': ['x', -1],
            'right': ['x', 1]
        }
        this.vision = props.vision || 0;
        this.isPlayerInVision = false;
        this.costumes = [];
        this.itemInUse = null;

        this.effects = {};
        Object.keys(props.effects || {}).forEach((key) => {
            const e = props.effects[key];
            e.id = key;

            this.effects[key] = this.createEffect(e);
        })

        if (!this.isPlayerControlled) {
            this.energy = 0;
            this.energyMax = 0;
        }
    }

    init() {
        this.context = this.context || {};
        this.context.events = this.context.events || {};
        this.context.automated = this.context.automated || {};
        this.vision = this.context.vision || 0;
        this.orientation = this.context.orientation || 'left';
        this.startAutomatedBehaviour(true, this.context.automated.action);
    }
    
    draw(ctx, cameraObject) {
        if (this.sprite) {
            Object.keys(this.effects).forEach((key) => {
                this.effects[key].visible && this.effects[key].sprite.draw(ctx, cameraObject);
            })
        }

        super.draw(ctx, cameraObject);
    }

    update(state) {
        if (!this.map) return;

        super.update(state);

        if (!this.isPlayerControlled) {
            // detect player
            if (this.vision) {
                const v = Utils.getVisionBox(this.x, this.y, this.vision);
                const oldPlayerInVision = this.isPlayerInVision;

                this.isPlayerInVision = 
                    v.t <= this.map.world.player.y && 
                    v.b >= this.map.world.player.y &&
                    v.l <= this.map.world.player.x &&
                    v.r >= this.map.world.player.x;

                if (this.isPlayerInVision !== oldPlayerInVision) {
                    // trigger events!
                    //console.log(this.id, this.isPlayerInVision ? 'Player entering vision!!' : 'Player leaving vision');
                    if (this.context?.events && this.context?.events[`player.${this.isPlayerInVision ? 'enter' : 'leave'}`]) {
                        EventManager.dispatch(this.context?.events[`player.${this.isPlayerInVision ? 'enter' : 'leave'}`], { this: this.id });
                    }
                }
            }
        }

        if (this.movingProgressRemaining > 0) {
            this.updatePosition(state);
            return;
        } 

        if (this.isPlayerControlled && state.arrow) {
            if (this.energy > 0) {
                this.startBehaviour(state, { type: 'walk', direction: state.arrow });
            } else {
                EventManager.dispatch('character.dialog:player|I’m feeling really drained and need a moment to recover.^3000');
            }
        }

        // idle?
        if (!state.arrow) {
            this.idleTime = this.idleTime || (new Date()).getTime();
            const difference = (new Date()).getTime() - this.idleTime;

            if (this.idleTick !== Math.round(difference / 1000)) {
                this.idleTick = Math.round(difference / 1000);
                this.idle();
            }
        }
        
        this.updateSprite();
    }

    idle() {
        // this.isPlayerControlled && console.log(`${this.idleTick % this.energyRecoverTime}`)

        if (this.idleTick % this.energyRecoverTime === 0 && this.energy < this.energyMax) {
            // recover
            this.recoveryTick = this.idleTick - this.recoveryTick;

            this.energy += (this.energyRecoverValue * (this.recoveryTick / this.energyRecoverTime));
            this.energy = this.energy > this.energyMax ? this.energyMax : this.energy;
            
            this.recoveryTick = this.idleTick;
        }
    }

    restoreEnergy(energy, time) {
        this.energy = energy || this.energyMax;

        if (!time) return;

        const difference = Math.round(((new Date()).getTime() - time) / 1000);

        if (difference < this.energyRecoverTime) return;

        this.energy += (this.energyRecoverValue * (difference / this.energyRecoverTime));
        this.energy = this.energy > this.energyMax ? this.energyMax : this.energy;
    }

    behaviours = {
        walk: (state, behaviour) => {
            if (!this.isFloating && state.map.isSpaceBlocked(this.x, this.y, this.direction)) {
                this.behaviours.finish();
                return;
            }
    
            !this.isOverlapped && state.map.moveWall(this.x, this.y, this.direction);

            // restart idle time
            this.idleTime = null;
            this.idleTick = this.recoveryTick = 0;

            this.steps++;
            this.energy -= this.energyStep;
            this.energy = this.energy < 0 ? 0 : this.energy;

            this.movingProgressRemaining = Utils.cellSize;
            this.speed = this.itemInUse?.modifiers?.speed || state.speed || this.speed || 1;
            this.updateSprite();
        },
        stop: (state, behaviour) => {
            this.movingProgressRemaining = 0;
            this.updateSprite();
        },
        finish: () => {
            this.currentBehaviour && this.currentBehaviour.callbackFcn && this.currentBehaviour.callbackFcn(this.currentBehaviour);
            this.currentBehaviour = {};
        }
    }

    automated = {
        events: ['up', 'down', 'left', 'right', 'idle', 'talk'],
        stop: (callback) => {},
        "goto": (callback, blocked, destinationFcn, reachedFcn ) => {
            const destination = destinationFcn ? destinationFcn() : this.context?.destination;

            if (!this.map || this.mustStopAutomatedBehaviour()) return;

            if (!destination) return;
            blocked = blocked || {};
            
            let [x, y] = Utils.asNumericCoords(destination);
            x *= Utils.cellSize;
            y *= Utils.cellSize;

            let distanceX = (this.x - x) / Utils.cellSize;
            let distanceY = (this.y - y) / Utils.cellSize;
            
            let direction =     distanceX > 0 && !blocked['left'] ? 'left' : 
                                distanceX < 0 && !blocked['right'] ? 'right' : 
                                distanceY > 0 && !blocked['up'] ? 'up' : 
                                distanceY < 0 && !blocked['down'] ? 'down' : null;

            // check for player at position (collision)
            if (direction && this.map.isPlayerAt(this.x, this.y, direction)) {
                reachedFcn && reachedFcn();
            }

            // destination reached (if nothing is blocked?)
            if (!direction) {
                // if no blocking or destination is blocked, reached!
                if (Object.keys(blocked).length === 0) {
                    //console.log(`${this.id} - destination reached!`);
                    reachedFcn && reachedFcn();
                    return;
                }

                const d = ['left', 'up', 'right', 'down'];
                let retries = 0;
                direction = d[Utils.randomNumber(0, 3)];

                while (blocked[direction] && retries <= 5) {
                    direction = d[Utils.randomNumber(0, 3)];
                    retries++;
                }

                // all blocked??
                if (!direction || blocked[direction]) {
                    //console.log(`${this.id} - all directions blocked!`);
                    reachedFcn && reachedFcn();
                    return;
                }
            }

            // check for space blocked
            if (this.map.isSpaceBlocked(this.x, this.y, direction)) {
                // change direction (how?)
                //console.log(`${this.id} - ${direction} blocked!`);
                blocked[direction] = true;
                setTimeout(() => this.automated.goto(callback, blocked, destinationFcn, reachedFcn), 1000);
                return;
            }

            const steps = direction === 'left' || direction === 'right' ? Math.abs(distanceX) : Math.abs(distanceY);
            //console.log(`${this.id} - GOTO: ${x} - ${y} - FROM ${this.x} - ${this.y} - ${direction} - ${steps}`);

            EventManager.dispatch(`character.behaviour:${this.id}::${steps}|walk^${direction}`)
                .then(() => setTimeout(() => this.automated.goto(callback, {}, destinationFcn, reachedFcn), 100));
        },
        "follow": (callback) => {
            if (!this.map || !this.map.world) return;
            this.automated.goto(callback, {}, 
                () => `${this.map?.world?.player?.x / Utils.cellSize},${this.map?.world?.player?.y / Utils.cellSize}`,
                () => {});
        },
        "attack": (callback) => {
            if (!this.map || !this.map.world || !this.map.world.player) return;

            this.map.world.loader.audio.music(this.context.music?.attack);

            this.vision *= 1.5;
            this.speed = 2;
            this.map.world.player.isUnderAttack = true;

            this.automated.goto(callback, {}, 
                () => `${this.map?.world?.player?.x / Utils.cellSize},${this.map?.world?.player?.y / Utils.cellSize}`,
                () => {
                    if (!this.map?.world?.player) return;

                    this.map?.world?.player.damage(this.context.damage || 5);

                    if (this.map.world.player.energy <= 10) {
                        // stop attack (emulate leave vision)
                        EventManager.dispatch(this.context?.events['player.leave'], { this: this.id });
                        return;
                    }
                });
        },
        "attack-stop": () => {
            this.vision /= 1.5;
            this.speed = 1;
            this.map.world.player.isUnderAttack = false;
            this.map.world.loader.audio.music(this.map.music);
        },
        "walk-around": (callback) => {
            // add extra automated events
            const events = [...this.automated.events, ...this.context?.automated.events || []]
            const event = events[Utils.randomNumber(0, events.length - 1)];
            const repeat = Utils.randomNumber(1, 10);
        
            if (event === 'idle') {
              // idle
              setTimeout(() => callback && callback(), repeat * 1000);
            } else if (event === 'talk') {
              // get character random phrase
              const phrases = this.context?.phrases || [];
        
              if (phrases.length === 0) {
                // no talking for this fellow
                // idle
                setTimeout(() => callback && callback(), repeat * 1000);
                return;
              }
        
              EventManager.dispatch(`character.talk:${this.id}|${phrases[Utils.randomNumber(0, phrases.length - 1)]}`)
                .then(() => setTimeout(() => callback && callback(), 2000));
            } else if (event === 'up' || event === 'down' || event === 'left' || event === 'right' ) {
                let direction = event;
                if (this.map && this.map.isSpaceBlocked(this.x, this.y, direction)) {
                    direction = event === 'up' ? 'down' :
                                event === 'down' ? 'up' :
                                event === 'left' ? 'right' :
                                event === 'right' ? 'left' : '';
                }

                EventManager.dispatch(Array.from({length: repeat}, (_, i) => `character.behaviour:${this.id}|walk^${direction}`))
                    .then(() => setTimeout(() => callback && callback(), 1000));
            } else {
                if (!this.context.events[event]) {
                    // invalid event -> idle
                    setTimeout(() => callback && callback(), 2000);
                    return
                }

                EventManager.dispatch(this.context.events[event], {this: this.id})
                    .then(() => setTimeout(() => callback && callback(), 1000));
            }
        }
    }

    startBehaviour(state, behaviour) {
        this.direction = behaviour.direction || this.orientation;
        this.orientation = behaviour.direction === 'left' ? 'left' : (behaviour.direction === 'right' ? 'right' : this.orientation);

        this.currentBehaviour = {...behaviour};

        const behaviourFcn = this.behaviours[behaviour.type];

        behaviourFcn && behaviourFcn(state, behaviour);

        // behaviour not handled
        !behaviourFcn && this.behaviours.finish();
    }

    mustStopAutomatedBehaviour() {
        if (!this.stopAutomaticBehaviour) return false;
        
        if (this.automated[`${this.automatedBehaviour}-stop`]) this.automated[`${this.automatedBehaviour}-stop`]();
        this.automatedBehaviour = null;

        //console.log(`${this.id} stopping automated behaviour ${automated}`);
        this.inAutomaticBehaviour = false;
        this.stopAutomaticBehaviour();
        return true;
    }

    startAutomatedBehaviour(restart, automated) {
        if (restart && this.inAutomaticBehaviour) {
            this.stopAutomaticBehaviour = () => {
                this.stopAutomaticBehaviour = null;
                this.startAutomatedBehaviour(false, automated);
            }
            return;
        }

        if (!automated || !this.automated[automated] || this.inAutomaticBehaviour) return;
        this.inAutomaticBehaviour = true;
        this.automatedBehaviour = automated;
    
        //console.log(`${this.id} starting automated behaviour ${automated}`);

        // make overlapped if has an automatic behaviour
        this.isOverlapped = true;
    
        const doBehaviour = (callback) => this.automated[automated] && this.automated[automated](callback);
        const doBehaviourCallback = () => {
          if (this.mustStopAutomatedBehaviour()) return;
          setTimeout(() => doBehaviour(() => doBehaviourCallback()), 500);
        };
    
        setTimeout(() => doBehaviour(() => doBehaviourCallback()), 1000);
    }

    updatePosition(state) {
        super.updatePosition(state);
        
        if (this.movingProgressRemaining > 0) {
            const [property, change] = this.directionUpdate[this.direction];
            this[property] += change * this.speed;
            this.movingProgressRemaining -= Math.abs(change * this.speed);

            this.isCameraTracked && this.map.camera.update();

            if (this.movingProgressRemaining === 0 && this.isPlayerControlled) {
                // stopped!

                // check leaving hotspot
                const {x, y} = Utils.previousPosition(this.x, this.y, this.direction);
                const oldSpot = state.map.isHotspot(x, y);

                if (oldSpot) {
                    EventManager.dispatch({
                        code: 'hotspot.leave',
                        character: this,
                        hotspot: oldSpot
                    });
                }

                // check entering hotspot 
                const newSpot = state.map.isHotspot(this.x, this.y);

                if (newSpot) {
                    EventManager.dispatch({
                        code: 'hotspot.enter',
                        character: this,
                        hotspot: newSpot
                    });
                }

                // check standing on object
                // pick pickable objects
                state.map.getObjectsAt(this.x, this.y)
                    .filter(o => o.isPickable && !o.isHidden)
                    .map(o => EventManager.dispatch(`character.inventory.add:player|${o.id}`));

                // set hover section
                state.map.setHoverSection(this.x, this.y);
            }
        }

        if (this.movingProgressRemaining === 0) {
            // round position
            this.x = Math.round(this.x / Utils.cellSize) * Utils.cellSize;
            this.y = Math.round(this.y / Utils.cellSize) * Utils.cellSize;

            // finish behaviour
            this.behaviours.finish();
        }

        this.onUpdatePosition && this.onUpdatePosition(state);
    }

    updateSprite() {
        super.updateSprite();

        if (this.isRemotePlayer && this.state) return;

        if (this.movingProgressRemaining > 0) {
            this.state = (this.itemInUse?.modifiers?.animation || 'walk') + (this.orientation ? '-' +  this.orientation : '');
            return;
        }

        this.state = (this.itemInUse?.modifiers?.animation || 'idle') + (this.orientation ? '-' +  this.orientation : '');
    }

    save(data) {
        super.save(data);
        
        data.items = this.items;
    }

    load(data) {
        super.load(data);

        this.items = data.items;
    }    

    addItems(items) {
        Object.keys(items).forEach((key) => this.addItem(items[key]));
    }

    addItem(item) {
        if (!item) return;
        item.count = item.count || 1;

        this.items[item.id] = item;
    }

    removeItem(item) {
        if (!item || item.count > 0) return;
        delete this.items[item.id];
    }

    talk(data) {
        this.chat = data.message;
        this.onUpdatePosition && this.onUpdatePosition();

        setTimeout(() => { 
            this.chat = null;
            data.onComplete && data.onComplete();
            this.onUpdatePosition && this.onUpdatePosition();
        }, data.timeout || 10000);        
    }

    damage(energy) {
        this.energy -= this.energy - energy < 0 ? this.energy : energy;

        // damage effect
        this.effect('damage', 200);
    }

    recover(energy) {
        this.energy += this.energy + energy > 100 ? (100 - this.energy) : energy;
    }

    effect(effect, timeout) {
        const e = this.effects[effect];

        if (!e) return;

        e.audio && this.map.world.loader.audio.effect(e.audio);
        e.visible = true;
        setTimeout(() => { e.visible = false; }, timeout);
    }

    createEffect(effect) {
        return {
            sprite: new Sprite({ 
                gameObject: this, 
                absolute: effect.absolute, 
                src: effect.src, 
                loader: this.props.loadImage, 
                spriteSize: this.props.spriteSize,
                animations: effect.animations,
                data: effect.data
            }),
            visible: effect.visible,
            audio: effect.audio,
            def: effect
        } 
    }

    use(item) {
        if (this.itemInUse) {
            // cleanup current effect
            const e = this.itemInUse?.modifiers?.effect;
            if (e && this.effects[e.id]) this.effects[e.id].visible = false;
        }

        this.itemInUse = this.itemInUse === item?.o ? null : item?.o;

        this.deltaX = this.itemInUse?.modifiers?.deltaX || this.props.deltaX || 0;
        this.deltaY = this.itemInUse?.modifiers?.deltaY || this.props.deltaY || 0;

        if (this.itemInUse && this.itemInUse?.modifiers?.effect) {
            // add effect
            const e = this.itemInUse?.modifiers?.effect;

            if (!this.effects[e.id]) {
                const _e = {};
                _e[e.id] = this.createEffect(e);

                this.effects = {..._e, ...this.effects};
            }

            this.effects[e.id].visible = true;
        }
    }

    onChangeState(state) {
        super.onChangeState(state);

        if (this.sprite) {
            Object.keys(this.effects).forEach((key) => {
                this.effects[key].visible && this.effects[key].sprite.onChangeState(state);
            })
        }
    }

    unMount(map) {
        // stop behaviours
        this.behaviours.stop();
        this.stopAutomaticBehaviour = () => { this.stopAutomaticBehaviour = null };
        this.mustStopAutomatedBehaviour();
    
        super.unMount(map);
    }
}