import "./MissionsComponent.css";
import React from "react";
import {lazy, Suspense} from "react";
import {iethContract} from "Landing";
import {CheckSquareFill, Square} from 'react-bootstrap-icons'
import {MissionsModal} from "./Missions/MissionsModal"

class MissionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showmodal: false,
      missions: []
    };

    this.modalRef = React.createRef();
  }

  async componentDidMount() {
    await this.loadMissions();
    this.setState({loaded: true});
  }

  loadMissions = async () => {
    return iethContract.loadMissions()      
      .then(async (data) => { 
        // building class
        data = data.filter(mission => (!this.props.buildingClass && !mission.class) || mission.class === this.props.buildingClass);

        // building level
        if (this.props.buildingLevel) {
          data.map(mission => mission.state = mission.level > 0 && mission.level > this.props.buildingLevel ? "disabled" : mission.state);
        }

        // sort missions
        data = data.sort((a, b) => {
          let result = a.id - b.id;

          if (a.state === 'pending' && b.state !== 'pending') {
            result = -1;
          } else if (a.state === 'disabled' && b.state !== 'disabled' && b.state !== 'pending') {
            result = -1;
          }

          return result;
        });

        this.setState({missions: data});
      })
      .catch((err) => console.error(err));    
  }

  onModalClick = async (mission) => {
    const CustomForm = lazy(() => import(`./Missions/${mission.params.form}`));

    this.modalRef.current.setState({
      title: mission.params.title,
      form: <Suspense fallback={<div>Loading...</div>}>
              <CustomForm/>
            </Suspense>,
      onClick: (e) => {
        e.preventDefault();
        this.setState({showmodal:false});
        this.onMissionFunction(mission, e.target.form);
      }
    });
    this.setState({showmodal:true});
  }
  
  onMissionExecute = async (mission) => {
    if (mission.type === 'component') {
      this.onMissionFunction(mission);
    } else if (mission.type === 'modal') {
      this.onModalClick(mission);
    } else if (mission.type === 'link') {
      this.onMissionComplete(mission).then(() => window.open(mission.params.url, '_blank').focus());
    } else if (mission.type === 'none') {
      this.onMissionComplete(mission);
    } else {
      console.log(mission);
    }
  }

  onMissionFunction = async (mission, data) => {
    const module = await import(`./Missions/${mission.params.component}`);

    if (typeof module[mission.params.method] === 'function') {
      module[mission.params.method](mission, data)
        .then((result) => {
            if (result.status === 200) {
                this.onMissionComplete(mission);
            }
        })
        .catch((err) => console.error(err));
    }
  }

  onMissionComplete = (mission) => {
    return iethContract.api.call('post', `wallets/${iethContract.userAddress()}/missions/complete`, {
      missionId: mission.id
    }).then(() => this.loadMissions());
  }

  render() {
    return (
        <div>
            {
              !this.props.hideTitle &&
              <h1>Missions</h1>
            }
              {
                this.state.loaded &&
                this.state.missions.length === 0 &&
                <>
                  <p>No missions available.</p>
                  <p>Stay alert for further instructions.</p>
                </>
              }
              {
                this.state.loaded &&
                this.state.missions &&
                this.state.missions.length > 0 &&
                <ul className="mission-list">
                {
                  this.state.missions.map((mission, index) => 
                    <MissionEntry 
                      key={index} 
                      index={index} 
                      mission={mission} 
                      onMissionExecute={(mission) => this.onMissionExecute(mission)}/>
                  )
                }
                </ul>
              }
          <MissionsModal 
            ref={this.modalRef}
            show={this.state.showmodal}
            onClose={() => {this.setState({showmodal: false});}}
            onClick={() => {}}>
          </MissionsModal>
        </div>
    );
  }
}

class MissionEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMissionClick = async (mission) => {
    if (mission.state !== 'pending' && !mission.alwaysOn)
      return;

    this.props.onMissionExecute(mission);
  }

  render() {
    return (
      <>
        <li className={"mission-entry mission-" + this.props.mission.state + (this.props.mission.alwaysOn ? " mission-alwayson" : "")} onClick={() => this.onMissionClick(this.props.mission)}
            key={this.props.index}>
              <div>
                { this.props.mission.completed && <CheckSquareFill className="mission-entry-icon completed "/> }
                { !this.props.mission.completed && <Square className="mission-entry-icon"/> }
                {this.props.mission.description} (+{this.props.mission.score})
              </div>
        </li>
      </>
    );
  }
}

export default MissionsComponent;