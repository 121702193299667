import { useEffect, useState } from "react";

export const GameWorldTime = (props = {}) => {
  const [ date, setDate ] = useState(new Date());

  useEffect(() => {
    setInterval(() => setDate(new Date()), 1000);
  }, []);

  return (
    <div className="world-time">{date.toUTCString().split(' ')[2]} {date.toUTCString().split(' ')[1]} - {date.toUTCString().split(' ')[4].substring(0, 5)}</div>
  );
}
