import "./InvaderGameResultsModal.css";
import React from "react";
import {Modal} from 'react-bootstrap';
import { AppContext } from "AppContext";

class InvaderRouletteResultsModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }  

  render() {
    if (!this.props.show)
      return null;

    return (
        <Modal
          show={this.props.show}
          onHide={this.props.onClose}
          dialogClassName="invader-game-results-modal"
          keyboard={false}
          centered
        >
          <Modal.Body>
            {
              !this.props.result.prize &&
              <>
                <h2>Sorry Rebel!</h2>
                {!this.props.result.ufo && <h3>No Prize</h3>}
                {this.props.result.ufo && <h3>The UFO captured you!</h3>}
                <hr></hr>
                <h4>Better luck next time</h4>
                {!this.props.result.ufo && <h4>keep trying!</h4>}
                {this.props.result.ufo && <h3>All chances gone for today</h3>}
                <p>Remaining chances: {AppContext.chances}</p>
              </>
            }
            {
              this.props.result.prize > 0 &&
              <>
                <h2>Congrats Rebel!</h2>
                <h3>You Won!</h3>
                <hr></hr>
                <h4>+{this.props.result.prize} Score</h4>
                <p>Remaining chances: {AppContext.chances}</p>
              </>
            }
            {
              this.props.result.prize === -1 &&
              <>
                <h2>Congrats Rebel!</h2>
                <h3>You captured an Invader!</h3>
                <hr></hr>
                <h4>Check the dashboard for new vouchers!</h4>
              </>
            }
          </Modal.Body>
        </Modal>
    );
  }
}

export default InvaderRouletteResultsModalComponent;