import "./InvasionMazeApp.css";
import BaseWorldApp from "./rebellion/ui/BaseWorldApp";
import React from "react";
import { EventManager } from "./rebellion/services/EventManager";
import Gauge from "Components/Gauge";
import { InfinitySpin } from "react-loader-spinner";
import { iethContract } from "Landing";
import { AppContext } from "AppContext";

class InvasionMazeApp extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      stages: [],
      currentMaze: null,
      currentStage: 0
    }
  }

  componentDidMount() {
    // TODO:
    // load stages from API
    const stages = [
      {
        stage: 1,
        mazes: [
          {
            index: 0,
            id: '1-1',
            map: '1-1',
            maxSteps: 50,
            enabled: true
          },
          {
            index: 1,
            id: '1-2',
            map: '1-2',
            maxSteps: 50,
            enabled: false
          },
          {
            index: 2,
            id: '1-3',
            map: '1-3',
            maxSteps: 50,
            enabled: false
          },
          {
            index: 3,
            id: 'Maze Editor',
            editor: true,
            enabled: false,
            hidden: true
          }
        ]
      }
    ];

    this.setState({stages: stages, loading: false});
  }

  onWorldInitialized(world) {
    // character step
    EventManager.bind('character.step', (event) => {
      let maze = this.state.currentMaze;
      maze.steps--;

      if (maze.steps === 0) {
        console.log('Mission Failed!');
        maze.started = false;
        maze = null;
      }

      this.setState({currentMaze: maze});

      return new Promise((resolve, reject) => { resolve(); });
    });

    // mission start
    EventManager.bind('mission.start', (event) => {
      let maze = this.state.currentMaze;
      maze.steps = maze.maxSteps;
      maze.started = true;

      this.setState({currentMaze: maze});

      return new Promise((resolve, reject) => { resolve(); });
    });

    // mission completed
    EventManager.bind('mission.complete', (event) => {
      let maze = this.state.currentMaze;
      maze.started = false;

      // unlock next maze
      const stages = this.state.stages;
      if (stages[this.state.currentStage].mazes.length > this.state.currentMaze.index + 1) {
        stages[this.state.currentStage].mazes[this.state.currentMaze.index + 1].enabled = true;
      }

      // save results for the maze, the stage and global

      this.setState({currentMaze: null, stages: stages});

      return new Promise((resolve, reject) => { resolve(); });
    });
  }

  onMazeSelected(maze) {
    this.setState({currentMaze: maze});

    if (maze.editor) {
      const editorMap = [...Array(20 * 12)];
      editorMap.fill('-');
      this.setState({editorMap: editorMap});
    }
  }

  EditorToggleCell(cellIndex) {
      const editorMap = this.state.editorMap;
      editorMap[cellIndex] = this.state.editorMap[cellIndex] === "X" ? "-" : "X";
      this.setState({editorMap: editorMap});
  }

  EditorMapSave() {
    console.log(this.state.editorMap);
  }

  render() {
    return (
      <div className="base-app invasion-maze-app">
      {
        this.state.loading &&
        <div className="text-center">
          <InfinitySpin width='190'color="#ffffff"/>                  
          <div>Loading</div>
        </div>
      }
      {
        !this.state.loading &&
        <>
        <div className="close-terminal" title="Close" onClick={() => this.props.onAppClose()}>X</div>
        {
          // stage selector
          !this.state.currentMaze &&
          <>
            <div className="stage-title">Stage {this.state.stages[this.state.currentStage].stage}</div>
            <ul className="maze-list">
                {
                  this.state.stages[this.state.currentStage].mazes.filter(item => !item.hidden).map(item => 
                    <this.MazeEntry 
                      key={item.id} 
                      item={item} 
                      onItemClick={(item) => this.onMazeSelected(item)}>
                    </this.MazeEntry>)
                }
            </ul>
          </>
        }
        {
          // maze!
          this.state.currentMaze &&
          !this.state.currentMaze.editor &&
          <>
            <BaseWorldApp 
              game="maze" 
              map={this.state.currentMaze.map} 
              user={{wallet: iethContract.userAddress(),  nickname: AppContext.walletData ? AppContext.walletData.nickname : 'Guest'}}
              {...this.props} 
              onWorldInitialized={(world) => this.onWorldInitialized(world)}>
            </BaseWorldApp>
            {
              this.state.currentMaze.started &&
              <div className="gaugebar gaugebar-bottom-right">
                <Gauge icon={require("../css/images/icons/moves.png")} value={this.state.currentMaze.steps} title="Moves"></Gauge>
              </div>
            }
          </>
        }
        {
          // maze editor!
          this.state.currentMaze &&
          this.state.currentMaze.editor &&
          <>
            <h1>Maze Editor</h1>
            <button onClick={() => this.setState({currentMaze: null})}>Close</button>
            <button onClick={() => this.EditorMapSave()}>Save</button>
            <div className="maze-editor-map">
              {
                this.state.editorMap.map((e, i) =>
                  <div className={"maze-editor-cell" + (e === "X" ? " cell-ground": "")} onClick={() => this.EditorToggleCell(i)}></div>
                )
              }
            </div>
          </>
        }
        </>
      }
      </div>
    );
  }

  MazeEntry = (props) => {
    if (props.item.hidden) {
      return ('');
    }

    return (
        <li className={"maze-entry button maze-icon " + props.item.class + (!props.item.enabled ? " maze-entry-disabled" : "")} 
          key={props.item.id} 
          onClick={() => props.item.enabled ? props.onItemClick(props.item) : null} 
          style={props.item.style}>
          {
            //<img src={require("./css/images/apps/" + props.item.image)} alt=""></img>
          }
          <div className="maze-entry-title">{props.item.id}</div>
          {props.item.extra}
        </li>
    );
  }
}

export default InvasionMazeApp;