import { ethers } from "ethers";
import { iethContract } from "Landing";
import abiAEKBattleEthAssets from "./../data/AEKBattleEthAssets.json"
import { AppContext, eventUpdateEnum } from "AppContext";
import { Alert } from "react-bootstrap";

export const battleEthService = {
  httpDataRoot: 'https://invasioneth.art/battleeth',
  toPrice: (value) => Number(value.toString()) / Math.pow(10, 18),
  getContract: () => {
    return new ethers.Contract(
      process.env.REACT_APP_CONTRACT_BATTLEETH, 
      abiAEKBattleEthAssets.data, 
      iethContract.getProvider()
    );
  },
  getSigner: () => {
    return battleEthService.getContract().connect(iethContract.getWeb3Provider().getSigner());
  },
  loadAssets: async () => {
    const contract = battleEthService.getContract();
    AppContext.battle.champions = [];

    return iethContract.api.call('get', `contracts/${process.env.REACT_APP_CONTRACT_BATTLEETH}/transfers`)      
      .then((res) => res.json())
      .then((transfers) => { 
        if (!transfers || Object.keys(transfers).length === 0 || !transfers.owners || transfers.owners.length === 0) {
          AppContext.events.update.dispatch(eventUpdateEnum.champions);
          return;
        }
        
        const tokens = {}

        transfers.owners.filter(owner => owner?.owner?.toUpperCase() === AppContext.userAddress?.toUpperCase()).map((token) => {
          if (tokens[token.id]) return token.id;

          tokens[token.id] = AppContext.battle.champions.length; // next index
          AppContext.battle.champions.push({isLoading: true});
          AppContext.events.update.dispatch(eventUpdateEnum.champions);

          const removeToken = (index) => delete AppContext.battle.champions[index];

          // check ownership
          contract.ownerOf(token.id)
            .then((result) => {
              if (result.toLowerCase() !== AppContext.userAddress.toLowerCase()) {
                removeToken(tokens[token.id]);
                return;
              }

              // get token information
              contract.tokenURI(token.id)
                .then((result) => {
                  const metadata = `${battleEthService.httpDataRoot}/metadata/${result.replace(/^.*[\\/]/, '')}`;

                  fetch(metadata, { method: 'get', dataType: 'json' })
                  .then((res) => res.json())
                  .then((meta) => { 
                    if (!meta || !meta.image) 
                      return;

                    const asset = {metadata: meta};
                    asset.id = +token.id;

                    // get class from attributes
                    const classAttr = asset.metadata.attributes?.find(attr => attr.trait_type === 'Class');
                    asset.class = classAttr?.value?.toLowerCase();

                    asset.isBaseChampion = asset.class === 'champion' && asset.id <= process.env.REACT_APP_BATTLEETH_BASECHAMPIONID;

                    // add token data
                    AppContext.battle.champions[tokens[token.id]] = asset;
                    AppContext.events.update.dispatch(eventUpdateEnum.champions);
                  })
                  .catch((err) => {
                    // TODO: show error in token
                    //removeToken(tokens[token.id]);
                    console.error(token.id, err);
                  });                  
                })
                .catch((err) => {
                  removeToken(tokens[token.id]);
                });
            })
            .catch((err) =>{
              // not owned by this user anymore
              removeToken(tokens[token.id]);
            });

          return token.id;
        });

        AppContext.events.update.dispatch(eventUpdateEnum.champions);
      })
      .catch((err) => console.error(err));
  },
  hasFreeBaseChampionAvailable: () => {
    return battleEthService.getSigner().hasFreeBaseChampionAvailable();
  },
  getBaseChampionClanPrice: (clan) => {
    return battleEthService.getContract().getBaseChampionClanPrice(clan);
  },
  loadAvailableAssets: async (clans, onClickClan) => {
    AppContext.battle.availableAssets = [];

    // add base champion clans
    Object.keys(clans).forEach((key) => {
      const clan = clans[key];

      if (clan.hidden) return true;

      const baseChampionData = {
        index: -1,
        tokenId: -1,
        clan: clan,
        title: clan.name,
        metadata: {
          image: require(`./../css/images/battle/${clan.image}.png`),
          name: clan.name
        },
        onClick: (token) => onClickClan(token)
      }
      
      AppContext.battle.availableAssets.push(baseChampionData);
      AppContext.events.update.dispatch(eventUpdateEnum.champions);
    });

    battleEthService.getContract().getAvailableAssets()
      .then((assets) => {
        assets.forEach((assetId) => {
          if (!assetId?._isBigNumber) return;

          const tokenIndex = AppContext.battle.availableAssets.length;
          AppContext.battle.availableAssets.push({isLoading: true});
          AppContext.events.update.dispatch(eventUpdateEnum.champions);

          battleEthService.getContract().getAsset(assetId.toString())
            .then((asset) => {
              if (!asset.uri) return;

              const metadata = `${battleEthService.httpDataRoot}/metadata/${asset.uri.replace(/^.*[\\/]/, '')}`;

              fetch(metadata, { method: 'get', dataType: 'json' })
              .then((res) => res.json())
              .then((meta) => { 
                if (!meta || !meta.image) 
                  return;

                // check if asset is reserved
                battleEthService.getContract().isAssetReserved(assetId.toString())
                  .then(([reserved, address]) => {
                    if (reserved && address.toLowerCase() !== iethContract.userAddress().toLowerCase()) {
                      // not available for this user
                      delete AppContext.battle.availableAssets[tokenIndex];
                    } else {
                      // add token data
                      AppContext.battle.availableAssets[tokenIndex] = {class: 'marketItem', metadata: meta, market: asset};
                    }

                    AppContext.events.update.dispatch(eventUpdateEnum.champions);
                  })
                  .catch((err) => { throw(err) });
              })
              .catch((err) => {
                // TODO: Show error in token view
                console.error(err);
              });               
            })
            .catch((err) =>{
              delete AppContext.battle.availableAssets[tokenIndex];
              console.error(err);
            });
        })
    });
  },
  mintBaseChampion: (code, free, nativeCoin, price, onSetStatus) => {
    if (free) {
      return battleEthService.getSigner().mintBaseChampion(code);
    }

    // Native price (POL)
    if (nativeCoin) {
      const options = {
        value: price.toString()
      };

      return battleEthService.getSigner().mintBaseChampion(code, options);
    }

    // approve RBLB transfer
    return iethContract.RBLB.getSigner().approve(battleEthService.getContract().address, price.toString())
      .then((result) => {
        onSetStatus && onSetStatus(result);

        return iethContract.getProvider().waitForTransaction(result.hash, 1, 60000).then(() => {
          return battleEthService.getSigner().mintBaseChampion(code);
        });
      })
      .catch((err) => {
        throw(err);
      });
  },
  mintAssetNative: (asset) => {
    // Native price (POL)
    const options = {
      value: asset.market.priceNative.toString()
    };

    return battleEthService.getSigner().mint(asset.market.id.toString(), options);
  },
  mintAssetRBLB: (asset, onSetStatus) => {
    // approve RBLB transfer
    return iethContract.RBLB.getSigner().approve(battleEthService.getContract().address, asset.market.priceRBLB.toString())
      .then((result) => {
        onSetStatus && onSetStatus(result);

        return iethContract.getProvider().waitForTransaction(result.hash, 1, 180000)
          .then(() => {
            return battleEthService.getSigner().mint(asset.market.id.toString());
          })
          .catch((err) => { throw(err); });
      })
      .catch((err) => {
        throw(err);
      });
  },
  hasRBLBBalance: (price, onClose) => {
    if (!price || price.lt(AppContext.rblb))
      return null;

    return(
      <Alert variant="danger" onClose={() => onClose && onClose()} dismissible>
        You don't have enough RBLB earthling
      </Alert>    
    )
  }
}