import React from "react";
import { Form } from "react-bootstrap";
import {AppContext} from "AppContext";

class ModalSetNickName extends React.Component {
  render() {
    return (
      <>
        <Form.Control 
          type="text" 
          name="nickname" 
          defaultValue={AppContext.walletData.nickname}
        />
        <Form.Check
          type="switch"
          name="showOSLink"
          label="Show link to my Open Sea account"
          defaultChecked={typeof(AppContext.walletData.showOSLink) !== 'undefined' ? AppContext.walletData.showOSLink : true}
        />
      </>
    );
  }
}

export default ModalSetNickName;