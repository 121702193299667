import Img from "Components/Img";
import { Col, Row, Button } from "react-bootstrap";
import { battleEthService } from "./BattleEthService";
import { AssetAttributes } from "./Asset";

// market asset component

export const MarketAsset = (props = {}) => {
  const asset = props.token;
  if (!asset) return null;

  const image = asset.metadata.external_url ? (asset.metadata.external_url.indexOf('.gif') < 0 ? asset.metadata.external_url + ".png" : asset.metadata.external_url) : asset.metadata.image;

  //console.log(asset.market);

  return (
    <div className={"asset-data market-asset-data"} key={props.index}>
      <div className="close-terminal" title="Close" onClick={() => props?.onClose()}>X</div>
      <Row>
        <Col md={6} className="position-relative">
        <Img 
          src={image} 
          errorImage={require("../css/images/resistance-fan.png")}
          alt="" 
          title={asset.name}></Img>
        </Col>
        <Col md={6} className="position-relative">
          <h2>{asset.metadata.name}</h2>
          {
            !asset.market?.minted &&
            <>
              <div className="data-actions">
                {
                  asset.market?.priceNative?.isZero() &&
                  asset.market?.priceRBLB?.isZero() &&
                  <Button className="button button-text" variant="primary" onClick={() => {props.onMintAsset && props.onMintAsset(asset, true)}}>
                    <div>Free</div>
                    <div>Mint</div>
                  </Button>
                }
                {
                  !asset.market?.priceNative?.isZero() &&
                  <Button className="button button-text" variant="primary" onClick={() => {props.onMintAsset && props.onMintAsset(asset, true)}}>
                    <div>Mint</div>
                    <small>{battleEthService.toPrice(asset.market.priceNative)} POL</small>
                  </Button>
                }
                {
                  !asset.market?.priceRBLB?.isZero() &&
                  <Button className="button button-text" variant="primary" onClick={() => {props.onMintAsset && props.onMintAsset(asset, false)}}>
                    <div>Mint</div>
                    <small>{battleEthService.toPrice(asset.market.priceRBLB)} RBLB</small>
                  </Button>
                }
              </div>
              <hr></hr>
            </>
          }
          <AssetAttributes asset={asset}></AssetAttributes>
        </Col>
      </Row>
    </div>
  );
}