import "./ChampionBuilder.css";

import React from "react";
import { InfinitySpin } from  'react-loader-spinner'
import { battleEthService } from "./BattleEthService";

class ChampionBuilder extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      selected: {
        trait: null
      },
      showAttributes: false,
      images: [],
      freeBaseChampion: false,
      priceNative: null,
      priceRBLB: null,
      alert: null
    };

    this.token = props.token;
  }

  componentDidMount() {
    this.gender = this.token.clan.traits.find(trait => trait.class === "gender").elements;
    this.skins = this.token.clan.traits.find(trait => trait.class === "skin").elements;

    const selected = this.state.selected;

    // set initial traits
    this.token.clan.traits.map((trait) => {
      // find default trait
      const defaulttraits = trait.elements.filter(element => element.default);

      selected[trait.class] = defaulttraits.length > 0 ? defaulttraits[0] : null;

      return trait;
    });

    battleEthService.hasFreeBaseChampionAvailable()
      .then((isFree) => this.setState({freeBaseChampion: isFree}))
      .catch((err) => console.error(err));

    battleEthService.getBaseChampionClanPrice(this.token.clan.code)
      .then(([priceMatic, priceRBLB]) => this.setState({priceNative: priceMatic, priceRBLB: priceRBLB}))
      .catch((err) => console.error(err));

    this.setState({selected: selected, loading: false});
  }

  createImage() {
    if (!this.token.clan.schema) {
      return null;
    }

    const urls = [];
    this.createTrait('main', this.token.clan.schema, urls);

    let images = this.state.images;
    images = [];

    urls.map((traits) => images.push(traits));

    this.setState({ images: images });
  }

  createTrait(name, data, traits) {
    //let position = {x: 0, y: 0};
    let skin = "";
    let selectedTrait = this.state.selected[name] || {};

    // skinned trait
    if (data.skinned && this.state.selected.skin) {
      skin = this.state.selected.skin.code;
    }

    // trait position
    if (data.position && this.state.selected[data.position] && this.state.selected[data.position][name]) {
      //position = this.state.selected[data.position][name];
    }

    // image path mask (only for non-virtual traits)
    if (data.mask && !selectedTrait.virtual) {
      // create mask
      let mask = data.mask;

      // selected traits
      Object.keys(this.state.selected).map((selected) => {
        if (this.state.selected[selected] != null)
          mask = mask.replace("{" + selected + "}", this.state.selected[selected].code);
        
        return selected;
      });

      // define image urls and styles
      traits.push({image: mask + "color" + (skin ? "." + skin : "") + ".png", style: ""});
      traits.push({image: mask + "shadows.png", style: ""});
      traits.push({image: mask + "ink.png", style: ""});
    }

    if (data.traits) {
      Object.keys(data.traits).map((child) => this.createTrait(child, data.traits[child], traits));
    }
  }

  createChampion(free, nativeCoin, price) {
    const champion = {
      tokenId: this.token.tokenId,
      images: this.state.images,
      attributes: []
    };

    Object.keys(this.state.selected).map((key, index) => {
      if (key === 'trait')
        return true;

      champion.attributes.push({
        trait_type: key.charAt(0).toUpperCase() + key.slice(1), 
        value: this.state.selected[key].name
      });

      return true;
    });

    if (!free && !nativeCoin) {
      const alert = battleEthService.hasRBLBBalance(price, () => this.setState({alert: null}));
      this.setState({alert: alert});

      if (alert) return;
    }

    // MINT Base Champion
    this.props.onMint && this.props.onMint(`${this.token.clan.code}-${this.state.selected.gender.code}-${this.state.selected.skin.code}`, free, nativeCoin, price);
  }

  render() {
    if (!this.token)
      return null;
    
    if (this.state.loading) {
      return (<div><InfinitySpin width='200' color="#ffffff"/></div>)
    }

    return (
      <div className="champion-builder">
        {this.state.alert}
        <div className="close-terminal" title="Close" onClick={() => this.props.onClose && this.props.onClose()}>X</div>
        <div className="title">{this.token.title}</div>
        <div className="subtitle">
          <div>Create your champion</div>
          <small>Select the skin color and gender</small>
        </div>
        <this.SkinSelector 
          elements={this.skins}
          selector="skin"
          onElementSelected={(selector, element) => this.onElementSelected(selector, element)}/>
        <this.GenderSelector 
          elements={this.gender}
          selected={this.state.selected.gender || {id: 1}}
          onElementSelected={(selector, element) => this.onElementSelected(selector, element)}/>
        <div className="footer">
          {
            this.state.freeBaseChampion &&
            <div className="button button-text" onClick={() => this.createChampion(true)}>
              Free<br></br>Mint
            </div>
          }
          {
            !this.state.freeBaseChampion &&
            <>
            {
              this.state.priceNative &&
              <div className="button button-text" onClick={() => this.createChampion(false, true, this.state.priceNative)}>
                <div>Mint</div> 
                <small>{battleEthService.toPrice(this.state.priceNative)} POL</small>
              </div>
            }
            {
              this.state.priceRBLB &&
              <div className="button button-text" onClick={() => this.createChampion(false, false, this.state.priceRBLB)}>
                <div>Mint</div> 
                <small>{battleEthService.toPrice(this.state.priceRBLB)} RBLB</small>
              </div>
            }                  
            </>
          }
        </div>
      </div>
    );
  }

  // Element Selected
  onElementSelected(selector, element) {
    const selected = this.state.selected;
    selected[selector] = element;

    this.createImage();

    this.setState({selected: selected});
  }

  // Skin Selector
  SkinSelector = (props) => {
    const selector = props.selector || "";
    
    return (
      <div className="skin-selector">
        {
          props.elements.map((element, index) => 
              <div className={"skin" + (this.state.selected[selector] !== null && this.state.selected[selector].id === element.id ? " selected" : "")} 
                key={element.id.toString()} 
                onClick={() => props.onElementSelected(selector, element)}
                title={element.name}
                style={element.style}>
             </div>)
        }
      </div>
    );
  }  

  // Gender selector
  getGenderStyle(gender) {
    return { 
      backgroundImage: (this.state.selected.skin != null ? "url(" + require(`./../css/images/battle/champion-base/${this.token.clan.code}-${gender.code}-${this.state.selected.skin.code}.png`) + ")" : "")
    }    
  }

  GenderSelector = (props) => {
    return (
        <div className="gender-selector">
          {
              props.elements.map((element, index) => 
                <div className={"champion-gender champion-" + element.code + (element.id === props.selected?.id ? " champion-selected" : "")} 
                  key={element.id.toString()} 
                  onClick={() => props.onElementSelected("gender", element)}
                  style={this.getGenderStyle(element)}>
              </div>)
          }
        </div>
    );
  }   

  // Trait selector
  onTraitSelected(selector, element) {
    const selected = this.state.selected;
    selected.trait = element;
    selected[element.class] = selected[element.class] || null;
   
    this.createImage();

    this.setState({selected: selected});
  }

  ElementSelector = (props) => {
    const selector = props.selector || "";

    return (
        <ul className={props.className + "-list"}>
          {
            props.elements.map((element, index) => 
              <li className={(props.className + "-entry") + (this.state.selected[selector] !== null && this.state.selected[selector].id === element.id ? " selected" : "")} 
                  key={element.id.toString()} 
                  onClick={() => props.onElementSelected(selector, element)}
                  title={element.name}>
                    {
                      element.image != null &&
                      <img src={require("css/images/icons/" + element.image)} alt=""></img>
                    }
                    {
                      element.image == null &&
                      <>{element.code}</>
                    }
              </li>)
          }
        </ul>
    );
  }  

  ChampionImage = (props) => {
    return(
      props.traits.map((trait, index) =>
        <div className="champion-trait-image" key={index} style={{backgroundImage: "url(" + require("css/images/battle/traits/" + trait.image) + ")"}}></div>
      )
    );
  }
}

export default ChampionBuilder;