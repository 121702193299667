import "./BattleEthApp.css";

import React from "react";
import { AppContext, eventUpdateEnum } from "AppContext";
import { iethContract } from "Landing";
import BattleEthTokenList from "./BattleEthTokenList";
import { InfinitySpin } from  'react-loader-spinner'
import { Spinner } from "react-bootstrap";
import ChampionBuilder from "./ChampionBuilder";
import { battleEthService } from "./BattleEthService";
import SideMenu from "Components/SideMenu";
import MetaMaskAuth from "connect/metamask-auth.module";
import { Champion } from "./Champion";
import { Asset } from "./Asset";
import { Battle } from "./Battle";
import { MarketAsset } from "./MarketAsset";
import { RBLBGauges } from "Components/RBLBGauges";
import { ArrowClockwise } from "react-bootstrap-icons";

const tokenTypeComponents = {
  champion: (props) => <Champion {...props}></Champion>,
  asset: (props) => <Asset {...props}></Asset>,
  wearable: (props) => <Asset {...props}></Asset>,
  mutation: (props) => <Asset {...props}></Asset>,
  battle: (props) => <Battle {...props}></Battle>,
  marketItem: (props) => <MarketAsset {...props}></MarketAsset>,
}

class BattleEthApp extends React.Component {
  battleEthMenuItems = [
    {
      index: 1,
      name: "Dashboard",
      image: "dashboard.png",
      component: (props) => <BattleEthViewDashboard {...props}></BattleEthViewDashboard>
    },
    {
      index: 2,
      name: "Market",
      image: "shopping.png",
      component: (props) => <BattleEthViewMarket {...props}></BattleEthViewMarket>
    },
    {
      index: 3,
      name: "Leaderboard",
      image: "leaderboard.png",
      component: (props) => <BattleEthViewLeaderboard {...props}></BattleEthViewLeaderboard>
    }   
  ]
   
  constructor() {
    super();
    this.state = {
      loading: true,
      loadingChampions: true,
      champions: [],
      battles: [],
      availableAssets: [],
      selectedToken: null,
      selectedTokenType: null,
      showChampionBuilder: false,
      selectedChampionBuilderToken: null,
      selectedMenuItem: this.battleEthMenuItems[0],
      minting: {
        hash: null,
        message: null,
        error: null,
        retry: () => {}
      },
      showMintingStatus: false
    };

    this.menuRef = React.createRef();
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    // champion traits definition
    this.clans = { clans: {} };
    iethContract.api.call('get', `championtraits`)
      .then((res) => res.json())
      .then((data) => { 
        this.clans = data.clans;

        battleEthService.loadAssets();
        battleEthService.loadAvailableAssets(this.clans, 
                  (token) => this.setState({showChampionBuilder: true, selectedChampionBuilderToken: token}));
    
        AppContext.events.update.add((event) => {
          if (event === eventUpdateEnum.champions) {
            this.setState({
              champions: AppContext.battle.champions || [], 
              battles: AppContext.battle.battles || [], 
              availableAssets: AppContext.battle.availableAssets || [],
              loadingChampions: false});
          }
        });
      })
      .finally(() => {
        this.setState({loading: false});
        this.menuRef.current && this.menuRef.current.showMenu();
      });
  }

  onRefresh(view) {
    if (view.name === 'Dashboard') {
      battleEthService.loadAssets();
    } else if (view.name === 'Market') {
      battleEthService.loadAvailableAssets(this.clans, 
        (token) => this.setState({showChampionBuilder: true, selectedChampionBuilderToken: token}));
    }
  }

  onMenuClick(menu) {
    this.setState({selectedMenuItem: menu});
  }

  onTokenClick(token) {
    if (token?.onClick) {
      return token.onClick(token);
    }

    this.setState({
      selectedToken: token,
      selectedTokenType: token?.class
    })
  }

  onMintBaseChampion(code, free, nativeCoin, price) {
    const minting = {};
    minting.message = 
      <>
        { free && <div>Free Mint</div> }
        { !free && nativeCoin && <div>{battleEthService.toPrice(price)} POL</div> }
        { !free && !nativeCoin && <div>{battleEthService.toPrice(price)} RBLB</div> }
        <div>Approve the transactions in your wallet to continue.</div>
      </>;
    minting.name = 'Base Champion';

    this.setState({showMintingStatus: true,  minting: minting});

    const onSetStatus = (status) => {
      minting.hash = status.hash;
      this.setState({ minting: minting});
    }

    battleEthService.mintBaseChampion(code, free, nativeCoin, price, onSetStatus)
      .then((result) => {
        onSetStatus(result);

        iethContract.getProvider().waitForTransaction(result.hash, 1, 60000).then(() => {
          this.setState({showMintingStatus: false, showChampionBuilder: false, selectedChampionBuilderToken: null, minting: null});
          battleEthService.loadAssets();
        });
      })
      .catch((err) => {
        minting.error = err.data?.message || err.message;
        minting.hash = null;
        minting.retry = () => this.onMintBaseChampion(code, free, nativeCoin, price);
        this.setState({minting: minting});
    })
  }

  onMintAsset(asset, sendNative) {
    const minting = {};
    minting.message = 
      <>
        { sendNative && <div>{battleEthService.toPrice(asset.market.priceNative)} POL</div> }
        { !sendNative && <div>{battleEthService.toPrice(asset.market.priceRBLB)} RBLB</div> }
        <div>Approve the transactions in your wallet to continue.</div>
      </>;
    minting.name = asset.metadata.name;

    this.setState({showMintingStatus: true,  minting: minting});

    const onSetStatus = (status) => {
      minting.hash = status.hash;
      this.setState({ minting: minting});
    }

    if (sendNative) {
      battleEthService.mintAssetNative(asset, onSetStatus)
        .then((result) => {
          onSetStatus(result);

          iethContract.getProvider().waitForTransaction(result.hash, 1, 60000).then(() => {
            this.setState({showMintingStatus: false,  minting: null, selectedToken: null});
            battleEthService.loadAssets();
            battleEthService.loadAvailableAssets(this.clans);
          });
        })
        .catch((err) => {
          minting.error = err.message;
          minting.hash = null;
          this.setState({minting: minting});
        });
    } else {
      battleEthService.mintAssetRBLB(asset, onSetStatus)
        .then((result) => {
          onSetStatus(result);

          iethContract.getProvider().waitForTransaction(result.hash, 1, 60000).then(() => {
            this.setState({showMintingStatus: false,  minting: null, selectedToken: null});
            battleEthService.loadAssets();
            battleEthService.loadAvailableAssets(this.clans);
          });
        })
        .catch((err) => {
          minting.error = err.message;
          minting.hash = null;
          this.setState({minting: minting});
        });
    }
  }

  render() {
    // loading
    if (this.state.loading) {
      return (
        <BattleEthLanding onAppClose={this.props.onAppClose}>
          <div className="wait"><InfinitySpin width="200" color="#ffffff"/></div>
        </BattleEthLanding>
      )
    }

    // minting window
    if (this.state.showMintingStatus) {
      return (
        <BattleEthLanding>
          <Minting 
              data={this.state.minting} 
              onClose={() => this.setState({showMintingStatus : false})}/>
        </BattleEthLanding>
      )
    }

    // champion builder
    if (this.state.showChampionBuilder) {
      return (
        <BattleEthLanding>
          <ChampionBuilder 
                  token={this.state.selectedChampionBuilderToken}
                  onClose={() => this.setState({showChampionBuilder: false})}
                  onMint={(code, free, nativeCoin, price) => this.onMintBaseChampion(code, free, nativeCoin, price)}/>
        </BattleEthLanding>
      )
    }

    // selected token
    if (this.state.selectedToken != null){
      const selectedTokenProps = {
        token: this.state.selectedToken,
        onClose: () => this.onTokenClick(null),
        onMintAsset: (asset, sendNative) => this.onMintAsset(asset, sendNative)
      }
  
      return (
        <BattleEthLanding>
          {tokenTypeComponents[this.state.selectedTokenType] && tokenTypeComponents[this.state.selectedTokenType](selectedTokenProps)}
        </BattleEthLanding>
      )
    }

    // main menu
    return (
      <BattleEthLanding onAppClose={this.props.onAppClose}>
        <SideMenu ref={this.menuRef} show={true} items={this.battleEthMenuItems} onMenuClick={(menu) => this.onMenuClick(menu)}></SideMenu>
        <BattleEthContainer 
          loading={this.state.loading || this.state.loadingChampions}
          onRefresh={(view) => this.onRefresh(view)}
          view={this.state.selectedMenuItem}
          tokens={{
            champions: this.state.champions,
            battles: this.state.battles,
            availableAssets: this.state.availableAssets
          }}
          onTokenClick={(token) => this.onTokenClick(token)}
          />
      </BattleEthLanding>
    );
  }
}

const BattleEthLanding = (props = {}) => {
  return (
    <div className="base-app landing-parallax">
      <div className="battle-eth-background-image"></div>
      <div className="battle-eth">
        {
          props?.onAppClose &&
          <div className="close-terminal" title="Close" onClick={() => props?.onAppClose()}>X</div>
        }
        {props?.children}
      </div>
    </div>
  )
}

const BattleEthContainer = (props = {}) => {
  const view = props?.view || {};

  return (
      <div className="battle-eth-container">
        <RBLBGauges position={'gaugebar-bottom-right'}></RBLBGauges>
        <div className="title">
          {view.name}
          <div className="refresh-data" title="Refresh" onClick={() => props.onRefresh && props.onRefresh(view)}><ArrowClockwise/></div>
        </div>
        <div className="battle-eth-contents">
          {
            !AppContext.userAddress ?
              <div className="text-center">
                <MetaMaskAuth onAddressChanged={address => {}}/>
              </div>
            :
              view.component(props)
          }
        </div>
      </div>
  );
}

// views

const BattleEthViewDashboard = (props = {}) => {
  return (
    <div>
      <div className="champion-list">
        <BattleEthTokenList
          className="token-list"
          tokenClassName="token-entry-card"
          loading={props.loading}
          tokens={[...props.tokens?.champions]} 
          noDataLegend={
            <>
              <div>No assets found.</div>
              <div>You'll find your champions and assets here.</div>
              <div>Go to the market, use your $RBLB and get some stuff.</div>
            </>
          }
          onTokenClick={(token) => props.onTokenClick && props.onTokenClick(token)}/>
      </div>
    </div>
  );

  /*
  <div className="battle-list">
    <BattleEthTokenList title="Battles" 
      className="token-list"
      loading={this.state.loadingChampions}
      tokens={this.state.battles} 
      onTokenClick={(token) => this.onBattleClick(token)}/>
  </div>
  */ 
}

const BattleEthViewMarket = (props = {}) => {
  return (
    <div className="market-list">
      <div className="market-legend">Get new champions, assets, weareables and potions with your $RBLB</div>
      <BattleEthTokenList
        className="token-list"
        tokenClassName="token-entry-card"
        loading={props.loading}
        tokens={[...props.tokens.availableAssets]} 
        noDataLegend={
          <>
            <div>No available items in the market</div>
          </>
        }
        onTokenClick={(token) => props.onTokenClick && props.onTokenClick(token)}/>
    </div>
  );
}

const BattleEthViewLeaderboard = (props = {}) => {
  return (
    <div className="leaderboard">
      <div>No leader information for now.</div>
    </div>
  );
}

// minting
const Minting = (props = {}) => {
  const data = props.data;

  return (
    <div className="minting-screen">
      <div className="close-terminal" title="Close" onClick={() => props.onClose && props.onClose()}>X</div>
      <div className="content">
        <h2>{data.name}</h2>
        <div>
          <small>{data.message}</small>
        </div>
        <br></br>
        {
          data.hash &&
          <div className="hash">
            <div>
              <Spinner className="spinner hidden" animation="border"/>
              Processing transaction
            </div>
            <hr></hr>
            <div>Transaction hash:</div>
            <a href={process.env.REACT_APP_NETWORK_SCAN + 'tx/' + data.hash} target="_blank" rel="noopener noreferrer">{data.hash}</a>
            <div>
              <small>Please, wait until the transaction is finished.</small>
            </div>
          </div>
        }
        {
          data.error &&
          <>
            <div className="error">
              {data.error}
            </div>
            {
              data.retry && 
              <div className="button button-text" onClick={() => data.retry()}>
                Try Again
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default BattleEthApp;