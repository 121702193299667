import "./VouchersModal.css";

import React from "react";
import {Container, Modal} from 'react-bootstrap';
import TokenList from "./TokenList";
import RedeemModalComponent from "./RedeemModal";
import { iethContract } from "Landing";
import { AppContext, eventUpdateEnum } from "AppContext";

export class VouchersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedToken: null,
      redeemModalShow: false,
      vouchers: []
    };
  }

  onTokenClick = (token) => {
    this.setState({selectedToken: token, redeemModalShow: true});
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    AppContext.events.update.add((event) => {
      if (event === eventUpdateEnum.vouchers) {
        this.setState({vouchers: AppContext.vouchers});
      }
    });

    this.setState({loading: false});
  }  

  render() {
    if (!this.props.show)
      return null;

    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => this.props.onClose()}
          keyboard={false}
          centered
          dialogClassName="voucher-modal"
        >
          <Modal.Body>
            <Container fluid>
              { this.state.vouchers != null &&
                this.state.vouchers.length > 0 &&
                <TokenList title="" 
                  className="token-list-md"
                  tokens={this.state.vouchers} 
                  onTokenClick={(token) => this.onTokenClick(token)}
                  cartUrl=""
                />
              }
            </Container>
          </Modal.Body>
        </Modal>
        <RedeemModalComponent 
          show={this.state.redeemModalShow} 
          token={this.state.selectedToken}
          onClose={async (close) => {
            this.setState({redeemModalShow: false});
            
            if (close) {
              iethContract.loadRebels();
              iethContract.loadAssets();
              iethContract.loadInvaders();

              this.props.onClose();
            }
          }}
        />
      </>
    );
  }
}
