import "./InvaderWaveComponent.css";
import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { CheckCircle, LockFill, QuestionCircleFill, ExclamationCircle } from "react-bootstrap-icons";
import Img from "../../Components/Img";
import { AppContext, rankEnumString } from "AppContext";
import { iethContract } from "Landing";


class InvaderWaveComponent extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <Container fluid>
      <Col>
          <Row>
            <h4 className="token-list-title">
              {this.props.wave.name}
              {
                this.props.wave.help &&
                <div className="token-list-buttons">
                  <a className="token-list-button" title="Help" href={require(`../../css/images/help-wave-${this.props.wave.wave}.png`)}  target="_blank" rel="noreferrer"><QuestionCircleFill/></a>
                </div>
              }
            </h4>
            {
              this.props.wave.invaders &&
              this.props.wave.invaders.length > 0 &&
              <ul className="token-list">
                {
                  this.props.wave.invaders.map((invader, index) => 
                    <InvaderEntry
                      key={index} 
                      invader={invader}
                      onInvaderClick={(invader) => this.props.onInvaderClick(invader)}>
                    </InvaderEntry>)
                }
              </ul>
            }
            {
              ( !this.props.wave.invaders ||
              this.props.wave.invaders.length === 0 ) &&
              <p>No invaders available</p>
            }
          </Row>
      </Col>
    </Container>
    );
  }
}

class InvaderEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const invader = this.props.invader;
    //let available = invader.locked ? 'TBA' : null;
    let available = null;

      // already capture invader
    if (AppContext.walletData && 
      AppContext.walletData.raffles && 
      AppContext.walletData.raffles.prizes &&
      AppContext.walletData.raffles.prizes.findIndex(element => element === invader.id) >= 0) {
      invader.locked = true;
      invader.owner = true;
    }    

    // check availability
    if (invader.supply <= 0 || invader.locked || AppContext.chances <= 0) {
      // unregistered user, no supply or invader locked
      invader.locked = true;
    } 
    
    // check rules
    if (invader.rules) {
      if ( (invader.rules.score && invader.rules.score > AppContext.score) || 
           (invader.rules.points && invader.rules.points > AppContext.points) ||
           (invader.rules.rank && invader.rules.rank > AppContext.rank) ) {
        invader.locked = true;
      } 

      // date
      if (invader.rules.date) {
        const date = new Date(invader.rules.date);
        const now = iethContract.apiRootTime();
        const nowUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
                      now.getUTCDate(), now.getUTCHours(),
                      now.getUTCMinutes(), now.getUTCSeconds());
        const diff = (date - nowUTC) / (1000*60*60*24);

        if (diff > 0) {
          invader.locked = true;
          
          if (diff >= 1) {
            available = Math.round(diff + 1) + ' day' + (Math.round(diff + 1) === 1 ? '' : 's');
          } else {
            const hours = diff * 24;
            if (hours >= 1) {
              available = Math.round(hours) + ' hour' + (Math.round(hours) === 1 ? '' : 's');
            } else {
              const minutes = diff * 24 * 60 < 1 ? 1 : diff * 24 * 60;
              available = Math.round(minutes) + ' minute' + (Math.round(minutes) === 1 ? '' : 's');
            }
          }
        } else {
          //available = 'NOW';
        }
      }
    }

    const requiredTitle =   (invader.rules && (invader.rules.score || invader.rules.points) ? 'Requires\r\n' : '') +
                            (invader.rules && invader.rules.score ? `Score ${invader.rules.score}+\r\n` : '') +
                            (invader.rules && invader.rules.points ? `Capture Points ${invader.rules.points}+\r\n` : '') +
                            (invader.rules && invader.rules.rank ? `Rank ${rankEnumString[invader.rules.rank]}\r\n` : '');

    return (
      <li className={"token-entry " /*+ (props.token === this.state.selectedToken ? "token-selected" : "")*/} 
          key={this.props.index} onClick={() => this.props.onInvaderClick(invader)}>
        {
          invader.owner &&
          <div className="token-entry-locked token-entry-captured" title="Captured!">
            <CheckCircle/>          
          </div>
        }
        {
          !invader.owner && invader.supply === 0 &&
          <div className="token-entry-locked" title="Too Late!">
            <ExclamationCircle/>          
          </div>
        }
        {
          !invader.owner && invader.locked && invader.supply !== 0 &&
          <div className="token-entry-locked" title="Locked">
            <LockFill/>          
          </div>
        }
        <Img 
          src={invader.image} 
          errorImage={require("../../css/images/icons/quality.png")}
          alt="" 
          title={invader.name}></Img>
        {
          !invader.owner && invader.id !== 1 && invader.supply !== 0 &&
          <Badge className="balance" pill bg="primary">{invader.supply}</Badge>
        }
        {
          invader.rules && (invader.rules.score || invader.rules.points || invader.rules.rank) &&
          <div className="token-entry-requirements" title={requiredTitle}>
            {invader.rules.score && <div><img src={require("../../css/images/icons/quality.png")} alt=""/><span>{invader.rules.score}</span></div>}
            {invader.rules.points && <div><img src={require("../../css/images/icons/points.png")} alt=""/><span>{invader.rules.points}</span></div>}
            {invader.rules.rank && <div><small>{rankEnumString[invader.rules.rank]}</small></div>}
          </div>
        }
        {
          available !== null && !invader.owner && invader.locked && invader.id !== 1 &&
          <div className="token-entry-available">{available}</div>
        }
      </li>
    );
  }
}

export default InvaderWaveComponent;