import React from "react";
import Landing from 'Landing'
import "./App.css";

class App extends React.Component {
  render() {
    return (
      <Landing></Landing>
    );
  }
}

export default App;