import React, { useEffect, useState } from "react";
import {Container, Modal} from 'react-bootstrap';
import {iethContract} from "Landing";
import { AppContext } from "AppContext";

export const RBLBModal = (props = {}) => {
  const [token, setToken] = useState({})
  const [status, setStatus] = useState(<div>If you didn't get your last $RBLB conversion, please retry.</div>)
  const [hash, setHash] = useState(null)

  useEffect(() => {
    setToken(props.token);
  }, [props.token]);    

  const setCoinPercentage = (p) => {
    // generate token
    iethContract.RBLB.getExchangeVoucher(p)
      .then((data) => setToken(data))
      .catch(() => {})
  }

  const resetHashAndStatus = () => {
    setStatus(<div>If you didn't get your last $RBLB conversion, please retry.</div>);
    setHash(null)
  }

  if (!props.show) return null;

  return (
      <Modal
        className="modal-rblb-modal"
        show={props.show}
        onHide={() => { resetHashAndStatus(); return props.onClose(hash !== null); }}
        keyboard={false}
        centered
        dialogClassName="redeem-modal rblb-modal"
      >
        <Modal.Body>
            <Container fluid className="text-center">
              <>
                <img className="icon" src={require("./../../css/images/icons/rblb.png")}  alt=""></img>
                <h2>Rebel Bucks</h2>
                <hr></hr>
              </>
              {
                AppContext.bucks === -1 &&
                <>
                  <p>We can't find the $RBLB contract in the current network</p>
                  <p>Please, switch your wallet to this network to see your balance:</p>
                  <div>Name: {process.env.REACT_APP_NETWORK_NAME}</div>
                  <div>Chain Id: {process.env.REACT_APP_NETWORK_CHAINID}</div>
                </>
              }
              {
                AppContext.bucks >= 0 &&
                <>
                  <>
                    <h4>Your Balance: <span className="balance">{AppContext.bucks} <small>$RBLB</small></span></h4>
                    <h4>Your Score: <span className="balance">{props.token.points || 0} <small>coins</small></span></h4>
                    <hr></hr>
                    <div>You can convert your coins to $RBLB</div>
                    <div>1 coin = {AppContext.bucksRate} $RBLB</div>
                    {
                      hash === null &&
                      token &&
                      token.points &&
                      <div className="quantity-selector">
                        <button className="button button-redeem button-redeem-sm" onClick={() => setCoinPercentage(10)}>10%</button>
                        <button className="button button-redeem button-redeem-sm" onClick={() => setCoinPercentage(20)}>20%</button>
                        <button className="button button-redeem button-redeem-sm" onClick={() => setCoinPercentage(30)}>30%</button>
                        <button className="button button-redeem button-redeem-sm" onClick={() => setCoinPercentage(50)}>50%</button>
                        <button className="button button-redeem button-redeem-sm" onClick={() => setCoinPercentage(100)}>MAX</button>
                      </div>
                    }
                    {
                      <>
                        <hr></hr>
                        { 
                          <div>With your <b>{token?.points || 0}</b> coins you'll get <b>{((token?.points || 0) * AppContext.bucksRate).toFixed(4)}</b> $RBLB</div> 
                        }
                        <p></p>
                      </>
                    }
                  </>
                  {
                    <>
                      {
                        hash === null &&
                        <div className="d-flex">
                          <button                         
                            className={`button button-redeem ${!token || !token.points ? 'bg-secondary' : 'bg-success'}`}
                            onClick={() => iethContract.redeem(token, (message, hash) => { setHash(hash); setStatus(message); })}
                            disabled={!token || !token.points}>
                            Convert Now!
                          </button>
                          <button 
                            className={`button button-redeem ${AppContext.lastExchangeUsed ? 'bg-secondary' : 'bg-danger'}`}
                            onClick={() => iethContract.redeem(AppContext.walletData.lastExchange, (message, hash) => { setHash(hash); setStatus(message); })}
                            disabled={AppContext.lastExchangeUsed}>
                            Retry Last!
                          </button>   
                        </div>                     
                      }
                      <hr></hr>
                      <div className="redeem-message">{status}</div>
                    </>
                  }
                  {
                    hash &&
                    <div className="redeemHash">
                      <b>Hash</b>
                      <div>
                        <a href={process.env.REACT_APP_NETWORK_SCAN + 'tx/' + hash} target="_blank" rel="noopener noreferrer">{hash}</a>
                      </div>
                    </div>
                  }
                </>
              }
              {
                false &&
                !hash &&
                !AppContext.lastExchangeUsed &&
                <>
                  <hr></hr>
                  <div>If you didn't get your $RBLB last conversion, please retry.</div>
                  <div>With your {AppContext.walletData.lastExchange.points} coins you'll get {(AppContext.walletData.lastExchange.points * AppContext.bucksRate).toFixed(4)} $RBLB</div>
                  <p></p>
                  <button 
                    className="button button-redeem" 
                    onClick={() => iethContract.redeem(AppContext.walletData.lastExchange, (message, hash) => { setHash(hash); setStatus(message); })}>
                      Retry Now!
                  </button>
                </>
              }
          </Container>
        </Modal.Body>
      </Modal>
  );
}
