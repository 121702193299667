import "./PickGameApp.css";
import "./PickGameAppScenarios.css";

import React from "react";
import { AppContext } from "AppContext";
import { InfinitySpin } from  'react-loader-spinner'
import PickGameModalComponent from "./PickGameModal";
import { iethContract } from "Landing";
import MetaMaskAuth from "connect/metamask-auth.module";

class PickGameApp extends React.Component {
  constructor() {
    super();

    this.state = {
      scenario: null,
      spots: [],
      chances:[],
      enableClick: false,
      showChances: false,
      showGameModal: false,
      showHelp: false,
      comingsoon: false,
      loading: false
    };
  }

  scenarios = [
    {
      index: 1,
      spots: 24,
      maxChances: 6,
      className: 'scenario-building',
      legend: 'Pick a Window',
      details: ['clouds', 'invader']
    },
    {
      index: 2,
      spots: 24,
      maxChances: 6,
      className: 'scenario-dumpster',
      legend: 'Pick a Box',
      details: []
    }
  ]

  componentDidMount() {
    this.initialize();
  }

  initialize() {
    if (AppContext.initialized) {
      this.createScenario(this.scenarios[0]);
    } else {
      AppContext.events.initilize.add(() => this.createScenario(this.scenarios[0]));
    }
  }

  async createScenario(scenario) {
    this.setState({loading: true});

    await iethContract.loadWalletData();
    await iethContract.loadRoachesCounters();

    const spots = [];
    for (let i = 0; i < scenario.spots; i++) {
      spots.push({
        index: i
      });
    }

    this.randomizeChances(scenario);
    
    // show instructions modal
    this.setState({
      showGameModal: this.state.availableRoaches > 0 && !this.state.comingsoon,
      scenario: scenario, 
      spots: spots,
      loading: false
    });
  }

  randomizeChances(scenario) {
    const chances = Array(scenario.spots).fill({score:0});

    const pickGameChances = [
      { prize: 2, score: 2 },
      { prize: 2, score: 2 },
      { prize: 3, score: 3 },
      { prize: 5, score: 5 }
    ];

    // ufos
    const ufos = AppContext.walletData.roaches2 || 0 > 20 ? 4 : AppContext.walletData.roaches2 || 0 > 10 ? 3 : 2;
    for (let u = 0; u < ufos; u++) {
      pickGameChances.push({ prize: 0, text: '-', score: 0, ufo: true, image: require('./../css/images/scenarios/building/spot-ufo.png') });
    }

    // winner chances
    // daily chances - max 6
    const availableRoaches = AppContext.roachesData ? (AppContext.roachesData.totalSupply - AppContext.roachesData.totalRoaches) || 0 : 0;
    const maxChances = availableRoaches < scenario.maxChances ? availableRoaches : scenario.maxChances;
    const availableChances = AppContext.chances >= maxChances ? maxChances : AppContext.chances;

    for (let x = 0; x < availableChances; x++) {
      pickGameChances.push({winner: true, prize: -1, score: scenario.prize || 10, image: require('./../css/images/scenarios/building/spot-roach.png')});
    }

    pickGameChances.forEach(chance => {
      let index = Math.floor(Math.random() * chances.length);
      while (chances[index].prize || chances[index].ufo) {
        index = Math.floor(Math.random() * chances.length);
      }

      chances[index] = chance;
    });
    
    this.setState({
      chances: chances, 
      availableChances: availableChances,
      availableRoaches: availableRoaches,
      availableUfos: ufos
    });
  }

  render() {
    return (
        <div className="base-app landing-parallax">
          <div className="pick-game">            
              {
                this.state.loading &&
                <div className="coming-soon">
                  <div>Loading</div>
                  <div><InfinitySpin width='200' color="#ffffff"/></div>
                </div>
              }
              {
                !AppContext.userAddress &&
                <div className="text-center">
                  <MetaMaskAuth onAddressChanged={address => {}}/>
                </div>
              }
              {
                this.state.showHelp &&
                <div className="help" onClick={() => {this.setState({showHelp: false, showGameModal: true})}}>
                  <img src={require("../css/images/help/wave-3-instructions.png")} alt=""/>
                </div>
              }
              {
                this.state.showGameModal &&
                <PickGameModalComponent
                  show={this.state.showGameModal} 
                  availableChances={this.state.availableChances}
                  availableUfos={this.state.availableUfos}
                  result={this.state.result || {}}
                  onCancel={() => {
                    iethContract.updateStatus();
                    this.props.onAppClose()
                  }}
                  onStart={() => {
                    iethContract.signIn()
                      .then((results) => {
                        this.randomizeChances(this.state.scenario);
                        this.setState({result: null, showGameModal: false, showChances: false, enableClick: true})
                      });
                  }}
                  onClose={(reload) => {
                    this.setState({showGameModal: false});
                  }}
                  onHelp={() => {
                    this.setState({showHelp: true, showGameModal: false});
                  }}
                  onCompleteWait={async () => {
                    return this.createScenario(this.scenarios[0]);
                  }}/>
              }
              {
                <>
                <div className="close-terminal" title="Close" onClick={() => this.state.showHelp ? this.setState({showHelp: false, showGameModal: true}) : this.props.onAppClose()}>X</div>
                {
                  AppContext.initialized &&
                  <>
                    {
                      this.state.comingsoon &&
                      !this.state.loading &&
                      <>
                        <div className="coming-soon">
                          <p>Coming Soon!</p>
                        </div>
                      </>
                    }
                    {
                      !this.state.comingsoon &&
                      this.state.availableRoaches <= 0 &&
                      <>
                        <div className="coming-soon">
                          <p>Sorry Earthling!</p>
                          <p>You're late...</p>
                          <p>All Roaches have been captured</p>
                        </div>
                      </>
                    }
                    {
                      this.state.scenario != null &&
                      <div className="scenario">
                        <div className={this.state.scenario.className + " scenario-container"}>
                          {
                            this.state.scenario.details.map((detail, index) => <div className={"scenario-background-details-" + detail} key={index}></div> )
                          }
                          {/*<div className="scenario-legend">{this.state.scenario.legend}</div>*/}
                          <div className="spot-container-background">
                            <div className="spot-container">
                              {
                                this.state.spots.map((spot, index) => 
                                  <Spot key={index} spot={spot} 
                                    showChances={this.state.showChances}
                                    enableClick={this.state.enableClick}
                                    chance={this.state.chances.length > index ? this.state.chances[index] : null} 
                                    onClick={(spot) => this.onSpotClick(spot)}>
                                  </Spot>)
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
                </>
              }
          </div>
        </div>
    );
  }

  async onSpotClick(spot) {
    //console.log(spot, this.state.chances[spot.index]);
    this.setState({showChances: true, result: this.state.chances[spot.index], enableClick: false});

    // if the user won or was captured by the UFO, no more chances
    const usedChances = (this.state.chances[spot.index].prize === -1 || this.state.chances[spot.index].ufo) ? AppContext.chances : this.state.chances.filter((item) => item.winner).length;
    iethContract.updateStatus();

    // waitTime
    // capture roach = 36 hours
    // ufo = 12 hours
    // no more chances = 6 hours
    const waitTime = this.state.chances[spot.index].prize === -1 ? 36 :
                      this.state.chances[spot.index].ufo ? 12 :
                      (AppContext.chances - usedChances) === 0 ? 6 : 0;

    await new Promise(res => setTimeout(res, 1000));

    // call api
    // signature + used chances + prize
    iethContract.signIn()
        .then((results) => {
            iethContract.api.call('post', `wallets/${iethContract.userAddress()}/prize/complete`, {
                signature: results.signature,
                nonce: results.nonce,
                prize: this.state.chances[spot.index].prize || 0,
                score: this.state.chances[spot.index].score || 0,
                prizeId: (AppContext.walletData.roaches || 0) + (AppContext.walletData.roaches2 || 0) + 1,
                prizeType: 'roach',
                usedChances: usedChances,
                waitTime: waitTime
            })
            .then((res) => res.json())
            .then(async (data) => {
              await iethContract.updateStatus();

              // show results
              this.setState({showGameModal: true});

              if (data.voucher) {
                iethContract.loadRoachesCounters();
                iethContract.loadVouchers();
              }
            });
        });
  }
}

class Spot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  render() {
    return (
      <div className={"spot" + (this.state.selected ? " spot-selected" : "")} 
        onClick={() => {
          if (this.props.enableClick) {
            //this.setState({selected: true}); 
            this.props.onClick(this.props.spot);
          }
          }}>
        {
          this.props.showChances &&
          this.props.chance &&
          this.props.chance.score > 0 &&
          !this.props.chance.image &&
          <div className="spot-legend">{this.props.chance.score}</div>
        }
        {
          this.props.showChances &&
          this.props.chance &&
          this.props.chance.image &&
          <div className="spot-image"><img src={this.props.chance.image} alt=""/></div>
        }
      </div>
    );
  }
}

export default PickGameApp;